import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import Box from '@mui/material/Box'
import { css } from '@emotion/css'
import { closeModalFn, isModalOpen, modalData } from '../../../../utils/controllers/ModalController'
import RightDrawer from '../../../../components/customElements/RightDrawer/RightDrawer'
import { getStyles } from '../../../../utils/utils/commonUtils'
import commonStyles from '../../../../styles/commonStyles'
import InstallationRequestStore from '../../stores/InstallationRequestStore'
import classNames from 'classnames'
import InputField from '../../../../components/customElements/InputField/InputField'
import InstallationData from '../../../../components/customElements/RequestDataAccordions/InstallationData'
import PhotosUpload from '../utils/PhotosUpload'
import UploadedPhotos from '../../../../components/customElements/RequestDataAccordions/UploadedPhotos'
import OrderStatus from '../../../../components/customElements/RequestDataAccordions/OrderStatus'
import { FormattedMessage } from 'react-intl'
import { gState } from '../../../../utils/controllers/GlobalStateController'
import AddInformationContent from '../utils/AddInformationContent'
import ChangePriority from '../../../../components/customElements/RequestDataAccordions/ChangePriority'
import ActionButtons from './components/ActionButtons'
import ChangeStatusView from './components/ChangeStatusView'
import ChangeInstallationDate from '../../../../components/customElements/RequestDataAccordions/ChangeInstallationDate'
import SetEquipmentId from '../../../../components/customElements/RequestDataAccordions/SetEquipmentId'
import CustomAccordion from '../../../../components/customElements/RequestDataAccordions/utils/CustomAccordion'
import moment from 'moment/moment'
import HasDuplicates from '../../../../components/customElements/RequestDataAccordions/HasDuplicates'
import DataUnit from '../../../../components/customElements/DataUnit/DataUnit'
import CommentsBlock from '../../../../components/RequestCommentsBlock/CommentsBlock'
import OrderInfoChips from '../../../../components/OrderInfoChips/OrderInfoChips'
import { isAdmin, isAdministration, isDealer, isLooker, isSuspiciousDealer } from '../../../../services/AccountService'
import { getComp } from '../../../../utils/utils/DI'
import FinancialSourceAccordio from '../../../../components/customElements/RequestDataAccordions/FinancialSourceAccordio'
import FormInput from '../../../../components/customElements/FormInput'
import IsSuspicious from '../../../../components/customElements/RequestDataAccordions/IsSuspicious'

function InstallationRequestDrawer({ ordersStore, serviceStore, openReportDetails, isReportDetailsOpen }) {
    const intl = gState['intl']
    const classes = getStyles(commonStyles)
    const [store] = useState(() => new InstallationRequestStore({ ordersStore }))
    const {
        data,
        setNewEquipment,
        newEquipment,
        clear,
        startUpdate,
        toDefaultStep,
        comment,
        setComment,
        addInformation,
        setSelectedOrder,
        isChangeStatusState,
        installationDateInput,
        setInstallationDateInput,
        changeInstallationDate,
        isEditInfo,
        toggleEditInfo,
        isEdit,
        photoSettings: { photoPermission },
        setOrderDataRequest,
        serviceInformationAccordionFormStore,
        receiverData,
        getReceiversFunc,
    } = store

    const {
        id,
        status,
        dealer,
        installationDate,
        equipmentId,
        dataCheckingStatus,
        hasDuplicates,
        reportStatus,
        reportId,
        receiverId,
        authorId,
        financialSourceId
    } = data
    const { serviceReceivers } = serviceStore

    const [editInstallationDate, setEditInstallationDate] = useState(false)
    const [editDataDealer, setEditDataDealer] = useState(false)
    const changeCreationDateCallback = () => {
        store.ordersStore.updateOrder(id)
    }

    useEffect(() => {
        if (receiverId) {
            getReceiversFunc(receiverId)
        }
    }, [receiverId])

    useEffect(() => {
        if (isModalOpen['installation-request'] && !isReportDetailsOpen) {
            store.updateDrawerData()
        }
    }, [isReportDetailsOpen])

    useEffect(() => {
        serviceInformationAccordionFormStore.clear()
        if (isModalOpen['installation-request']) {
            setSelectedOrder()
        } else {
            clear()
        }
    }, [isModalOpen['installation-request']])

    const closeModals = () => {
        if (isEdit && isEditInfo) {
            toggleEditInfo()
        } else {
            setEditDataDealer(false)
        }
        closeModalFn['photo-gallery']()
        closeModalFn['installation-request']()
    }
    const statusStore = getComp('StatusesStore')
    const { getStatusName } = statusStore
    const [error, setError] = useState(null)
    const [fins, setFins] = useState([])

    useEffect(() => {
        const territoryStore = getComp('TerritoryStore')
        const { territories } = territoryStore
        const { district, locality, territory } = data
       

        if (!territories || Object.keys(territories).length === 0) {
            setError('No territories available in the store')
            return
        }

        const findTerritory = territories[territory]
        if (!findTerritory) {
            setError(`Territory ${territory} not found`)
            return
        }

        const findDistrict = findTerritory.Territory?.Districts.find((el) => el.Name === district)
        if (!findDistrict) {
            setError(`District ${district} not found in territory ${territory}`)
            return
        }

        const findLocality = findDistrict.Localities.find((el) => el.Name === locality)
        if (!findLocality) {
            setError(`Locality ${locality} not found in district ${district}`)
            return
        }

        const allowedFinancialSourceIds = findLocality.AllowedFinancialSourceIds
        if (!allowedFinancialSourceIds) {
            setError(`Allowed financial source IDs not found for locality ${locality}`)
            return
        }
      
        setFins(allowedFinancialSourceIds)
        setError(null)
    }, [data, id])

    
    

    return (
        <RightDrawer
            open={isModalOpen['installation-request']}
            onClose={closeModals}
            loading={startUpdate}
            title={
                <>
                    <FormattedMessage id={'Заявка'} /> №{id}
                </>
            }
            backButtonClick={addInformation || isChangeStatusState ? toDefaultStep : null}
            isReportDetailsOpen={isReportDetailsOpen}
        >
            {isModalOpen['installation-request'] ? (
                <Box height={1}>
                    <Box name={'content'} className={classNames('fx-col jst-sb', css(classes.drawerContent))}>
                        {addInformation ? (
                            <AddInformationContent store={store} />
                        ) : isChangeStatusState ? (
                            <ChangeStatusView comment={comment} setComment={setComment} />
                        ) : isEdit && isEditInfo && !(isDealer() || isSuspiciousDealer()) ? (
                            <InstallationData
                                order={data}
                                defaultOpenState
                                onEdit={toggleEditInfo}
                                isEdit={true}
                                filterStore={modalData['installation-request'].filterStore}
                                ordersStore={modalData['installation-request'].ordersStore}
                            />
                        ) : (
                            <Box>
                                <Box sx={classes.drawerStatusesInfo}>
                                    <OrderStatus status={status} dealer={dealer} />
                                    {status >= 12 && (isAdmin() || isDealer()) && !isLooker() ? (
                                        <OrderInfoChips
                                            tooltip={getStatusName(reportStatus, true)}
                                            openReportDetails={openReportDetails} //функция при клике открывает окно отчета
                                            id={reportId} // id отчета
                                            reportStatus={reportStatus} // статус отчета
                                            haveChips={true} // регулирует наличие фона по типу чипса
                                            showOrderStatusIcon={true} // регулирует отображение иконки статуса
                                        />
                                    ) : null}
                                </Box>
                                {hasDuplicates && (
                                    <Box
                                        className={'fx-center'}
                                        sx={{
                                            padding: '8px 16px 16px',
                                            width: '100%',
                                        }}
                                    >
                                        <DataUnit value={intl.formatMessage({ id: 'Отмечена как повторная заявка' })} />
                                    </Box>
                                )}
                                {status === 4 ? (
                                    isEdit && status !== 2 ? (
                                        <SetEquipmentId store={store} newEquipmentValue={newEquipment} />
                                    ) : isDealer() && status === 5 ? null : (
                                        <CustomAccordion summary={intl.formatMessage({ id: 'Id оборудования' })}>
                                            {status === 6 ? (
                                                <DataUnit value={equipmentId ?? newEquipment} />
                                            ) : (
                                                <Box sx={{ paddingTop: '24px' }}>
                                                    <InputField
                                                        value={equipmentId ?? newEquipment}
                                                        onChange={setNewEquipment}
                                                        readOnly={!!equipmentId && !isEdit}
                                                        styles={{ marginTop: '8px' }}
                                                        label={intl.formatMessage({ id: 'Id оборудования' })}
                                                        fullWidth
                                                    />
                                                </Box>
                                            )}
                                        </CustomAccordion>
                                    )
                                ) : null}
                                <InstallationData
                                    order={data}
                                    defaultOpenState
                                    isEdit={
                                        editDataDealer ||
                                        (isEditInfo && isDealer() && [2, 4, 5].includes(status)) ||
                                        /*||*/
                                        (isEditInfo && isDealer() && status == 6 && dataCheckingStatus == 0)
                                    }
                                    requestStore={store}
                                    onEdit={isSuspiciousDealer() && [2, 4, 5].includes(status) && !([1].includes(financialSourceId) && fins.includes(1))? null:
                                        isEdit  &&
                                        !(isDealer() && [6].includes(status) && [0].includes(dataCheckingStatus))
                                            ? toggleEditInfo
                                            : (isDealer() && [2, 4, 5].includes(status)) ||
                                              (isDealer() && getComp('UserInfoStore').userId === authorId && status == 6 && dataCheckingStatus == 0)
                                            ? () => {
                                                  setEditDataDealer(!editDataDealer)
                                              }
                                            : null
                                    }
                                    ordersStore={modalData['installation-request'].ordersStore}
                                />

                                <FinancialSourceAccordio data={store.data} />
                                {isAdministration() || (isDealer() && status === 7) ? null : (
                                    <ChangePriority id={id} priority={store.data.priority} />
                                )}

                                {isEdit && <HasDuplicates data={store.data} onChange={setOrderDataRequest} />}
                                {isEdit && isAdmin() && <IsSuspicious data={store.data} onChange={setOrderDataRequest} />}
                                {photoPermission.isWorking ? (
                                    <Box>
                                        <CustomAccordion
                                            // defaultOpenState={isDealer() && status === 5}
                                            defaultOpenState={true}
                                            summary={intl.formatMessage({ id: 'Данные установки' })}
                                        >
                                            <>
                                                {status === 6 ? (
                                                    <DataUnit value={equipmentId ?? newEquipment} />
                                                ) : (
                                                    <Box sx={{ paddingTop: '10px' }}>
                                                        <InputField
                                                            value={equipmentId ?? newEquipment}
                                                            onChange={setNewEquipment}
                                                            readOnly={!!equipmentId && !isEdit}
                                                            styles={{ marginTop: '8px' }}
                                                            label={intl.formatMessage({ id: 'Id оборудования' })}
                                                            fullWidth
                                                        />
                                                    </Box>
                                                )}
                                                <Box>
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            flexDirection: 'column',
                                                            gap: '14px',
                                                            paddingTop: '21px',
                                                        }}
                                                    >
                                                        {
                                                            status === 110 ? (
                                                                <>
                                                                    <FormInput
                                                                        type='select'
                                                                        api={serviceInformationAccordionFormStore}
                                                                        propName={'receiverModelId'}
                                                                        label={intl.formatMessage({
                                                                            id: 'Модель оборудования',
                                                                        })}
                                                                        fullWidth
                                                                        placeholder={'Модель оборудования'}
                                                                        items={serviceReceivers.map((el) => ({
                                                                            key: `${el.name} - ${el.manufacturerName}`,
                                                                            value: el.id,
                                                                        }))}
                                                                        withoutErrorText={true}
                                                                    />
                                                                    <FormInput
                                                                        type='text'
                                                                        api={serviceInformationAccordionFormStore}
                                                                        propName={'receiverSerialNumber'}
                                                                        label={intl.formatMessage({
                                                                            id: 'Серийный номер',
                                                                        })}
                                                                        fullWidth
                                                                        placeholder={'Серийный номер'}
                                                                        withoutErrorText={true}
                                                                    />
                                                                </>
                                                            ) : null
                                                            // <>
                                                            //     <DataUnit
                                                            //         name='Id оборудования'
                                                            //         value={equipmentId || 'Не заполнен'}
                                                            //     />
                                                            //     <DataUnit
                                                            //         name='Модель оборудования'
                                                            //         value={
                                                            //             `${
                                                            //                 serviceReceivers.find(
                                                            //                     (receiver) =>
                                                            //                         receiver.id === receiverData.modelId
                                                            //                 )?.name
                                                            //             } - ${
                                                            //                 serviceReceivers.find(
                                                            //                     (receiver) =>
                                                            //                         receiver.id === receiverData.modelId
                                                            //                 )?.manufacturerName
                                                            //             }` || 'Не заполнен'
                                                            //         }
                                                            //     />
                                                            //     <DataUnit
                                                            //         name='Серийный номер'
                                                            //         value={receiverData?.serialNumber || 'Не заполнен'}
                                                            //     />
                                                            // </>
                                                        }
                                                    </Box>
                                                </Box>
                                                <Box mt={2}>
                                                    <InputField
                                                        mt={2}
                                                        value={installationDateInput}
                                                        onChange={setInstallationDateInput}
                                                        label={intl.formatMessage({ id: 'Дата установки' })}
                                                        mask={'99-99-9999'}
                                                        placeholder={'DD-MM-YYYY'}
                                                        error={
                                                            (installationDateInput.length < 10 ||
                                                                moment().diff(
                                                                    moment(installationDateInput, 'DD-MM-YYYY')
                                                                ) < 0) &&
                                                            intl.formatMessage({ id: 'Введите корректную дату' })
                                                        }
                                                        fullWidth={true}
                                                    />

                                                    <PhotosUpload
                                                        store={store}
                                                        title={intl.formatMessage(
                                                            {
                                                                id: `Загрузите от {minimumLimit} до {maximumLimit} фотографий`,
                                                                defaultMessage: `Загрузите от {minimumLimit} до {maximumLimit} фотографий`,
                                                            },
                                                            {
                                                                minimumLimit: photoPermission.minLimitPerLoad,
                                                                maximumLimit: photoPermission.maxLimitPerLoad,
                                                            }
                                                        )}
                                                    />
                                                </Box>
                                            </>
                                        </CustomAccordion>
                                    </Box>
                                ) : (
                                    ![2, 4, 5].includes(status) && (
                                        // <Box>
                                        <CustomAccordion
                                            defaultOpenState={isDealer() && status === 6}
                                            onEdit={
                                                isEdit && status === 6 && data?.dataCheckingStatus === 0
                                                    ? () => setEditInstallationDate(!editInstallationDate)
                                                    : false
                                            }
                                            summary={intl.formatMessage({ id: 'Данные установки' })}
                                        >
                                            <>
                                                {[7].includes(status) ? null : <Box mt={2}>
                                                    <DataUnit
                                                        name='Id оборудования'
                                                        value={equipmentId || 'Не заполнен'}
                                                    />
                                                </Box>}

                                                {[5, 6,7].includes(status) ? null : (
                                                    <Box>
                                                        <Box mt={2}>
                                                            <DataUnit
                                                                name='Модель оборудования'
                                                                value={
                                                                    serviceReceivers.find(
                                                                        (receiver) =>
                                                                            receiver.id === receiverData.modelId
                                                                    )?.name
                                                                        ? `${
                                                                              serviceReceivers.find(
                                                                                  (receiver) =>
                                                                                      receiver.id ===
                                                                                      receiverData.modelId
                                                                              )?.name
                                                                          } - ${
                                                                              serviceReceivers.find(
                                                                                  (receiver) =>
                                                                                      receiver.id ===
                                                                                      receiverData.modelId
                                                                              )?.manufacturerName
                                                                          }`
                                                                        : 'Не заполнена'
                                                                }
                                                            />
                                                        </Box>
                                                        <Box mt={2}>
                                                            <DataUnit
                                                                name='Серийный номер'
                                                                value={receiverData?.serialNumber || 'Не заполнен'}
                                                            />
                                                        </Box>
                                                    </Box>
                                                )}
                                            </>
                                            {editInstallationDate || (status <= 6 && data?.dataCheckingStatus === 0) ? (
                                                <Box mt={2}>
                                                    {[6, 12, 13, 15, 16].includes(status) && (
                                                        <ChangeInstallationDate
                                                            inputData={installationDate}
                                                            requestId={id}
                                                            requestStatus={status}
                                                            changeInstallationDate={changeInstallationDate}
                                                            reloadAction={changeCreationDateCallback}
                                                            reportOrder={!!dataCheckingStatus && status !== 6}
                                                        />
                                                    )}
                                                </Box>
                                            ) : isDealer() && status === 7 ? null : (
                                                <Box mt={2}>
                                                    <DataUnit
                                                        name='Дата установки'
                                                        value={moment(installationDate)
                                                            .startOf('day')
                                                            .format('DD-MM-YYYY')}
                                                    />
                                                </Box>
                                            )}

                                            <UploadedPhotos store={store} order={data} defaultOpenState={true} />
                                        </CustomAccordion>
                                        // </Box>
                                    )
                                )}

                                <CommentsBlock store={store} />
                            </Box>
                        )}
                        {isEditInfo ? null : <ActionButtons store={store} />}
                    </Box>
                </Box>
            ) : null}
        </RightDrawer>
    )
}

export default observer(InstallationRequestDrawer)
