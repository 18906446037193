import { Box } from '@mui/system'
import { inject, observer } from 'mobx-react'
import React from 'react'
import WarningPaper from './WarningPaper'

const WarningsContainer = ({ serviceStore }) => {
    const { haveNotFound, showSearchResult, searchByIdMode, showContinueInfo } = serviceStore
    return (
        <>
            {!showSearchResult && !haveNotFound && searchByIdMode && (
                <Box sx={{ padding: '0 16px' }}>
                    <WarningPaper
                        title={'Внимание!'}
                        text1={
                            'Перед заведением заявки на обслуживание, пожалуйста, выполните опрос Абонента в соответствии с регламентом и убедитесь, что неисправность требует сервисного обслуживания!'
                        }
                        text2={
                            'Для создания заявки на обслуживание, выполните поиск данных об установленном оборудовании по Id оборудования или номеру телефона Абонента, указанным в бумажной копии Акта об установке оборудования.'
                        }
                    />
                </Box>
            )}
            {showContinueInfo && (
                <Box sx={{ padding: '0 16px' }}>
                    <WarningPaper
                        title={'Id оборудования не указан'}
                        text1={
                            'Для создания заявки на сервисное обслуживание рекомендуется указать Id оборудования и выполнить в системе поиск данных об установке.'
                        }
                        text2={
                            'В случае если такие данные не предоставлены или не найдены, пожалуйста, заведите заявку  без указания номера оборудования и сообщите Абоненту, что обслуживание такой заявки может потребовать больше времени.'
                        }
                    />
                </Box>
            )}
        </>
    )
}

export default inject('serviceStore')(observer(WarningsContainer))
