import { FormControl } from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect } from 'react'
import FormInput from '../../../../../components/customElements/FormInput'
import { inject, observer } from 'mobx-react'
import { useIntl } from 'react-intl'

const FormsOnTop = ({ serviceStore }) => {
    const intl = useIntl()
    const {
        reasonCategories,
        searchByIdMode,
        showAddServiceRequestForm,
        searchByPhoneMode,
        serviceAddRequestFormStore,
        showContinueInfo,
        checkedSeviceOrder,
        equipmentIdError,
        getInstalledOrders,
    } = serviceStore

    const validateFilterInputs = (event, propName) => {
        if (propName === 'phoneNumber') {
            const phonePattern = /^[0-9()-]+$/
            return phonePattern.test(event.target.value) || event.target.value === '' ? true : false
        }
        if (propName === 'equipmentId') {
            if (event) {
                if (event.target.value.length > 14) return false
                const idPattern = /^[0-9]+$/
                return idPattern.test(event.target.value) || event.target.value === '' ? true : false
            }
        }
        return true
    }

    const handleSearchByEnter = (e) => {
        if (e.key === 'Enter') {
            if (searchByIdMode && !equipmentIdError) {
                return getInstalledOrders()
            }

            if (searchByPhoneMode && !checkedSeviceOrder) {
                return getInstalledOrders()
            }
        }
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '14px',
                padding: '0 16px',
                marginTop: '24px',
            }}
            onKeyUp={handleSearchByEnter}
        >
            <FormControl fullWidth>
                <FormInput
                    api={serviceAddRequestFormStore}
                    type='select'
                    fullWidth
                    autoSearch
                    withoutErrorText
                    propName='maintenanceReasonId'
                    label={intl.formatMessage({ id: 'Причина обращения' })}
                    items={reasonCategories.map((el) => {
                        return { key: el.name, value: el.id }
                    })}
                    disabled={true}
                />
            </FormControl>
            {searchByIdMode || showContinueInfo || showAddServiceRequestForm ? (
                <FormControl fullWidth>
                    <FormInput
                        api={serviceAddRequestFormStore}
                        type={'text'}
                        fullWidth
                        autoSearch
                        withoutErrorText
                        propName='equipmentId'
                        label={intl.formatMessage({ id: 'Id оборудования' })}
                        renderCellValue={(params) => params.equipmentId}
                        validateFilterInputs={validateFilterInputs}
                        disabled={showContinueInfo || showAddServiceRequestForm}
                    />
                </FormControl>
            ) : null}
            {searchByPhoneMode && (
                <FormControl fullWidth>
                    <FormInput
                        api={serviceAddRequestFormStore}
                        type={'text'}
                        fullWidth
                        mask={'+7 (999)-999-99-99'}
                        propName='phoneNumber'
                        label={intl.formatMessage({ id: 'Номер телефона' })}
                        renderCellValue={(params) => params.phone}
                        withoutErrorText={true}
                        // validateFilterInputs={validateFilterInputs}
                        ignoreErrorColor={true}
                    />
                </FormControl>
            )}
        </Box>
    )
}

export default inject('serviceStore')(observer(FormsOnTop))
