import { makeAutoObservable } from 'mobx'
import { closeModalFn } from '../../../utils/controllers/ModalController'

class RequestTableSettingsStore {
    constructor() {
        makeAutoObservable(this)
    }
    // перменная со всеми столбцами доступными для редактирования
    // актуально для пользователя Админ 'financial',
    columns = [
        {
            propName: 'isExpiredAssign',
            field: 'isExpiredAssign',
            headerName: 'Просрочено',
            checked: true,
        },
        {
            propName: 'financial',
            field: 'financial',
            headerName: 'Оплата',
            checked: true,
        },
        {
            propName: 'priority',
            field: 'priority',
            headerName: 'Приоритет',
            checked: true,
        },
        {
            propName: 'assignDealer',
            headerName: 'Дилер',
            checked: true,
        },
        {
            propName: 'sender',
            headerName: 'Источник',
            checked: true,
        },
        {
            propName: 'creationDate',
            field: 'creationDate',
            headerName: 'Дата создания',
            checked: true,
        },
        {
            propName: 'installationDate',
            field: 'installationDate',
            headerName: 'Дата установки',
            checked: true,
        },
        {
            propName: 'fullName',
            field: 'fullName',
            headerName: 'ФИО',
            checked: true,
        },
        {
            propName: 'address',
            field: 'address',
            headerName: 'Адрес',
            checked: true,
        },
        {
            propName: 'phone',
            field: 'phone',
            headerName: 'Номер телефона',
            checked: true,
        },
        {
            propName: 'preferentialCategory',
            field: 'preferentialCategory',
            headerName: 'Льготная категория',
            checked: true,
        },
    ]

    // переменная для обозначения ключевых моментов изменения столбцов. Входит в массив зависимостей useMemo в RequsetTable
    // значение не важно. Главное ее изменение и последующая перерисовка столбцов таблицы.
    actionIndicator = false
    #toggleActionIndicator = () => {
        this.actionIndicator = !this.actionIndicator
    }

    // при загрузке страницы загружаем настройки столбцов из localStorage если они там есть
    actualizeColumns = () => {
        if (localStorage.getItem('requestColumns')) {
            this.columns = JSON.parse(localStorage.getItem('requestColumns'))
        }
        this.#toggleActionIndicator()
    }

    // функция для переключения статуса столбца
    setColumnCheckbox = (propName) => {
        this.columns = this.columns.map((column) => {
            if (column.propName === propName) {
                column.checked = !column.checked
            }
            return column
        })
    }

    // функция для сброса настроек столбцов, все столбцы переходят в статус true
    // локалСторадж очищается
    resetSettings = () => {
        localStorage.removeItem('requestColumns')
        this.columns = this.columns.map((column) => {
            column.checked = true
            return column
        })
        this.#toggleActionIndicator()
    }

    // функция для применения настроек столбцов
    submitSetting = () => {
        localStorage.setItem('requestColumns', JSON.stringify(this.columns))
        // на странице управления заявками в RequestTable
        // запустится колбек в useMemo
        this.#toggleActionIndicator()
        closeModalFn['request-table-settings']()
    }
}

export default RequestTableSettingsStore
