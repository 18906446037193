import React from 'react';
import CustomDialog from '../../../../components/CustomDialog/CustomDialog';
import { closeModalFn, isModalOpen } from '../../../../utils/controllers/ModalController';
import { useIntl } from 'react-intl';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { FormattedMessage } from 'react-intl/lib';
import { observer } from 'mobx-react';
import { getComp } from '../../../../utils/utils/DI';
import Button from '@mui/material/Button';
import DialogItem from '../../../OperationalSummary/components/OperationSettingsDialog/components/DialogItem';
import { Checkbox, IconButton } from '@mui/material';
import { getStyles } from '../../../../utils/utils/commonUtils';
import settingsDialogStyles from './styles/SettingsDialogStyles';
// import cross from '../../assets/images/common/cross_dark.svg'
import cross from '../../../../assets/images/common/cross_dark.svg';
const RequestTableSettingsDialog = ({ store }) => {
    const classes = getStyles(settingsDialogStyles);
    const intl = useIntl();
    const { resetSettings, submitSetting, columns, setColumnCheckbox } = store;

    return (
        <CustomDialog
            open={isModalOpen['request-table-settings']}
            // onClose={() => {
            //     closeModalFn["request-table-settings"]()
            // }}
            // title={intl.formatMessage({id: "Настроить страницу управления"})}
            styles={classes.customDialog}
            customTopPanel={true}
        >
            <Box className={'fx-col fx-center'} sx={{ position: 'relative' }}>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        marginBottom: '16px',
                    }}
                >
                    <Typography sx={{ fontSize: '20px', fontWeight: 500 }}>
                        <FormattedMessage id={'Настроить страницу управления'} />
                    </Typography>
                    <IconButton
                        onClick={() => {
                            closeModalFn['request-table-settings']();
                        }}
                        sx={{ position: 'absolute', top: '-19px', right: '-39px' }}
                    >
                        <img alt='' src={cross} />
                    </IconButton>
                </Box>
                <Typography sx={{ fontSize: '16px', fontWeight: 500 }}>
                    <FormattedMessage id={'Выберите столбцы для отображения'} />
                </Typography>
                <Box sx={classes.contentContainer}>
                    {columns.map((column) => column.propName === 'preferentialCategory' ? null : (
                        <Box key={column.propName} sx={classes.contentItem}>
                            <Checkbox checked={column.checked} onChange={() => setColumnCheckbox(column.propName)} />
                            <Typography>{column.headerName}</Typography>
                        </Box>
                    ))}
                </Box>
                <Box className={'fx-nowrap'} sx={classes.buttonsContainer}>
                    <Button
                        variant={'contained'}
                        color={'primary'}
                        fullWidth={true}
                        onClick={() => {
                            submitSetting();
                        }}
                    >
                        <FormattedMessage id={'Применить'} />
                    </Button>

                    <Button
                        variant={'outlined'}
                        color={'primary'}
                        fullWidth={true}
                        onClick={() => {
                            resetSettings();
                        }}
                    >
                        <FormattedMessage id={'Сбросить'} />
                    </Button>
                </Box>
            </Box>
        </CustomDialog>
    );
};

export default observer(RequestTableSettingsDialog);
