import { makeAutoObservable } from 'mobx'
import getCookie from '../utils/utils/getCookieByName'

class WebSocketService {
    constructor() {
        this.connection = null
        this._clientCookie = getCookie('csid')
        this.url = `${window.SAT.config.apiUrl}/ws?access_token=${this._clientCookie}`
        makeAutoObservable(this)
    }

    setConnection = (withEventsData, optionalCallback) => {
        this._clientCookie = getCookie('csid')
        this.url = `${window.SAT.config.apiUrl}/ws?access_token=${this._clientCookie}`
        if (this._clientCookie) {
            this.connection = new window.signalR.HubConnectionBuilder()
                .withUrl(`${this.url}`, {
                    transport: signalR.HttpTransportType.WebSockets,
                    skipNegotiation: true,
                })
                .configureLogging(window.signalR.LogLevel.Information)
                .withAutomaticReconnect([5000, 5000, 5000, 5000])
                .build()

            this.connection.onclose((err) => {
                if (err) {
                    setTimeout(this.start, 5000)
                }
            })

            this.connection.onreconnected(() => {
                this._clientCookie = getCookie('.csid')
                this.url = `${window.SAT.config.apiUrl}/ws?access_token=${this._clientCookie}`
                setTimeout(this.start, 5000)
            })
            this.start()
            if (Array.isArray(withEventsData)) {
                withEventsData.forEach(({ eventName, wsCallback }) => {
                    this.connection.on(eventName, (data) => wsCallback(data))
                })
                return
            }
            if (typeof withEventsData === 'object') {
                this.connection.on(withEventsData.eventName, (data) => withEventsData.wsCallback(data))
                return
            }
            if (typeof withEventsData === 'string' && typeof optionalCallback === 'function') {
                this.connection.on(withEventsData, (data) => optionalCallback(data))
            }
        }
    }

    start = () => {
        this.connection
            .start()
            .then(() => {})
            .catch((err) => {
                if (err.message && err.message.includes(`Status code '403'`)) {
                    this.disconnect()
                } else {
                    this._clientCookie = getCookie('csid')
                    this.url = `${window.SAT.config.apiUrl}/ws?access_token=${this._clientCookie}`
                    setTimeout(this.start, 5000)
                }
            })
    }

    disconnect = () => {
        if (this.connection) {
            this.connection.stop()
        }
    }

    // send = () => {
    //     this.connection.invoke('send', 'Приветики конфетики')
    // }
}

export default WebSocketService
