import React, { useCallback, useEffect, useState } from 'react'
import {
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Paper,
    Button,
    Box,
    Typography,
    Divider,
} from '@mui/material'
import { observer } from 'mobx-react'

import TableActionsBlock from 'src/utils/components/CustomTable/components/TableActionsBlock/TableActionsBlock'
import store from './RequestsTableOptimizedStore'
import HeadCheckbox from './HeadCheckbox'
import TableOptimizedRow from './TableOptimizedRow'
import StackActions from '../../../../utils/components/StackActions/StackActions'
import StackActionStore from '../../../../utils/components/StackActions/StackActionStore'
import { debounce } from '../../../../utils/debounce/debounce'
import MassOperations from './MassOperations'
import { FormattedMessage, useIntl } from 'react-intl'
import TableSortIcon from '../../../../components/customElements/TableSortIcon/TableSortIcon'
import { openModalFn } from '../../../../utils/controllers/ModalController'
import ParamsFilterStore from '../../../SummaryStatisticsDealers/store/ParamsFilterStore'
import downLoad from 'src/assets/images/common/downloadWhite.svg'
import downLoadGray from 'src/assets/images/common/downloadGray.svg'
import NameReport from '../../../Reports/components/SelectedReport/components/NameReport'
import NewRequestFilters from '../NewRequestFilters/NewRequestFilters'
import summaryStatisticsDealersStoreInstance from '../../../SummaryStatisticsDealers/store/SummaryStatisticsDealersStore'

const columns = [
    {
        propName: 'status',
        label: 'Статус',
    },
    {
        propName: 'financialSourceId',
        label: 'Оплата',
        sort: 'financial-source',
        handleClick: 'financial-source',
    },
    {
        propName: 'dealer',
        label: 'Дилер',
    },
    {
        propName: 'sender',
        label: 'Источник',
    },
    {
        propName: 'id',
        label: 'Номер заявки',
        sort: 'id',
        handleClick: 'id',
        aline: 'left',
        textAlign: 'left',
    },
    {
        propName: 'created',
        label: 'Дата создания',
        sort: 'created',
        handleClick: 'created',
        aline: 'center',
    },
    {
        propName: 'installationDate',
        label: 'Дата установки',
        sort: 'installed',
        handleClick: 'installed',
        aline: 'center',
    },
    {
        propName: 'fullName',
        label: 'ФИО',
        sort: 'fullname',
        handleClick: 'fullname',
    },
    {
        propName: 'address',
        label: 'Адрес',
        sort: 'locality',
        handleClick: 'locality',
    },
    {
        propName: 'phone',
        label: 'Номер телефона',
        aline: 'left',
    },
]

const RequestsTableOptimized = ({
    selectAllMode = false,
    filter,
    ordersStore,
    onRowClick,
    showPagination = true,
    filterStore,
    selectOrdersStore,
}) => {
    const { data, addServiceProps, clearSelectOrders, getIdSelectedOrders, firstItem, isSomeChecked } = store
    const { count, request, closeRequest, dealerName, titleDate } = summaryStatisticsDealersStoreInstance
    const intl = useIntl()
    const [inputValue, setInputValue] = useState(ordersStore.pageNumber + 1)
    const [stackActionStore] = useState(new StackActionStore({ store: store }))
    const [newRowsFront, setNewRowsFront] = useState([])
    const { collection } = ordersStore
    const { params, loadFile } = ParamsFilterStore

    useEffect(() => {
        addServiceProps(collection)
        getIdSelectedOrders()
    }, [collection])
    
    useEffect(() => {
        if (selectAllMode) {
            store.selectAllMode = true
        }
        return () => {
            store.selectAllMode = false
            store.clearSelectOrders()
        }
    }, [])

    const onPageChange = useCallback(
        (event, page) => {
            if (page >= 0) {
                ordersStore.loadPageAction(page)
            }
        },
        [ordersStore]
    )

    const onPageSizeChange = useCallback(
        (event) => {
            ordersStore.pageSize = event.target.value
            onPageChange(0, 0)
        },
        [onPageChange, ordersStore]
    )
   
    return (
        <TableContainer sx={{ borderRadius: '8px', marginTop: '0px', minHeight: '80vh' }} component={Paper}>
            <Box>
                {filter ? (
                    <Box mb={1} mr={3} ml={3} pt={1}>
                        <NameReport
                            onClose={closeRequest}
                            reportTitle={`${dealerName}, Просрочено за период ${titleDate}`}
                            paddingX={'0'}
                        />
                        <Divider sx={{ marginBottom: '24px' }} />
                        <NewRequestFilters
                            SSDPageParams={params}
                            gray
                            filterRowStyles={{ background: '#fff' }}
                            totalCollectionLength={ordersStore.total}
                            filtersStore={filterStore}
                            ordersStore={ordersStore}
                            selectOrdersStore={selectOrdersStore}
                            actionButtonProps={{
                                name: intl.formatMessage({
                                    id: 'Сохранить в файл',
                                }),
                                onClick: () => loadFile(data),
                                icon: true ? downLoad : downLoadGray,
                                disabled: !isSomeChecked(),
                            }}
                            scrollIsAlreadyLocked
                        />
                        <Divider sx={{ marginTop: '24px' }} />
                    </Box>
                ) : null}
            </Box>
            <TableActionsBlock
                rows={data}
                actionsBlock={
                    filter ? null : (
                        <MassOperations
                            stackActionStore={stackActionStore}
                            status={firstItem?.status}
                            collection={data}
                        />
                    )
                }
                showPagination={showPagination}
                total={ordersStore.total}
                setInputValue={setInputValue}
                pageSize={ordersStore.pageSize}
                inputValue={inputValue}
                page={ordersStore.pageNumber}
                rowsPerPageOptions={[50, 100, 200, 300, 500]}
                onPageSizeChange={onPageSizeChange}
                onPageChange={debounce(clearSelectOrders, onPageChange, 300)}
                doublePagination={true}
                setNewRowsFront={setNewRowsFront}
            >
                <Table sx={{ minWidth: 650 }} aria-label='simple table'>
                    <TableHead>
                        <TableRow>
                            <TableCell />
                            <HeadCheckbox />
                            {columns.map((column) => (
                                <TableCell
                                    key={column.propName}
                                    onClick={
                                        column.handleClick ? () => ordersStore.reversPage(column.handleClick) : null
                                    }
                                    sx={{
                                        padding: '4px',
                                        background: '#fff',
                                        color: '#A9A9A9',
                                        height: '54px',
                                        cursor: column.handleClick ? 'pointer' : 'default',
                                        img: column.handleClick
                                            ? {
                                                  filter: 'invert(60%)',
                                              }
                                            : null,
                                        '&:hover': column.handleClick
                                            ? {
                                                  backgroundColor: column.handleClick ? 'rgb(175,211,239)' : null,
                                                  color: column.handleClick ? 'rgb(255, 255, 255)' : '#A9A9A9',
                                                  img: {
                                                      filter: column.handleClick ? 'invert(100%)' : 'invert(60%)',
                                                  },
                                              }
                                            : null,
                                    }}
                                >
                                    <Box sx={{ display: 'flex', justifyContent: column.aline, alignItems: 'center' }}>
                                        <Typography
                                            sx={{
                                                // padding: '8px',
                                                fontSize: '13px',
                                                fontWeight: '500',
                                                textAlign: column.textAlign || 'center',
                                            }}
                                        >
                                            {column.label}
                                        </Typography>
                                        {column.sort && (
                                            <TableSortIcon isReverse={ordersStore.checkIsRevers(column.sort)} />
                                        )}
                                    </Box>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((row, i) => {
                            return (
                                <TableOptimizedRow
                                    store={store}
                                    index={i}
                                    columns={columns}
                                    row={row}
                                    click={onRowClick}
                                />
                            )
                        })}
                    </TableBody>
                </Table>
                {!ordersStore.total || !ordersStore.isLoaded ? (
                    <Box className={'jst-center'} width={1} p={4.5} sx={{ background: 'rgba(217, 217, 217, 0.20)' }}>
                        <Typography sx={{ fontWeight: '500', color: '#A9A9A9', fontSize: '13px' }}>
                            {data.length ||
                                (!ordersStore.isLoaded ? (
                                    <FormattedMessage id={'загрузка..'} />
                                ) : (
                                    <FormattedMessage id={'нет данных для отображения'} />
                                ))}
                        </Typography>
                    </Box>
                ) : null}
            </TableActionsBlock>
        </TableContainer>
    )
}

export default observer(RequestsTableOptimized)
