import { action, computed, makeObservable, observable } from 'mobx'
import ServiceFilterStore from './ServiceFilterStore'
import PagedCollectionStore from '../../../utils/stores/PagedCollectionStore'
import { gState } from '../../../utils/controllers/GlobalStateController'
import { getIconFinancialSource } from '../../../helpers/switchFinancialSource'
import fullSubsidy from '../../../assets/images/financialSource/fullSubsidy.svg'
import {
    acceptServiceOrder,
    addServiceOrderComment,
    assignServiceOrderOnDealer,
    beginWorkWithOrder,
    changeServiceEquipmentId,
    completeOnSiteRepair,
    createServiceOrder,
    getInstalledOrders,
    getReworkServiceOrders,
    getServiceOrderComments,
    getServiceOrders,
    getServicePhotos,
    getServiceReasons,
    getServiceReceivers,
    getUploadServicePhotoSettings,
    improvementRequired,
    maintenanceOrdersRelease,
    maintenanceOrdersTake,
    markOrderAsImpossible,
    markServiceOrderAsDuplicate,
    noWrrantyDiagnostic,
    sendToDiagnostic,
    serviceImpossible,
    solvewithoutvisit,
    updateServiceOrderPut,
    wrrantyDiagnostic,
} from '../../../services/ApiService'
import { closeModalFn, isModalOpen, modalData, openModalFn } from '../../../utils/controllers/ModalController'
import { notifyError, notifySuccess } from '../../../services/ToastifyService/ToastifyService'
import { getComp } from '../../../utils/utils/DI'
import ServiceAddRequestFormStore from './ServiceAddRequestFormStore'
import moment from 'moment'
import ServiceInformationAccordionFormStore from './ServiceInformationAccordionFormStore'
import IntermediateDrawerFormStore from './IntermediateDrawerFormStore'
import WebSocketService from '../../../services/WebSocketService'
import { isOperator } from '../../../services/AccountService'

class ServiceStore extends PagedCollectionStore {
    constructor() {
        super({ action: getServiceOrders })
        this.wsConnection = new WebSocketService()
        this.pageSize = 100
        this.serviceStore = true
        makeObservable(this, {
            reasonCategories: observable,
            installedOrders: observable,
            showSearchResult: observable,
            searchByIdMode: observable,
            searchByPhoneMode: observable,
            isLoading: observable,
            haveNotFound: observable,
            showContinueInfo: observable,
            showAddServiceRequestForm: observable,
            checkedSeviceOrder: observable,
            wsConnection: observable,
            isChecked: action,
            showToggleToPhoneBtn: observable,
            equipmentIdError: computed,
            newEquipment: observable,
            showInfoDrawer: observable,
            setShowInfoDrawer: action,
            showAssignToDealerView: observable,
            currentInfoDrawerData: observable,
            newDealer: observable,
            setShowAssignToDealerView: action,
            clearCheckedSeviceOrder: action,
            openedAccordionIndex: observable,
            setOpenedAccordionIndex: action,
            comment: observable,
            setComment: action,
            disabledBtnSubmitRequest: observable,
            toggleDisabledBtnSubmitRequest: action,
            newStatus: observable,

            serviceOrderPhotosUrls: observable,
            photosInfoList: observable,
            clearServicePhotosUrls: action,

            fromDrawer: observable,
            setFromDrawer: action,
            getUploadServicePhotoSettingsFunc: action,
            selectedTab: observable,
            editSelectTab: action,
            toggleStatusOnly: observable,
        })
        this.categoriesStore = getComp('CategoriesStore')
        this.installationReasonStore = getComp('InstallationReasonStore')
        this.serviceFilterStore = new ServiceFilterStore()
        this.serviceAddRequestFormStore = new ServiceAddRequestFormStore()
        this.serviceInformationAccordionFormStore = new ServiceInformationAccordionFormStore()
        this.intermediateDrawerFormStore = new IntermediateDrawerFormStore()
        this.statusesStore = getComp('StatusesStore')
        this.dealersStore = getComp('DealersStore')
        this.loadParams = {
            ...this.loadParams,
            size: 100,
            page: this.pageNumber,
        }
    }
    intl = gState['intl']
    comment = ''
    selectedTab = null
    disabledBtnSubmitRequest = false
    reasonCategories = [] // список причин обращения
    installedOrders = [] // список найденных установленных заказов
    showSearchResult = false
    toggleStatusOnly = false // ножно для того чтобы скрыть весь контент при редактировании статуса
    searchByIdMode = true
    searchByPhoneMode = false
    haveNotFound = false
    showContinueInfo = false
    showAddServiceRequestForm = false
    showToggleToPhoneBtn = false
    checkedSeviceOrder = null // выбранная заявка в дровере
    checkedServiceTableOrderIds = [] // выбранные заявки в таблице

    newEquipment = ''

    isLoading = false

    currentInfoDrawerData = null
    showInfoDrawer = false
    showAssignToDealerView = false
    newDealer = null

    openedAccordionIndex = 0

    newStatus = null
    serviceReceivers = null

    serviceOrderPhotosUrls = []
    photosInfoList = null
    fromDrawer = false
    uploadServicePhotoSettings = null // настройки загрузки фотографий
    setComment = (e) => {
        this.comment = e.target.value
    }
    setToggleStatusOnly = (close) => {
        this.toggleStatusOnly = close ? null : !this.toggleStatusOnly
    }
    toggleDisabledBtnSubmitRequest = (value) => {
        // не дает вернуть заявку во время редактирования
        this.disabledBtnSubmitRequest = value
    }

    setFromDrawer = (value) => {
        // если обнулить то вернет к первой заявке(начало экрана)
        this.fromDrawer = value
    }
    clearServicePhotosUrls = () => {
        this.serviceOrderPhotosUrls = []
    }

    setOpenedAccordionIndex = (index) => {
        this.openedAccordionIndex = index
    }

    setShowAssignToDealerView = (value) => {
        this.showAssignToDealerView = value
    }

    setShowInfoDrawer = (data) => {
        if (data !== null) {
            this.currentInfoDrawerData = data
        }
    }

    updateCurrentInfoDrawerData = () => {
        const currentId = this.currentInfoDrawerData.id
        this.currentInfoDrawerData = this.collection.find((item) => item.id === currentId)
    }

    getTechnicalData = () => {
        getServiceReasons().then((res) => {
            this.reasonCategories = res.maintenanceReasons
        })
    }

    gerReworkPagedData = (param = { page: 0, size: 100 }) => {
        this.pageSize = param.size
        getReworkServiceOrders(param).then((res) => {
            this.setData(res)
        })
    }

    reLoadpageSize = () => {
        this.pageSize = 100
    }

    get serviceCollection() {
        this.collection.map((order) => {
            this.checkedServiceTableOrderIds.find((id) => id === order.id) && (order.checked = true)

            if (order.ticketStatus && Number.isInteger(order.status)) {
                let complexStatus = Number(order.status) + '.' + Number(order.ticketStatus)
                complexStatus = Number(complexStatus)

                order.status = complexStatus
            }

            return order
        })
        return this.collection
    }

    loadPageAction(page, pageSize = null) {
        this.pageNumber = page
        if (pageSize) {
            this.pageSize = pageSize
        }

        const queryParam = {
            ...this.loadParams,
            page,
            size: this.pageSize,
        }
        if (this.selectedTab === 2 || !isOperator()) {
            this.reLoad(queryParam)
        } else {
            this.gerReworkPagedData(queryParam)
        }
    }

    normalizePhoneNumber(phoneNumber) {
        return phoneNumber.replace(/\D/g, '')
    }

    getInstalledOrders = () => {
        this.setOpenedAccordionIndex(0)
        const params = {
            equipmentId: this.serviceAddRequestFormStore.data.equipmentId || null,
            phoneNumber: this.normalizePhoneNumber(this.serviceAddRequestFormStore.data.phoneNumber) || null,
        }

        if (params.equipmentId || params.phoneNumber) {
            openModalFn['progress-backdrop']()
            this.isLoading = true
            getInstalledOrders(params).then((res) => {
                // изменяю поле льготных категорий в обьекте заявки для отображения
                const normalizedOrders = res.orders.map((order) => {
                    return {
                        ...order,
                        preferentialCategories: this.categoriesStore.formatCategory(order.preferentialCategories),
                        installationReasonId: this.installationReasonStore.getInstallationReasonName(
                            order.installationReasonId
                        ),
                    }
                })

                this.installedOrders = normalizedOrders
                this.showSearchResult = true
                if (!this.installedOrders.length) {
                    this.haveNotFound = true
                    this.showToggleToPhoneBtn = true
                }

                closeModalFn['progress-backdrop']()
                this.isLoading = false
            })
        }
    }

    clearInputs = () => {
        this.serviceAddRequestFormStore.clear()
    }

    onCloseDrawer = () => {
        closeModalFn['create-service-request']()
        this.showSearchResult = false
        this.clearInputs()
        this.searchByIdMode = true
        this.searchByPhoneMode = false
        this.haveNotFound = false
        this.showContinueInfo = false
        this.showAddServiceRequestForm = false
        this.clearCheckedSeviceOrder()
        this.installedOrders = []
        this.showToggleToPhoneBtn = false
        this.setOpenedAccordionIndex(0)
    }

    toggleOnPhoneSearchMode = () => {
        this.searchByIdMode = false
        this.searchByPhoneMode = true
        this.showSearchResult = false
        this.serviceAddRequestFormStore.data.equipmentId = ''
    }

    toggleToShowContinueInfo = () => {
        this.serviceAddRequestFormStore.data.equipmentId = ''
        this.searchByIdMode = false
        this.searchByPhoneMode = false
        this.showSearchResult = false
        this.showContinueInfo = true
    }

    showToggleToPhoneBtnFunc = () => {
        this.showToggleToPhoneBtn = true
    }

    goOnStartDrawer = () => {
        this.searchByIdMode = true
        this.searchByPhoneMode = false
        this.haveNotFound = false
        this.showContinueInfo = false
        this.showAddServiceRequestForm = false
        this.showToggleToPhoneBtn = false
        this.clearCheckedSeviceOrder()
        this.clearInputs()
        this.installedOrders = []
        this.showSearchResult = false
        this.setOpenedAccordionIndex(0)
    }

    clearCheckedSeviceOrder = () => {
        this.checkedSeviceOrder = null
    }

    showAddServiceRequestFormFunc = (order) => {
        if (order && order.type !== 'click') {
            this.checkedSeviceOrder = {
                ...order,
                preferentialCategories: this.categoriesStore.formatCategory(order.preferentialCategories),
            }
        }

        if (this.checkedSeviceOrder) {
            Object.keys(this.serviceAddRequestFormStore.data).forEach((key) => {
                if (key === 'senderId') {
                    return (this.serviceAddRequestFormStore.data[key] = Number(this.checkedSeviceOrder[key]))
                }

                if (key === 'preferentialCategories') {
                    const categories = this.categoriesStore.getIndexesOfMatchingElements(
                        this.checkedSeviceOrder[key].split(', ')
                    )

                    return (this.serviceAddRequestFormStore.data[key] = categories)
                }

                if (key === 'phoneNumber') {
                    return (this.serviceAddRequestFormStore.data[key] =
                        this.checkedSeviceOrder['phone'] || this.checkedSeviceOrder['phoneNumber'])
                }
                if (key === 'orderId') {
                    return (this.serviceAddRequestFormStore.data['orderId'] = this.checkedSeviceOrder['id'])
                }

                if (key === 'installedOrderId') {
                    return (this.serviceAddRequestFormStore.data[key] = this.checkedSeviceOrder['orderId'])
                }

                if (key === 'created') {
                    return (this.serviceAddRequestFormStore.data[key] = moment().format('DD.MM.YYYYTHH:mm:ss'))
                }

                if (key === 'maintenanceReasonId') {
                    return this.serviceAddRequestFormStore.data[key]
                }

                if (key === 'addressType') {
                    if (this.checkedSeviceOrder[key] === null) {
                        return this.serviceAddRequestFormStore.data[key]
                    }
                    return (this.serviceAddRequestFormStore.data[key] = this.checkedSeviceOrder[key])
                }

                this.serviceAddRequestFormStore.data[key] = this.checkedSeviceOrder[key]
            })
        } else {
            this.serviceAddRequestFormStore.clear()
        }
        this.searchByIdMode = false
        this.searchByPhoneMode = false
        this.showSearchResult = false
        this.showContinueInfo = false
        this.showAddServiceRequestForm = true
    }

    createServiceOrder = () => {
        const params = {}
        Object.keys(this.serviceAddRequestFormStore.data).forEach((key) => {
            if (this.serviceAddRequestFormStore.data[key]) {
                if (key === 'equipmentId') {
                    return (params[key] = null)
                }
                if (key === 'maintenanceReasonId' || key === 'senderId') {
                    return (params[key] = Number(this.serviceAddRequestFormStore.data[key]))
                }

                if (key === 'created') {
                    return (params[key] = moment(this.serviceAddRequestFormStore.data[key], 'DD.MM.YYYYTHH:mm').format(
                        'YYYY-MM-DDTHH:mm:ssZ'
                    ))
                }
                return (params[key] = this.serviceAddRequestFormStore.data[key])
            }
        })

        this.onCloseDrawer()
        createServiceOrder(params).then((res) => {
            if (this.selectedTab === 3) {
                this.gerReworkPagedData()
                notifySuccess('Заявка создана')
            } else {
                this.reLoad()
                notifySuccess('Заявка создана')
            }
        })
    }

    addThisToCheckedServiceOrder = (id) => {
        if (this.checkedSeviceOrder && this.checkedSeviceOrder.orderId === id) {
            this.checkedSeviceOrder = null
        } else {
            this.checkedSeviceOrder = this.installedOrders.find((item) => item.orderId === id)
        }
    }

    get checkedServiceOrderId() {
        return this.checkedSeviceOrder ? this.checkedSeviceOrder.orderId : null
    }

    selectAllOrdersOnPage = () => {
        this.installedOrders.forEach((order) => {
            order.checked = true
        })
    }

    checkOrderFunc = (order) => {
        order.checked = !order.checked

        if (this.checkedServiceTableOrderIds.find((el) => el === order.id)) {
            this.checkedServiceTableOrderIds = this.checkedServiceTableOrderIds.filter((el) => el !== order.id)
        } else {
            this.checkedServiceTableOrderIds.push(order.id)
        }
    }

    checkAllOrdersFunc = () => {
        if (this.serviceCollection.every((el) => el.checked)) {
            this.serviceCollection.map((el) => (el.checked = false))
            this.checkedServiceTableOrderIds = []
            return
        }

        this.serviceCollection.map((el) => {
            el.checked = true
            if (!this.checkedServiceTableOrderIds.find((id) => id === el.id)) {
                this.checkedServiceTableOrderIds.push(el.id)
            }
        })
        return
    }

    isChecked = (id) => {
        return this.checkedServiceTableOrderIds.find((el) => el === id) ? true : false
    }

    get equipmentIdError() {
        this.newEquipment = this.serviceAddRequestFormStore.data.equipmentId

        let summ
        let number
        if (this.serviceAddRequestFormStore.data.equipmentId?.length) {
            number = +this.newEquipment.slice(0, 2)
            if (this.serviceAddRequestFormStore.data.equipmentId.length > 2) {
                summ = this.serviceAddRequestFormStore.data.equipmentId
                    .slice(2, 14)
                    .split('')
                    .reduce((acc, el) => {
                        return +acc + +el
                    })
            }
        }
        return !(this.serviceAddRequestFormStore.data.equipmentId?.length === 14 && summ === number)
    }

    updateServiceOrder = async (selectedTab3) => {
        const params = {}

        Object.keys(this.serviceAddRequestFormStore.data).forEach((key) => {
            if (this.serviceAddRequestFormStore.data[key]) {
                if (key === 'equipmentId') {
                    return (params[key] = null)
                }
                if (key === 'maintenanceReasonId' || key === 'senderId') {
                    return (params[key] = Number(this.serviceAddRequestFormStore.data[key]))
                }
                if (key === 'phone') {
                    return (params[key] = this.serviceAddRequestFormStore.data[key].replace(/\D/g, ''))
                }

                if (key === 'created') {
                    return (params[key] = moment(this.serviceAddRequestFormStore.data[key], 'DD.MM.YYYYTHH:mm').format(
                        'YYYY-MM-DDTHH:mm:ssZ'
                    ))
                }
                return (params[key] = this.serviceAddRequestFormStore.data[key])
            }
        })
        await updateServiceOrderPut(params).then(() => {
            if (selectedTab3) {
                this.gerReworkPagedData()
            } else {
                this.reLoad()
            }
            // notifySuccess('Операция выполнена успешно!')
        })
    }

    editSelectTab = (value) => {
        this.selectedTab = value
    }

    changeServiceOrderEquipmentId = async ({ operatorStore, params }) => {
        await changeServiceEquipmentId(params)
            .then(() => {
                if (operatorStore?.selectedTab && operatorStore?.selectedTab === 3) {
                    this.gerReworkPagedData()
                } else {
                    this.reLoad()
                }
            })
            .finally(() => {
                closeModalFn['progress-backdrop']()
            })
    }

    availableStatusesToChange(admin, status) {
        // let availableStatuses = [1, 2, 3, 4, 5]

        if (admin && status === 7) {
            const availableStatuses = [2, 10]
            return this.statusesStore.reportServiceOrderStatuses.filter((status) =>
                availableStatuses.includes(status.id)
            )
        } else if (admin && status === 11) {
            const availableStatuses = [2, 7, 10]
            return this.statusesStore.reportServiceOrderStatuses.filter((status) =>
                availableStatuses.includes(status.id)
            )
        } else if (status === 11) {
            const availableStatuses = [2, 7]
            return this.statusesStore.reportServiceOrderStatuses.filter((status) =>
                availableStatuses.includes(status.id)
            )
        } else {
            const availableStatuses = [1, 2, 3, 4, 5]
            return this.statusesStore.reportServiceOrderStatuses.filter((status) =>
                availableStatuses.includes(status.id)
            )
        }
    }

    // availableStatusesToChangeMaintenance() {
    //     const availableStatuses = [2, 7]
    //     return this.statusesStore.reportServiceOrderStatuses.filter((status) => availableStatuses.includes(status.id))
    // }

    // контроллер открытия дровера
    // сохраняет данные выбранной заявки в модалку "Текущая сервисная заявка"
    // затем открывает дровер

    serviceDrawersController = (e) => {
        this.clearServicePhotosUrls()
        openModalFn['current-service-data']({ serviceOrder: e.data })
        openModalFn['common-service-drawer']()
    }

    get currentServiceData() {
        // текущая реализация медленно работает на большом количестве заявок
        // надо переделать получение и обновление заявки без перебора коллекции
        if (!!modalData['current-service-data']?.serviceOrder) {
            const updatedOrderData = this.serviceCollection.find(
                (item) => item.id === modalData['current-service-data'].serviceOrder.id
            )
            return updatedOrderData
        }
        return null
    }

    acceptServiceOrderFunc = async (id) => {
        await acceptServiceOrder({ orderId: id })
            .then(() => {
                this.reLoad()
                // notifySuccess('Операция выполнена успешно!')
            })
            .finally(() => {
                closeModalFn['progress-backdrop']()
            })
    }

    openTransferToDuplicateDrawerFunc = (data) => {
        openModalFn['transfer-to-duplicate-drawer']({ serviceOrder: data })
    }

    getServiceComments = async (id) => {
        openModalFn['progress-backdrop']()
        await getServiceOrderComments(id)
            .then((res) => {
                const narmalizedCommentsArray = res.map((item) => {
                    const newDate = moment.utc(item.date).toDate()
                    item.date = moment(newDate).format('DD.MM.YYYY,HH:mm')

                    return item
                })
                modalData['current-service-data'].serviceOrder.comments = narmalizedCommentsArray
            })
            .finally(() => {
                closeModalFn['progress-backdrop']()
            })
    }

    postServiceOrderComment = async (params) => {
        await addServiceOrderComment(params)
            .then(() => {
                notifySuccess('Комментарий добавлен')
            })

            .finally(() => {
                closeModalFn['progress-backdrop']()
            })
    }

    getServiceReceiversFunc = async (id) => {
        await getServiceReceivers(id)
            .then((res) => {
                this.serviceReceivers = res
            })
            .finally(() => {
                closeModalFn['progress-backdrop']()
            })
    }

    beginWorkWithOrderFunc = async (id) => {
        await beginWorkWithOrder({ orderId: id })
            .then(() => {
                this.reLoad()
                // notifySuccess('Операция выполнена успешно!')
            })
            .finally(() => {
                closeModalFn['progress-backdrop']()
            })
    }

    completeOnSiteRepairFunc = () => {
        const params = {}

        const files =
            this.serviceInformationAccordionFormStore.data['files'] &&
            this.serviceInformationAccordionFormStore.data['files'].map((el) => el.file)

        params.orderId = this.serviceInformationAccordionFormStore.data['orderId']
        params.receiverSerialNumber = this.serviceInformationAccordionFormStore.data['receiverSerialNumber']
        // поправляю время(в utc) и id модель(числовое значение)
        params.receiverModelId = +this.serviceInformationAccordionFormStore.data['receiverModelId']
        params.dateOfMaintenance = moment(
            this.serviceInformationAccordionFormStore.data['dateOfMaintenance'],
            'DD.MM.YYYYTHH:mm'
        ).format('YYYY-MM-DDTHH:mm:ssZ')
        if (files) {
            params.files = files
        }

        completeOnSiteRepair(params).then((res) => {
            // this.checkOnUploadDublicateError(res, this.serviceInformationAccordionFormStore.data['files'])
            if (res.uploaded === false) {
                res.images.map((item) => {
                    if (item.error === 'Дубликат') {
                        notifyError(`Дубликат: ${item.filename}`)
                    }
                })
                // this.serviceInformationAccordionFormStore.data['files'] = null
                return
            }
            this.serviceInformationAccordionFormStore.clear()
            this.reLoad()
            closeModalFn['on-site-service-drawer']()
            // notifySuccess('Операция выполнена успешно!')
        })
    }

    checkOnUploadDublicateError = async (res, formStoreField) => {
        if (res.uploaded === false) {
            res.images.map((item) => {
                if (item.error === 'Дубликат') {
                    notifyError(`Дубликат: ${item.filename}`)
                }
            })
            formStoreField = null
            return false
        }
    }

    getServicePhotosFunc = async (id, photoField) => {
        const field = photoField

        await getServicePhotos({
            orderId: id,
        }).then((res) => {
            this.photosInfoList = res[field]
            this.photosInfoList.forEach((item, i) => {
                const imageUrl = window.SAT.config.apiUrl + `/maintenance/Photos/getimage?photoId=${item.id}`
                this.photosInfoList[i].url = imageUrl
                this.serviceOrderPhotosUrls.push(imageUrl)
            })
        })
    }

    sendToDiagnosticFunc = async () => {
        const params = {}
        const files = this.serviceInformationAccordionFormStore.data['files']
            ? this.serviceInformationAccordionFormStore.data['files'].map((el) => el.file)
            : []

        params.orderId = this.serviceInformationAccordionFormStore.data['orderId']
        params.receiverSerialNumber = this.serviceInformationAccordionFormStore.data['receiverSerialNumber']
        // поправляю время(в utc) и id модель(числовое значение)
        params.receiverModelId = +this.serviceInformationAccordionFormStore.data['receiverModelId']
        params.dateOfSeizure = moment(
            this.serviceInformationAccordionFormStore.data['dateOfMaintenance'],
            'DD.MM.YYYYTHH:mm'
        ).format('YYYY-MM-DDTHH:mm:ssZ')
        params.files = files
        params.diagnosticPassBasisComment = this.serviceInformationAccordionFormStore.data['diagnosticPassBasisComment']
        await sendToDiagnostic(params).then((res) => {
            // this.checkOnUploadDublicateError(res, this.serviceInformationAccordionFormStore.data['files']).then(() => {
            //     if (res === false) {
            //         return
            //     }
            // })
            if (res.uploaded === false) {
                res.images.map((item) => {
                    if (item.error === 'Дубликат') {
                        notifyError(`Дубликат: ${item.filename}`)
                    }
                })
                // this.serviceInformationAccordionFormStore.data['files'] = null
                return
            }
            this.serviceInformationAccordionFormStore.clear()
            this.reLoad()
            closeModalFn['transfer-to-service-center-drawer']()
            // notifySuccess('Операция выполнена успешно!')
        })
    }

    solvewithoutvisitFunc = async () => {
        const params = {}

        params.orderId = this.intermediateDrawerFormStore.data['orderId']
        params.comment = this.intermediateDrawerFormStore.data['comment']
        await solvewithoutvisit(params).then(() => {
            this.intermediateDrawerFormStore.clear()
            this.reLoad()
            closeModalFn['intermediate-drawer']()
            // notifySuccess('Операция выполнена успешно!')
        })
    }

    guaranteeRepairFunc = async () => {
        const params = {}

        params.orderId = this.intermediateDrawerFormStore.data['orderId']
        params.externalTrackingNumber = +this.intermediateDrawerFormStore.data['comment']

        await wrrantyDiagnostic(params).then(() => {
            this.intermediateDrawerFormStore.clear()
            this.reLoad()
            closeModalFn['intermediate-drawer']()

            // notifySuccess('Операция выполнена успешно!')
        })
    }

    setWsConnection = () => {
        this.wsConnection.setConnection('Notification', (data) => {
            const { type, orderId, maintenanceOrderId, operatorId } = data

            switch (type) {
                case 6:
                    this.changeCollectionWsAction(maintenanceOrderId, type)
                    break
                case 7:
                    this.changeCollectionWsAction(maintenanceOrderId, type)
                    break
                default:
                    break
            }
        })
    }

    changeCollectionWsAction = (id, type) => {
        // this.refreshOrderId = id
        const order = this.serviceCollection.find((order) => order.id === id)
        if (order) {
            this.serviceCollection = this.serviceCollection.map((order) => {
                if (order.id === id) {
                    if (type === 7) {
                        order.isTaken = false
                        order.isTakenByMe = false
                        notifySuccess(`Заявка №${id} была возвращена оператором в общий список`)
                    } else if (type === 6) {
                        order.isTaken = true
                        order.isTakenByMe = false
                        notifySuccess(`Заявка №${id} была взята оператором в обработку`)
                    }
                    // this.refreshedOrder = order
                }
                return order
            })
        }
    }

    setIsTaken = ({ isTakenByMe, id }) => {
        let promise
        if (isTakenByMe) {
            promise = maintenanceOrdersRelease({ orderId: id })
        } else {
            promise = maintenanceOrdersTake({ orderId: id })
        }
        promise.then(() => this.gerReworkPagedData())
    }

    notGuaranteeRepairFunc = async () => {
        const params = {}

        params.orderId = this.intermediateDrawerFormStore.data['orderId']
        params.externalTrackingNumber = this.intermediateDrawerFormStore.data['comment']

        await noWrrantyDiagnostic(params).then(() => {
            this.intermediateDrawerFormStore.clear()
            this.reLoad()
            closeModalFn['intermediate-drawer']()

            // notifySuccess('Операция выполнена успешно!')
        })
    }

    improvementRequiredFunc = () => {
        const params = {}
        const files = this.intermediateDrawerFormStore.data['files']
            ? this.intermediateDrawerFormStore.data['files'].map((el) => el.file)
            : null
        params.orderId = +this.intermediateDrawerFormStore.data['orderId']
        params.comment = this.intermediateDrawerFormStore.data['comment']
        params.files = files

        improvementRequired(params).then((res) => {
            // this.checkOnUploadDublicateError(res, this.intermediateDrawerFormStore.data['files']).then(() => {
            //     if (res === false) {
            //         return
            //     }
            // })
            if (res.uploaded === false) {
                res.images.map((item) => {
                    if (item.error === 'Дубликат') {
                        notifyError(`Дубликат: ${item.filename}`)
                    }
                })
                // this.intermediateDrawerFormStore.data['files'] = null
                return
            }
            this.intermediateDrawerFormStore.clear()
            this.reLoad()
            closeModalFn['intermediate-drawer']()
            // notifySuccess('Операция выполнена успешно!')
        })
    }

    serviceImpossibleFunc = () => {
        const params = {}
        const files = this.intermediateDrawerFormStore.data['files']
            ? this.intermediateDrawerFormStore.data['files'].map((el) => el.file)
            : null
        params.orderId = +this.intermediateDrawerFormStore.data['orderId']
        params.comment = this.intermediateDrawerFormStore.data['comment']
        params.files = files

        serviceImpossible(params).then((res) => {
            // this.checkOnUploadDublicateError(res, this.intermediateDrawerFormStore.data['files'])
            if (res.uploaded === false) {
                res.images.map((item) => {
                    if (item.error === 'Дубликат') {
                        if (item.error === 'Дубликат') {
                            notifyError(`Дубликат: ${item.filename}`)
                        }
                    }
                })
                // this.intermediateDrawerFormStore.data['files'] = null
                return
            }
            this.intermediateDrawerFormStore.clear()
            this.reLoad()
            closeModalFn['intermediate-drawer']()
            // notifySuccess('Операция выполнена успешно!')
        })
    }

    markServiceOrderAsDuplicateFunc = async ({ top }) => {
        const params = {}
        params.orderId = top ? this.currentServiceData.id : +this.intermediateDrawerFormStore.data['orderId']
        if (this.comment || this.intermediateDrawerFormStore.data['comment']) {
            params.comment = top ? this.comment : this.intermediateDrawerFormStore.data['comment']
        }

        await markServiceOrderAsDuplicate(params).then(() => {
            this.intermediateDrawerFormStore.clear()
            this.reLoad()
            closeModalFn['intermediate-drawer']()
            // notifySuccess('Операция выполнена успешно!')
        })
    }

    //  получение настроек для загрузки фото
    getUploadServicePhotoSettingsFunc = () => {
        getUploadServicePhotoSettings().then((res) => {
            this.uploadServicePhotoSettings = res
        })
    }

    markAsImpossible = (admin) => {
        serviceImpossible({
            orderId: this.currentServiceData.id,
            comment: this.comment,
        }).then(() => {
            this.comment = ''
            this.newStatus = null
            admin ? this.reLoad() : this.gerReworkPagedData()
            notifySuccess('Заявка обновлена')
        })
    }

    assignDealer = (admin) => {
        assignServiceOrderOnDealer({
            dealerId: this.newDealer || this.currentServiceData.assignedDealerId,
            orderId: this.currentServiceData.id,
        }).then(() => {
            this.newStatus = null

            admin ? this.reLoad() : this.gerReworkPagedData()

            notifySuccess('Дилер назначен')
        })
    }
}

export default ServiceStore
