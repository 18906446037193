import { makeAutoObservable, makeObservable, observable } from 'mobx'
import FormStore from '../../../utils/stores/FormStore'
import moment from 'moment'

const initState = {
    statuses: [],
    phoneNumber: '',
    id: '',
    fullName: '',
    fullAddress: '',
    createdFrom: null,
    createdTo: null, 
    dealerIds: [],
    reportId: '',
    year: '',
    address: '',
    month: '',
    equipmentId: '',
    preferentialCategories: [],
    surname: '',
    name: '',
    patronymic: '',
    territory: '',
    district: '',
    locality: '',
    addressType: '',
    house: '',
    building: '',
    apartment: '',
    preferentialCategory: '',
    created: '',
    additionalInfo: '',
    senderId: '',
    maintenanceReasonId: null,
    authorId: '',
    sender: '',
    ticketStatuses: [],
    financialSourceIds: [],
}

const validateData = {
    status: { required: true },
}

class ServiceFilterStore extends FormStore {
    constructor() {
        super({ initState, validateData })
        this.param = null
        this.showErrors = true
        this.isValid = false
        makeObservable(this, {
            param: observable,
        })
    }
    isHighlighted = false

    makeHighlightTrue = () => {
        if (this.data.id !== '') {
            return
        }
        this.isHighlighted = true
    }

    makeHighlightFalse = () => (this.isHighlighted = false)

    clear = () => {
        this.param = null
        this.setData(initState)
        this.clearSelectedFilters()
        this.makeHighlightFalse()
    }

    makeParamCopy = (param) => {
        this.param = param
    }

    getParamCopy = () => this.param
}

export default ServiceFilterStore
