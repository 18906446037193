import { Box, Checkbox, TableCell } from '@mui/material'
import { observer } from 'mobx-react'
import React from 'react'
import store from './RequestsTableOptimizedStore'

function BodyCheckbox({row}) {
    const{toggleSelection} = store
    const handleClickCheckbox = (event, row) => {
        event.stopPropagation()
        toggleSelection(row)
    }
    return (
        <TableCell sx={{padding: '8px'}} >
            {row.showChecbox? <Checkbox checked={row.checked} onClick={(event) => handleClickCheckbox(event, row)}  />: <Box sx={{ height: '40.5px', width: '40.5px' }} />}
        </TableCell>
    )
}

export default observer(BodyCheckbox)
