import { Box, MenuItem, Typography } from '@mui/material'
import React, { useEffect, useMemo } from 'react'
import NewFilters from '../../../../components/NewFilters/NewFilters'
import { useIntl } from 'react-intl'
import { inject, observer } from 'mobx-react'
import serviceIcon from 'src/assets/images/service/serviceIcon.svg'
import { closeModalFn, openModalFn } from '../../../../utils/controllers/ModalController'
import { isAdministration, isDealer, isOperator } from '../../../../services/AccountService'
import moment from 'moment'
import { getStyles } from '../../../../utils/utils/commonUtils'
import { getComp } from '../../../../utils/utils/DI'
import requestFiltersStyles from '../../../RequestsManagement/styles/requestFiltersStyles'
import avatarIcon from 'src/assets/images/common/avatar.svg'
import ServiceFilterChip from './ServiceFilterChip'
import { when } from 'mobx'

const ServiceFilters = ({ serviceStore, isReportCreate, serviceSelectOrdersStore, selectedTab }) => {
    const intl = useIntl()
    const { serviceFilterStore } = serviceStore
    const { clearSelectOrders } = serviceSelectOrdersStore
    const classes = getStyles(requestFiltersStyles)
    const statusStore = getComp('StatusesStore')
    const authorsStore = getComp('AuthorsStore')
    const { reasons } = getComp('InstallationReasonStore')
    const sendersStore = getComp('SendersStore')
    const dealersStore = getComp('DealersStore')
    const sources = getComp('SourcesStore').sources

    const onSearch = async () => {
        openModalFn['progress-backdrop']()
        const { setSelectedFilters, makeHighlightTrue, makeParamCopy } = serviceFilterStore
        const param = {}
        function normalizePhoneNumber(phoneNumber) {
            return phoneNumber.replace(/\D/g, '')
        }
        for (const key in serviceFilterStore.data) {
            if (!!serviceFilterStore.data[key]) {
                switch (key) {
                    case 'dealerIds':
                        if (serviceFilterStore.data[key] === 'none') {
                            param.withNoDealer = true
                            break
                        }
                        param[key] = serviceFilterStore.data[key].map(
                            (aid) => dealersStore.getDealerBuyAid(aid)?.subjectId
                        )
                        break
                    case 'createdFrom':
                    case 'createdTo':
                    case 'installedFrom':
                    case 'installedTo':
                        param[key] = serviceFilterStore.data[key].format('YYYY-MM-DDTHH:mm:ssZ')
                        break
                    case 'source':
                        param['sourceId'] = serviceFilterStore.data[key]
                        break
                    case 'sender':
                        param['senderId'] = serviceFilterStore.data[key]
                        break
                    case 'id':
                        param['id'] = serviceFilterStore.data[key]
                        break
                    case 'phoneNumber':
                        param['phoneNumber'] = normalizePhoneNumber(serviceFilterStore.data[key])
                        break

                    case 'statuses':
                        serviceFilterStore.data[key].forEach((status) => {
                            if (status === 6.2 || status === 6.3 || status === 6.4 || status === 6.5) {
                                const number = status
                                const decimalPart = number.toString().split('.')[1]
                                const decimalNumber = parseInt(decimalPart)

                                param['ticketStatuses'] = param['ticketStatuses']
                                    ? [...param['ticketStatuses'], decimalNumber]
                                    : [decimalNumber]
                            } else {
                                param['statuses'] = param['statuses'] ? [...param['statuses'], status] : [status]
                            }
                        })
                        break
                    case 'ticketStatuses':
                        break
                    default:
                        param[key] = serviceFilterStore.data[key]
                        break
                }
            }
        }

        makeParamCopy(param)
        when(
            () => serviceStore.isLoaded,
            () => {
                setSelectedFilters()
                makeHighlightTrue()
                closeModalFn['progress-backdrop']()
            }
        )
        closeModalFn['common-service-drawer']()
        if (selectedTab === 3) {
            return await serviceStore.gerReworkPagedData({
                ...param,
                size: serviceStore.pageSize,
            })
        }
        return await serviceStore.reLoad({
            ...param,
            size: serviceStore.pageSize,
        })
    }

    const filtersData = [
        {
            type: 'select',
            propName: 'statuses',
            multiple: true,
            name: intl.formatMessage({ id: 'Статус' }),
            collection: statusStore.getServiceStatuses(),
            setMenuItem: (data) => (
                <MenuItem value={data.id === 0 ? '' : data.id} key={data.id}>
                    <Box className={'aln-center'}>
                        <img alt={''} src={data.imageSrc} />
                        <Box ml={1} sx={classes.selectTextContainer}>
                            <Typography variant={'subtitle2'} sx={classes.selectText}>
                                {data.name}
                            </Typography>
                        </Box>
                    </Box>
                </MenuItem>
            ),
        },
        {
            type: 'input',
            propName: 'id',
            name: intl.formatMessage({ id: 'Номер заявки' }),
        },

        {
            type: 'input',
            propName: 'phoneNumber',
            mask: '+7 (999)-999-99-99',
            name: intl.formatMessage({ id: 'Телефон' }),
        },

        {
            type: 'input',
            propName: 'fullName',
            name: intl.formatMessage({ id: 'ФИО' }),
            placeholder: intl.formatMessage({ id: 'Минимум 3 символа' }),
        },
        {
            type: 'input',
            propName: 'fullAddress',
            name: intl.formatMessage({ id: 'Регион, МО или ГО, Населенный пункт, Адрес' }),
            placeholder: intl.formatMessage({ id: 'Минимум 3 символа' }),
            mb: !isOperator() && 2,
        },
        [
            {
                type: 'date',
                propName: 'createdFrom',
                name: intl.formatMessage({ id: 'Дата создания заявки' }),
                minDate: moment(window.SAT.config.minDateOperationalSummary).utc().format('YYYY-MM-DD HH:mm'),
                maxDate: moment().utc(true).format('YYYY-MM-DD HH:mm'),
            },
            {
                type: 'date',
                propName: 'createdTo',
                name: intl.formatMessage({ id: 'Дата создания заявки' }),
                minDate: moment(window.SAT.config.minDateOperationalSummary).utc().format('YYYY-MM-DD HH:mm'),
                maxDate: moment().utc(true).format('YYYY-MM-DD HH:mm'),
            },
        ],

        {
            type: 'select',
            propName: 'authorId',
            name: intl.formatMessage({ id: 'Автор' }),
            collection: authorsStore.authors,
            setMenuItem: (data) => {
                return (
                    <MenuItem key={data.id} value={data.id}>
                        {data.name}
                    </MenuItem>
                )
            },
        },
        {
            type: 'select',
            propName: 'sender',
            name: intl.formatMessage({ id: 'Источник' }),
            collection: sendersStore.allSenders,
            setMenuItem: (data) => (
                <MenuItem key={data.id} value={data.id}>
                    {data.name}
                </MenuItem>
            ),
        },
        {
            type: 'select',
            multiple: true,
            propName: 'financialSourceIds',
            name: intl.formatMessage({ id: 'Тип дотации' }),
            placeholder: intl.formatMessage({ id: 'Тип дотации' }),
            collection: statusStore.financialSources,
            setMenuItem: (el) => {
                return (
                    <MenuItem key={el.id} value={el.id === 0 ? '' : el.id}>
                        <Box className={'aln-center'}>
                            <img alt={''} src={el.imageSrc} />
                            <Box ml={1}>
                                <Typography variant={'subtitle2'}>{el.name}</Typography>
                            </Box>
                        </Box>
                    </MenuItem>
                )
            },
        },

        {
            type: 'select',
            propName: 'dealerIds',
            name: intl.formatMessage({ id: 'Дилер' }),
            multiple: true,
            collection: dealersStore.collection,
            setMenuItem: (data) => (
                <MenuItem value={data.aid} key={data.aid} sx={{ padding: '20px 12px' }}>
                    <Box className={'aln-center'} height={20}>
                        <img alt={''} src={avatarIcon} height={20} width={20} />
                        <Box ml={1} sx={classes.selectTextContainer}>
                            <Typography
                                variant={'body2'}
                                sx={{ ...classes.selectTextContainer, ...classes.selectText }}
                            >
                                {data.displayName}
                            </Typography>
                        </Box>
                    </Box>
                </MenuItem>
            ),
        },
        {
            type: 'input',
            propName: 'equipmentId',
            name: intl.formatMessage({ id: 'Id оборудования' }),
        },
    ]
    const getFiltersData = useMemo(() => {
        let availableFilters = isAdministration()
            ? [
                  [
                      'statuses',
                      'id',
                      'phoneNumber',
                      'fullName',
                      'fullAddress',
                      // 'financialSourceIds',
                      // 'createdTo',
                  ],
                  ['createdFrom', 'equipmentId'],
              ]
            : [
                  [
                      'statuses',
                      'id',
                      'phoneNumber',
                      'fullName',
                      'fullAddress',
                      'createdFrom',
                      // 'createdTo',
                      'authorId',
                      'sender',
                  ],
                  ['dealerIds', 'equipmentId'],
              ]
        if (isDealer()) {
            availableFilters = availableFilters.map((arrItem) => {
                return arrItem.filter((el) => {
                    if (el !== 'dealerIds' && el !== 'authorId' && el !== 'sender') {
                        return el
                    }
                })
            })
        }

        if (isAdministration()) {
            availableFilters = availableFilters.map((arrItem) => {
                return arrItem.filter((el) => {
                    if (el !== 'dealerIds' && el !== 'authorId' && el !== 'sender') {
                        return el
                    }
                })
            })
        }

        if (isOperator()) {
            availableFilters = availableFilters.map((arrItem) => {
                return arrItem.filter((el) => {
                    if (
                        (selectedTab === 2 || selectedTab === 3) &&
                        el !== 'dealerIds' &&
                        el !== 'authorId' &&
                        el !== 'sender' &&
                        el !== 'statuses' &&
                        el !== 'createdTo' &&
                        el !== 'createdFrom'
                    ) {
                        return el
                    }

                    return null
                })
            })
        }

        const setFilterData = () =>
            availableFilters.reduce((acc, filtersArr, i) => {
                const newFilters = filtersArr.map((propName) => {
                    return filtersData.find((el) => {
                        if (Array.isArray(el)) {
                            return el[0].propName === propName
                        }
                        return el.propName === propName
                    })
                })
                acc.push(newFilters)
                return acc
            }, [])
        return setFilterData()
    }, [reasons, sources, selectedTab])

    const setChip = (params, withAction) => <ServiceFilterChip {...params} onClick={withAction && handleDeleteChip} />

    const handleDeleteChip = (propName, multiselectValue) => {
        serviceFilterStore.deleteFilter(propName, multiselectValue, true)
        clearSelectOrders()
        onSearch()
    }

    useEffect(() => {
        serviceFilterStore.clear()
    }, [selectedTab])

    const handleClearFilterAndTableCheckboxes = () => {
        serviceFilterStore.clear()
        clearSelectOrders()
        onSearch()
    }

    return (
        <Box mb={2}>
            <NewFilters
                title={intl.formatMessage(
                    {
                        id: 'Найдено заявок {reports}',
                        defaultMessage: 'Найдено заявок {reports}',
                    },
                    {
                        reports: serviceStore.total,
                    }
                )}
                setChip={setChip}
                totalCollectionLength={5}
                filtersStore={serviceFilterStore}
                filtersData={getFiltersData}
                onSearch={onSearch}
                filtersContainerHeight={'150px'}
                actionButtonProps={{
                    name: intl.formatMessage({ id: 'Заявка на обслуживание' }),
                    onClick: () => openModalFn['create-service-request'](),
                    icon: serviceIcon,
                }}
                onClear={handleClearFilterAndTableCheckboxes}
            />
        </Box>
    )
}

export default inject('serviceStore', 'serviceSelectOrdersStore')(observer(ServiceFilters))
