import { makeAutoObservable } from 'mobx'
import {
    getStatisticsDealerPerformanceHistory,
    getStatisticsDealerPerformanceHistoryToday,
} from '../../../services/ApiService'
import { closeModalFn, openModalFn } from '../../../utils/controllers/ModalController'
import moment from 'moment/moment'
import { getComp } from '../../../utils/utils/DI'

import * as XLSX from 'xlsx'

class SummaryStatisticsDealersStore {
    rows = []
    data = {}
    monthly = {}
    dealerSelect = []
    intervalType = ''

    weeks = []
    weeksVue = []
    currentWeek = {}
    dealers = []
    searchForFilter = false
    periodDate = ''

    dealerName = ''
    titleDate = ''
    


    ExpiredFrom = ''

    ExpiredTo = ''

    month = ''
    year = ''

    isLoading = true

    subpage = {
        today: 'today',
        period: 'period',
        total: 'total',
    }

    countWeek = 1

    activeToggleBlockItem = this.subpage.today
    toggleButton = false
    request = false
    
    constructor() {
        this.dealersStore = getComp('DealersStore')

        makeAutoObservable(this)
    }
    setCountWeek = (value) => {
        this.countWeek = value
    }

    setPeriodDate = (value) => {
        this.periodDate = value
    }

    addServiceProps = (rows) => {
        return rows.map((el) => {
            el.checked = false

            return el
        })
    }

    toggleChecked = (row) => {
        const { dealerId } = row
        this.rows.map((el) => {
            if (el.dealerId === dealerId) {
                el.checked = !el.checked
            }
            return el
        })
    }

    allCheckedCheckbox = () => {
        if (this.isAllChecked()) {
            this.rows.map((el) => {
                el.checked = false
                return el
            })
        } else {
            this.rows.map((el) => {
                el.checked = true
            })
        }
    }

    isIndeterminate = () => {
        return this.rows.some((item) => item.checked) && !this.isAllChecked()
    }

    isAllChecked = () => {
        return !this.rows.some((item) => !item.checked)
    }

    isSomeChecked = () => {
        return this.rows.some((item) => item.checked)
    }
    clearCheckbox = () => {
        this.rows.map((el) => {
            el.checked = false
            return el
        })
    }

    getRows = ({ param, vue }) => {
        if (this.activeToggleBlockItem === this.subpage.period) {
            const { AssignedFrom, AssignedTo } = param
            this.ExpiredFrom = AssignedFrom
            this.ExpiredTo = AssignedTo
        }
            this.currentWeek = vue

        openModalFn['progress-backdrop']()
        if (this.activeToggleBlockItem === this.subpage.today) {
            getStatisticsDealerPerformanceHistoryToday(param).then((res) => {
                this.data = res
                this.rows = this.addServiceProps(Object.values(res.dealers))
                this.isLoading = false
                closeModalFn['progress-backdrop']()
            })
        } else {
            getStatisticsDealerPerformanceHistory(param).then((res) => {
                this.data = res
                this.monthly = res.monthly
                this.rows = this.addServiceProps(Object.values(res.dealers))
                this.isLoading = false
                closeModalFn['progress-backdrop']()
            })
        }
    }

    getDealers = () => {
        const collection = this.dealersStore.collection
        this.dealers = [{ displayName: 'Все дилеры', subjectId: '', aid: '' }, ...collection]
    }

    setSearchForFilter = (value) => {
        this.searchForFilter = value
    }

    get getChips() {
        if (this.month && this.year) {
            return [...this.dealerSelect, this.month, this.year]
        } else {
            return [...this.dealerSelect]
        }
    }

    getWeeksOfMonth = (year, month) => {
        let startOfMonth = moment(`${year}-${month}-01`).startOf('day')
        let endOfMonth = startOfMonth.clone().endOf('month').add(1, 'days').startOf('day')

        let weeks = []

        while (startOfMonth.isBefore(endOfMonth)) {
            let endOfWeek = startOfMonth.clone().endOf('isoWeek').startOf('day').add(1, 'days')

            if (endOfWeek.isAfter(endOfMonth)) {
                endOfWeek = endOfMonth.clone().startOf('day')
            }

            weeks.push({
                start: startOfMonth.toISOString(),
                end: endOfWeek.toISOString(),
            })

            startOfMonth = endOfWeek.clone().startOf('day')
        }
        this.weeks = weeks

        return weeks
    }

    clear = () => {
        this.dealerSelect = []
        this.intervalType = ''
        const currentDate = new Date()
        this.month = [this.subpage.total, this.subpage.today].includes(this.activeToggleBlockItem)
            ? ''
            : currentDate.getMonth() + 1
        this.year = [this.subpage.total, this.subpage.today].includes(this.activeToggleBlockItem)
            ? ''
            : currentDate.getFullYear()
    }

    getWeeksOfMonthVue = (year, month) => {
        let startOfMonth = moment(`${year}-${month}-01`).startOf('day')
        let endOfMonth = startOfMonth.clone().endOf('month').startOf('day')

        let weeks = []

        while (startOfMonth.isBefore(endOfMonth) || startOfMonth.isSame(endOfMonth, 'day')) {
            let startOfWeek = startOfMonth.clone().startOf('isoWeek').startOf('day').add(3, 'hours')
            let endOfWeek = startOfMonth.clone().endOf('isoWeek').startOf('day').add(3, 'hours')

            if (endOfWeek.isAfter(endOfMonth)) {
                endOfWeek = endOfMonth.clone().startOf('day').add(3, 'hours')
            }

            if (startOfWeek.isBefore(startOfMonth)) {
                startOfWeek = startOfMonth.clone().startOf('day').add(3, 'hours')
            }

            weeks.push({
                start: startOfWeek.toISOString(),
                end: endOfWeek.toISOString(),
            })

            startOfMonth = endOfWeek.clone().add(1, 'days').startOf('day')
        }
        this.weeksVue = weeks

        return weeks
    }

    loadFile = () => {
        const {
            dealerId,
            assigns,
            expiredAssigns,
            installs,
            expiredInstalls,
            improvement,
            duplicates,
            impossible,
            averageProcessingTimeHours,
        } = this.data

        const selectedRowsData = this.rows
            .filter((el) => el.checked)
            .map((el) => [
                this.dealersStore.getDealerName(el.dealerId)?.toString(),
                el.assigns?.toString(),
                el.expiredAssigns?.toString(),
                el.installs?.toString(),
                el.expiredInstalls?.toString(),
                el.improvement?.toString(),
                el.duplicates?.toString(),
                el.impossible?.toString(),
            ])

        let finalArray = ['ИТОГО', 0, 0, 0, 0, 0, 0, 0]

        selectedRowsData.forEach((row) => {
            for (let i = 1; i < finalArray.length; i++) {
                finalArray[i] += Number(row[i]) || 0
            }
        })
        finalArray = finalArray.map((el) => el.toString())

        const getTypeIntervalAndDate = () => {
            const formatDate = (date) => {
                const daysOfWeek = ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб']
                const dayOfWeek = daysOfWeek[date.getDay()]
                const day = String(date.getDate()).padStart(2, '0')
                const month = String(date.getMonth() + 1).padStart(2, '0')
                const year = date.getFullYear()

                return `${dayOfWeek} ${day}.${month}.${year}`
            }

            const today = new Date()
            const formattedDate = formatDate(today)

            switch (this.activeToggleBlockItem) {
                case 'today':
                    return `Сегодня, ${formattedDate}`
                case 'period':
                    return `За отчётный период, ${this.periodDate}`
                case 'total':
                    return `Суммарно за все время, Пн 30.01.2022 - ${formattedDate}`
                default:
                    return ''
            }
        }

        const typeAndDate = getTypeIntervalAndDate()

        

        const fields = [
            [typeAndDate],
            [],
            ['Общее количество установок'],
            ['Назначены дилерам',  this.activeToggleBlockItem === this.subpage.period? this.monthly?.assigns?.toString() : assigns.toString()],
            ['Просроченные',  this.activeToggleBlockItem === this.subpage.period? this.monthly?.expiredAssigns?.toString() : expiredAssigns.toString()],
            ['Выполненные установки',  this.activeToggleBlockItem === this.subpage.period? this.monthly?.installs?.toString() : installs.toString()],
            ['Установки с просрочкой',  this.activeToggleBlockItem === this.subpage.period? this.monthly?.expiredInstalls?.toString() : expiredInstalls.toString()],
            ['На доработку',  this.activeToggleBlockItem === this.subpage.period? this.monthly?.improvement?.toString() : improvement.toString()],
            ['В Невозможно',  this.activeToggleBlockItem === this.subpage.period? this.monthly?.impossible?.toString() : impossible.toString()],
            ['В Дубликаты',  this.activeToggleBlockItem === this.subpage.period? this.monthly?.duplicates?.toString() : duplicates.toString()],
            [
                'Среднее время (часы)',
                (
                    Math.round((  this.activeToggleBlockItem === this.subpage.period? this.monthly?.averageProcessingTimeHours : averageProcessingTimeHours) * 10) / 10
                ).toFixed(1),
            ],
            [],
            ['По выбранным дилерам'],
            [
                'Исполнитель',
                'Назначено',
                'Просрочено',
                'Установлено',
                'Из них просрочено',
                'На доработку',
                'В Дубликат',
                'В Невозможно',
            ],
            ...selectedRowsData,
            finalArray,
        ]

        const wb = XLSX.utils.book_new()
        const ws = XLSX.utils.aoa_to_sheet(fields)

        const boldRows = [2, 12]
        boldRows.forEach((rowIndex) => {
            fields[rowIndex].forEach((_, colIndex) => {
                const cellRef = XLSX.utils.encode_cell({ r: rowIndex, c: colIndex })
                ws[cellRef] = ws[cellRef] || {}
                ws[cellRef].s = { font: { bold: true } }
            })
        })

        const colWidths = fields[0].map((_, colIndex) => {
            const maxLength = Math.max(...fields.map((row) => (row[colIndex] ? row[colIndex].toString().length : 0)))
            return { wch: maxLength + 5 }
        })

        ws['!cols'] = colWidths

        XLSX.utils.book_append_sheet(wb, ws, 'Отчет')
        XLSX.writeFile(wb, 'Отчет.xlsx')
    }

    setActiveToggleBlockItem = (value) => {
        
        // this.clear()
        this.activeToggleBlockItem = value
    }

    setDealerSelect = (value) => {
        if (value.includes('Все дилеры')) {
            this.dealerSelect = ['Все дилеры']
        } else {
            this.dealerSelect = value
        }
    }
    setIntervalType = (value) => {
        this.intervalType = value
    }
    setMonth = (value) => {
        this.month = value
    }
    setYear = (value) => {
        this.year = value
    }
    setToggleButton = (value) => {
        this.toggleButton = value
    }
    setDealerName = (value) => {
        this.dealerName = value
    }
    setTitleDate = (value) => {
        this.titleDate = value
    }
    startTime = () => {
        const currentDate = new Date()
        this.month = [this.subpage.total, this.subpage.today].includes(this.activeToggleBlockItem)
            ? ''
            : !this.month
            ? currentDate.getMonth() + 1
            : this.month
        this.year = [this.subpage.total, this.subpage.today].includes(this.activeToggleBlockItem)
            ? ''
            : !this.year
            ? currentDate.getFullYear()
            : this.year
    }

    

    openRequest = () => (this.request = true)

    closeRequest = () => (this.request = false)
}

const SummaryStatisticsDealersStoreInstance = new SummaryStatisticsDealersStore()

export default SummaryStatisticsDealersStoreInstance
