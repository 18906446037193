import React from 'react'
import CustomAccordion from '../../../../../components/customElements/RequestDataAccordions/utils/CustomAccordion'
import { Box } from '@mui/system'
import { useIntl } from 'react-intl'
import DataUnit from '../../../../../components/customElements/DataUnit/DataUnit'
import moment from 'moment'
import { getComp } from '../../../../../utils/utils/DI'
import { observer } from 'mobx-react-lite'
import { formatAddress } from '../../../../../helpers/ordersUtiles'

const ServiceInstallationData = ({
    defaultOpenState,
    serviceOrder,
    onEdit,
    setIsEdit,
    isEdit,
    serviceStore,
    withoutInstallationDate,
}) => {
    const intl = useIntl()
    const categoriesStore = getComp('CategoriesStore')
    const {
        equipmentId,
        created,
        territory,
        address,
        name,
        surname,
        patronymic,
        dealerName,
        additionalInfo,
        preferentialCategories,
        preferentialCategory,
        phoneNumber,
        installationReasonId,
        maintenanceReasonName,
        locality,
        district,
        house,
        building,
        apartment,
        addressType,
        status
    } = serviceOrder
    
    const { showAddServiceRequestFormFunc } = serviceStore
    const handleEdit = () => {
        showAddServiceRequestFormFunc(serviceOrder)
        setIsEdit(!isEdit)
    }

    const getNormalizedString = (obj) => {
        return formatAddress(obj)
    }
    function formatPhoneNumber(phoneNumber) {
        const cleaned = ('' + phoneNumber).replace(/\D/g, '');
        const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})$/);
    
        if (match) {
            return `+${match[1]} (${match[2]})-${match[3]}-${match[4]}-${match[5]}`;
        }
    
        return null; // Вернуть null, если входные данные некорректны
    }
    
    return (
        <CustomAccordion
            summary={
                isEdit
                    ? intl.formatMessage({ id: 'Редактирование заявки' })
                    : intl.formatMessage({ id: 'Данные абонента' })
            }
            defaultOpenState={defaultOpenState}
            onEdit={onEdit ? handleEdit : null}
        >
            <Box sx={{ padding: '10px 0 0 14px', display: 'flex', flexDirection: 'column', gap: '8px' }}>
                {/* <DataUnit name='Id оборудования' value={equipmentId} /> */}
                {!withoutInstallationDate && (
                    <DataUnit name='Дата установки' value={moment(created).format('DD.MM.YYYY')} />
                )}
                {/* <DataUnit name='Регион' value={territory} /> */}
                <DataUnit
                    name='Адрес'
                    value={getNormalizedString({
                        territory,
                        district,
                        locality,
                        address,
                        addressType,
                        house,
                        building,
                        apartment,
                    })}
                />
                <DataUnit name='Заказчик' value={`${surname || ''} ${name || ''} ${patronymic || ''}`} />
                <DataUnit name='Причина' value={maintenanceReasonName} />
                <DataUnit name='Телефон' value={formatPhoneNumber(phoneNumber)} />
                <DataUnit name='Дополнительная информация' value={additionalInfo} />
                {/* <DataUnit name='Льготная категория' value={preferentialCategory} /> */}
                {/* { !preferentialCategories  ? null : (<>
                    <DataUnit name='Льготные категории' value={categoriesStore.formatCategory(preferentialCategories)} />
                    <DataUnit name='Дополнительная информация' value={additionalInfo} />
                </>

                )} */}
            </Box>
        </CustomAccordion>
    )
}

export default observer(ServiceInstallationData)
