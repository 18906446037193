import { observer } from 'mobx-react'
import React, { useCallback, useState } from 'react'
import Box from '@mui/material/Box'
import { css } from '@emotion/css'
import Typography from '@mui/material/Typography'
import { FormattedMessage, useIntl } from 'react-intl'

import { getStyles } from '../../../../utils/utils/commonUtils'
import { getComp } from '../../../../utils/utils/DI'
import chipsStyles from '../../../../components/Chips/styles/chipsStyles'
import DatesStore from '../../../../stores/DatesStore'
import Chip from '../../../../components/Chips/Chip'
import moment from 'moment'

function ServiceFilterChip({ propName, value, onClick }) {
    const classes = getStyles(chipsStyles)
    const intl = useIntl()
    const { getReportStatusName, getFinancialSourceElement, getServiceOrderStatusImage, getServiceOrderStatusName } = getComp('StatusesStore')
    const [datesStore] = useState(() => new DatesStore())
    const { getDealerBuyAid } = getComp('DealersStore')
    const { getName } = getComp('AuthorsStore')
    const { getSourceName } = getComp('SourcesStore')
    const { getSenderName } = getComp('SendersStore')
    const setChipAction = useCallback(() => {
        return onClick ? () => onClick(propName, value) : null
    }, [value])

    const setValue = useCallback(() => {
        switch (propName) {
            case 'statuses':
            case 'status': {
                return (
                    <Box className={'aln-center'}>
                        <img alt={''} src={getServiceOrderStatusImage(value)} className={css(classes.statusIcon)} />
                        <Typography variant={'body1'} sx={classes.chipText}>
                            {getServiceOrderStatusName(value)}
                        </Typography>
                    </Box>
                )
            }
            case 'financialSourceIds': {
                
                const data = getFinancialSourceElement(value)
                 return(
                     <Box className={'aln-center'}>
                         <img alt={''} src={data.imageSrc} className={css(classes.statusIcon)} />
                         <Typography variant={'body1'} sx={classes.chipText}>
                             {data.name}
                         </Typography>
                     </Box>
                 )
             }
            case 'source': {
                return getSourceName(value)
            }
            case 'sender': {
                return getSenderName(value)
            }
            case 'createdFrom': {
                return intl.formatMessage({ id: 'Создана от' }) + ' ' + moment(value).format('DD-MM-YYYY')
            }
            case 'createdTo': {
                return intl.formatMessage({ id: 'Создана до' }) + ' ' + moment(value).format('DD-MM-YYYY')
            }
            case 'closeDateFrom': {
                return intl.formatMessage({ id: 'Закрыт' }) + ' ' + value
            }
            case 'dealerIds':
            case 'dealerId': {
                return getDealerBuyAid(value) ? getDealerBuyAid(value).displayName : ''
            }
            case 'authorId': {
                return getName(value).name
            }
            case 'month': {
                return datesStore.getMonthName(value, false, true)
            }
            case 'hasDuplicates': {
                return <FormattedMessage id={'Повторное обращение'} />
            }

            default: {
                return value
            }
        }
    }, [value])

    return <Chip action={setChipAction()} value={setValue()} />
}

export default observer(ServiceFilterChip)
