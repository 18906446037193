import { Checkbox, TableCell } from '@mui/material'
import React from 'react'
import store from './RequestsTableOptimizedStore'
import { observer } from 'mobx-react'

function HeadCheckbox() {
    const { allSelectedCheckbox, isIndeterminate, isAllSelected } = store
    return (
        <TableCell sx={{padding: '8px',}}>
            <Checkbox checked={isAllSelected()} indeterminate={isIndeterminate()} onClick={allSelectedCheckbox} />
        </TableCell>
    )
}

export default observer(HeadCheckbox)
