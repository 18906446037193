import { Button } from '@mui/material'
import React from 'react'
import { openModalFn } from '../../../../../../utils/controllers/ModalController'
import { observer } from 'mobx-react'

const DuplicateBtn = () => {
    const handleDuplicate = () => {
        openModalFn['intermediate-drawer']({ type: 'transfer-to-duplicate' })
    }

    return (
        <Button onClick={handleDuplicate} fullWidth variant='outlined'>
            Перевести в дубликат
        </Button>
    )
}

export default observer(DuplicateBtn)
