import React from "react"
import classNames from "classnames"
import {css} from "@emotion/css"
import Box from "@mui/material/Box"
import {observer} from "mobx-react"
import InputField from "../../../../../components/customElements/InputField/InputField"
import {useIntl} from "react-intl"
import CustomAccordion from "../../../../../components/customElements/RequestDataAccordions/utils/CustomAccordion"

const ChangeStatusView = ({comment, setComment}) => {
    const intl = useIntl()
    return (
        <Box name={"content"} className={classNames("fx fx-col", css({flex: "1 1 auto"}))}>
            <CustomAccordion
                summary={intl.formatMessage({id: "Причина перевода заявки в статус дубликат"})}
                defaultOpenState={true}
            >
                <InputField
                    multiline
                    autoFocus
                    value={comment}
                    onChange={setComment}
                    rows={4}
                    fullWidth
                    placeholder={intl.formatMessage({id: "Введите комментарий"})}
                />
            </CustomAccordion>
        </Box>
    )
}

export default observer(ChangeStatusView)
