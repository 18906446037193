import { makeObservable } from 'mobx'
import FormStore from '../../../utils/stores/FormStore'

const initState = {
    orderId: '',
    comment: '',
    files: null,
}

const validateData = {
    orderId: { required: true },
    comment: { required: true },
}

class IntermediateDrawerFormStore extends FormStore {
    constructor() {
        super({ initState, validateData })
        this.showErrors = true
        this.isValid = false
        makeObservable(this, {})
    }

    clear = () => {
        this.setData(initState)
        this.clearSelectedFilters()
    }
}

export default IntermediateDrawerFormStore
