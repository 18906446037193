import { closeModalFn, isModalOpen, modalData, openModalFn } from '../../../../utils/controllers/ModalController'
import RightDrawer from '../../../../components/customElements/RightDrawer/RightDrawer'
import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { css } from '@emotion/css'
import Box from '@mui/material/Box'
import { FormattedMessage, useIntl } from 'react-intl'
import classNames from 'classnames'
import OrderStatus from '../../../../components/customElements/RequestDataAccordions/OrderStatus'
import InstallationData from '../../../../components/customElements/RequestDataAccordions/InstallationData'
import AssignToDealerView from '../RequestDetails/AssignToDealerView/AssignToDealerView'
import ChangeStatusView from '../RequestDetails/ChangeStatusView/ChangeStatusView'
import { getStyles } from '../../../../utils/utils/commonUtils'
import commonStyles from '../../../../styles/commonStyles'
import { getComp } from '../../../../utils/utils/DI'
import RequestManagementStore from '../../stores/RequestManagementStore'
import { isAdmin, isAdministration, isDealer, isLooker, isOperator } from '../../../../services/AccountService'
import DefaultContent from './components/DefaultContent'
import DataUnit from '../../../../components/customElements/DataUnit/DataUnit'
import OrderHistory from '../../../../components/customElements/RequestDataAccordions/OrderHistory/OrderHistory'
import { Typography } from '@mui/material'
import ReportIconComponent from '../../../ReportsSummery/components/ReportIconComponent'
import OrderInfoChips from '../../../../components/OrderInfoChips/OrderInfoChips'
import RequestsTableStore from '../RequestsTableOptimized/RequestsTableOptimizedStore'

function RequestDetailsDrawer({
    filterStore,
    operatorStore,
    ordersStore,
    openReportDetails,
    isReportDetailsOpen,
    serviceStore,
    selectReport,
}) {
    const intl = useIntl()
    const classes = getStyles(commonStyles)
    const [store] = useState(() => new RequestManagementStore({ operatorStore, ordersStore }))
    
    const { data, collection, toDefaultView, contentState, toChangeStatusView, setNewEquipment } = store
    const {
        id,
        status,
        isTakenByMe,
        hasDuplicates,
        reportId,
        reportStatus,
        financialSourceId,
        dealer,
        dataCheckingStatus,
        additionalInfo,
        address,
        addressType,
        apartment,
        author,
        authorId,
        authorName,
        building,
        categories,
        created,
        district,
        equipmentId,
        house,
        images,
        installationDate,
        isTaken,
        locality,
        name,
        patronymic,
        phone,
        preferentialCategory,
        priority,
        reason,
        reasonId,
        receiverId,
        sender,
        source,
        surname,
        territory,
    } = data

    const isOrderAvailable = [1, 2, 4, 5, 6, 7, 9, 11].includes(status)
    const [updateHistory, setUpdateHistory] = useState(false)
    const { updateOrders, setCurrentClickRow } = RequestsTableStore

    useEffect(() => {
        if (data) {
            updateOrders([data])
        }
    }, [
        id,
        status,
        isTakenByMe,
        hasDuplicates,
        reportId,
        reportStatus,
        financialSourceId,
        dealer,
        dataCheckingStatus,
        additionalInfo,
        address,
        addressType,
        apartment,
        author,
        authorId,
        authorName,
        building,
        categories,
        created,
        district,
        equipmentId,
        house,
        images,
        installationDate,
        isTaken,
        locality,
        name,
        patronymic,
        phone,
        preferentialCategory,
        priority,
        reason,
        reasonId,
        receiverId,
        sender,
        source,
        surname,
        territory,
    ])
    // при закрытии окна отчетов обновляем данные просмотренного отчета в таблице и в дровере
    useEffect(() => {
        return () => {
            if (isReportDetailsOpen) {
                store.reloadCallback()
            }
        }
    }, [isReportDetailsOpen])

    // при закрытии дровера очищаю текущие данные стора
    // иначе при следующем открытии дровера будет лишний запрос со старым id
    useEffect(() => {
        if (!isModalOpen['request-details']) {
            store.clear()
        }
    }, [isModalOpen['request-details']])

    useEffect(() => {
        if (isAdmin() || isAdministration() || (isOperator() && operatorStore.selectedTab === 1)) {
            if (ordersStore.isLoaded && modalData['request-details'].id) {
                const { id } = modalData['request-details']
                const order = ordersStore.collection.find((el) => el.id === id)
                if (order) {
                    if (order.equipmentId) {
                        setNewEquipment(order.equipmentId)
                    }
                    store.setData(order)
                    store.getImages()
                } else {
                    // closeModalFn['request-details']()
                }
            }
        }
    }, [modalData['request-details'].id])

    useEffect(() => {
        if (operatorStore) {
            if (isOperator()) {
                if (operatorStore && operatorStore.selectedTab === 0) {
                    const { id } = modalData['request-details']
                    const order = operatorStore.collection.find((el) => el.id === id)
                    if (order) {
                        if (order.equipmentId) {
                            setNewEquipment(order.equipmentId)
                        }
                        store.setData(order)
                        store.getImages()
                    } else {
                        closeModalFn['request-details']()
                    }
                }
            }
        }
    }, [operatorStore?.collection, modalData['request-details'].id])

    const closeModals = () => {
        // setCurrentClickRow(null)
        closeModalFn['photo-gallery']()
        closeModalFn['request-details']()
    }

    const statusStore = getComp('StatusesStore')
    const { getStatusName } = statusStore

    const editStatusAdministration = () => {
        return isAdministration() && (status === 1 || status === 2 || status === 3 || status === 4 || status === 5)
    }
    return (
        <RightDrawer
            open={isModalOpen['request-details']}
            onClose={closeModals}
            title={
                <>
                    <FormattedMessage id={'Заявка'} /> №{String(id).padStart(6, '0')}
                </>
            }
            backButtonClick={contentState === 'default' ? closeModals : toDefaultView}
            isReportDetailsOpen={isReportDetailsOpen}
        >
            <Box className={classNames('fx fx-col')}>
                {contentState === 'default' || contentState === 'changeStatus' ? (
                    <Box sx={classes.drawerStatusesInfo}>
                        <OrderStatus
                            status={status}
                            onEdit={
                                (isAdmin() && isOrderAvailable) ||
                                (isOperator() && isTakenByMe) ||
                                editStatusAdministration()
                                    ? isLooker()
                                        ? null
                                        : toChangeStatusView
                                    : null
                            }
                        />
                        {status >= 12 && (isAdmin() || isDealer()) && !isLooker() && !serviceStore ? (
                            <OrderInfoChips
                                tooltip={getStatusName(reportStatus, true)}
                                openReportDetails={openReportDetails} //функция при клике открывает окно отчета
                                id={reportId} // id отчета
                                reportStatus={reportStatus} // статус отчета
                                haveChips={true} // регулирует наличие фона по типу чипса
                                showOrderStatusIcon={true} // регулирует отображение иконки статуса
                            />
                        ) : null}
                    </Box>
                ) : null}
                {hasDuplicates && (
                    <Box
                        className={'fx-center'}
                        sx={{
                            padding: '8px 16px 16px',
                            width: '100%',
                        }}
                    >
                        <DataUnit value={intl.formatMessage({ id: 'Отмечена как повторная заявка' })} />
                    </Box>
                )}
                <Box name={'content'} className={classNames('fx-col jst-sb', css(classes.drawerContent))}>
                    {contentState === 'default' ? (
                        <DefaultContent
                            store={store}
                            ordersStore={ordersStore}
                            operatorStore={operatorStore}
                            filterStore={filterStore}
                            setUpdateHistory={setUpdateHistory}
                            updateHistory={updateHistory}
                            openReportDetails={openReportDetails}
                        />
                    ) : null}
                    {contentState === 'assignDealer' ? <AssignToDealerView store={store} /> : null}
                    {contentState === 'changeStatus' ? (
                        <ChangeStatusView store={store} operatorStore={operatorStore} />
                    ) : null}
                    {contentState === 'editData' ? (
                        <InstallationData
                            requestStore={store}
                            isEdit={true}
                            onEdit={toDefaultView}
                            order={data}
                            defaultOpenState
                            drawerOpen={isModalOpen['request-details']}
                            filterStore={filterStore}
                            ordersStore={ordersStore}
                            operatorStore={operatorStore}
                        />
                    ) : null}
                    {contentState === 'default' && (
                        <OrderHistory
                            id={id}
                            financialSourceId={financialSourceId}
                            contentState={contentState}
                            updateCondition={updateHistory}
                            openReportDetails={openReportDetails}
                            isReportDetailsOpen={isReportDetailsOpen}
                        />
                    )}
                </Box>
            </Box>
        </RightDrawer>
    )
}

export default observer(RequestDetailsDrawer)
