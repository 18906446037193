import React from 'react'
import { observer } from 'mobx-react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { FormattedMessage, useIntl } from 'react-intl'
import ChangePriority from '../../../../components/customElements/RequestDataAccordions/ChangePriority'
import RightDrawer from '../../../../components/customElements/RightDrawer/RightDrawer'
import classNames from 'classnames'
import { Button, css, FormControl, IconButton, InputLabel, MenuItem, Select } from '@mui/material'
import { getComp } from '../../../../utils/utils/DI'
import checkIcon from '../../../../assets/images/common/check.svg'
import cross from '../../../../assets/images/common/cross_dark.svg'

const MassChangeStatusDrawer = ({ store, operatorStore }) => {
    const { isMassChangeStatusDrawerOpen, toggleMassChangeStatusDrawer, selectedOrders, massChangeStatus } = store
    const statusesStore = getComp('StatusesStore')
    const intl = useIntl()
    const [value, setValue] = React.useState([])
    const statuses = statusesStore.orderStatuses.filter((el) => el.id === 1)

    const handleClick = () => {
        setValue([])
        massChangeStatus([...selectedOrders])
    }

    return (
        <RightDrawer
            open={isMassChangeStatusDrawerOpen}
            onClose={toggleMassChangeStatusDrawer}
            titleComp={
                <Box className={'fx-col'}>
                    <Typography variant={'subtitle1'} color={'common.white'}>
                        <FormattedMessage id={'Заявок выбрано'} />: {selectedOrders.size}
                    </Typography>
                    <Typography variant={'body1'} color={'#8FBFF6'}>
                        <FormattedMessage id={'Перевод заявок в статус "Создана"'} />
                    </Typography>
                </Box>
            }
        >
            <React.Fragment>
                <Box mb={3} mt={2} mx={2}>
                    <Typography variant={'h6'} sx={{ fontSize: '14px' }}>
                        <FormattedMessage id={'Изменить статус'} />
                    </Typography>
                </Box>
                <Box mt={2} mx={2} sx={{ display: 'flex' }}>
                    <FormControl fullWidth>
                        <InputLabel id='demo-simple-select-label'>
                            <FormattedMessage id={'Статус'} />
                        </InputLabel>
                        <Select
                            value={value}
                            label={intl.formatMessage({ id: 'Статус' })}
                            onChange={(event) => setValue(event.target.value)}
                        >
                            {statuses.map((el) => (
                                <MenuItem value={el.id} key={el.id}>
                                    <Box className={'aln-center'}>
                                        <img alt={''} src={el.imageSrc} />
                                        <Box ml={1}>
                                            <Typography variant={'subtitle2'}>{el.name}</Typography>
                                        </Box>
                                    </Box>
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    {value.length !== 0 && (
                        <Box className={'aln-center'} ml={1.5}>
                            <IconButton onClick={handleClick}>
                                <img alt={'Подтвердить'} src={checkIcon} />
                            </IconButton>
                            <IconButton
                                onClick={() => {
                                    setValue([])
                                }}
                            >
                                <img alt={'Отменить'} src={cross} width={20} height={20} />
                            </IconButton>
                        </Box>
                    )}
                </Box>
            </React.Fragment>
        </RightDrawer>
    )
}

export default observer(MassChangeStatusDrawer)
