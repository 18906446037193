import React from "react"
import {observer} from "mobx-react"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import {FormattedMessage} from "react-intl"
import ChangePriority from "../../../../components/customElements/RequestDataAccordions/ChangePriority"
import RightDrawer from "../../../../components/customElements/RightDrawer/RightDrawer"

const MassChangePriorityDrawer = ({store}) => {
    const {isMassChangePriorityDrawerOpen, toggleMassChangePriorityDrawer, selectedOrders, massChangePriority} = store
    
    return (
        <RightDrawer
            open={isMassChangePriorityDrawerOpen}
            onClose={toggleMassChangePriorityDrawer}
            titleComp={<Box className={"fx-col"}>
                <Typography variant={"subtitle1"} color={"common.white"}>
                    <FormattedMessage id={"Заявок выбрано"}/>: {selectedOrders.size}
                </Typography>
                <Typography variant={"body1"} color={"#8FBFF6"}>
                    <FormattedMessage id={"Изменение приоритета"}/>
                </Typography>
            </Box>
            }
        >
            <ChangePriority
                id={[...selectedOrders]}
                changePriority={massChangePriority}
                firstItem={store?.firstItem}
                isDefaultOpen={true}
                priority={null}
                massChange
                status = {2}
            />
        </RightDrawer>
    )
}

export default observer(MassChangePriorityDrawer)