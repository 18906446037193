import { makeAutoObservable } from "mobx"

class MassOperationDrawerStore {
    nameOperation = ''
    constructor() {
        makeAutoObservable(this)
    }
}

const store = new MassOperationDrawerStore()

export default store