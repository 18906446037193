import {makeAutoObservable} from "mobx"
import RequestsFilterStore from "../views/RequestsManagement/stores/RequestsFilterStore"
import SelectOrdersStore from "./SelectOrdersStore"
import {closeModalFn, openModalFn} from "../utils/controllers/ModalController"
import {isAdmin, isAdministration, isDealer, isDealerOperator, isOperator} from "../services/AccountService"
import OrdersStore from "src/stores/OrdersStore"
import ReportOrderDetailsStore from "../views/Reports/stores/ReportOrderDetailsStore"
import OperatorRequestStore from "src/stores/OperatorRequestStore"

class RequestsManagementStore {
    constructor() {
        this.filterStore = new RequestsFilterStore()
        this.ordersStore = new OrdersStore()
        this.operatorStore = new OperatorRequestStore()
        this.selectOrdersStore = new SelectOrdersStore({ordersStore: this.ordersStore})
        this.reportOrderDetailsStore = new ReportOrderDetailsStore({reportId: null, ordersStore: this.ordersStore})
        makeAutoObservable(this)
    }

    openAddRequestDrawer = () => {
        ;(isAdmin() || isOperator() || isDealerOperator() || isAdministration()) &&
            openModalFn["add-request"]({filterStore: this.filterStore, ordersStore: this.ordersStore})
    }

    openRequestDetails = (order) => {
        if ((isAdmin() || isDealer()) && order.data.dataCheckingStatus === 2) {
            this.reportOrderDetailsStore.openOrderDetails(order)
            return
        }
        if (isDealer()) {
            openModalFn["installation-request"]({
                id: order.data.id,
                filterStore: this.filterStore,
                ordersStore: this.ordersStore
            })
            return
        }
        if (isOperator() && this.operatorStore.selectedTab === 0) {
            openModalFn["request-details"]({
                id: order.data.id,
                filterStore: this.filterStore,
                ordersStore: this.ordersStore,
                operatorStore: this.operatorStore
            })
            return
        }
        if (isAdmin() || isOperator() || isAdministration()) {
            openModalFn["request-details"]({id: order.data.id})
        }
    }

    reLoadCallback = () => {
        this.ordersStore
            .updateOrder(this.reportOrderDetailsStore.data.id)
            .then((order) => {
                if (!order) {
                    this.reportOrderDetailsStore.isOrderEdit = false
                    this.reportOrderDetailsStore.closeOrderDetails()
                    return
                }
                this.reportOrderDetailsStore.setData(order)
                this.reportOrderDetailsStore.isOrderEdit = false
                if (order.dataCheckingStatus !== 2) {
                    openModalFn["installation-request"]({
                        id: this.reportOrderDetailsStore.data.id,
                        ordersStore: this.ordersStore
                    })
                    this.reportOrderDetailsStore.closeOrderDetails()
                }
                closeModalFn["progress-backdrop"]()
            })
            .finally(() => {
                closeModalFn["progress-backdrop"]()
            })
    }

    get totalCollectionLength() {
        return isOperator() && this.operatorStore.selectedTab === 0 ? this.operatorStore.total : this.ordersStore.total
    }
}

export default RequestsManagementStore
