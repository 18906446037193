import React, { useEffect } from 'react'
import CustomAccordion from '../../../../../components/customElements/RequestDataAccordions/utils/CustomAccordion'
import { Box } from '@mui/system'
import { observer } from 'mobx-react-lite'
import { Typography } from '@mui/material'
import { openModalFn } from '../../../../../utils/controllers/ModalController'

const ApplicationServicingAccordion = ({ serviceStore }) => {
    const { currentServiceData, getServicePhotosFunc, serviceOrderPhotosUrls, photosInfoList } = serviceStore

    useEffect(() => {
        const field = currentServiceData.status === 7 ? 'MaintenanceImpossible' : 'ImprovementRequired'

        getServicePhotosFunc(currentServiceData.id, field)
    }, [])
    const collection = photosInfoList
    const name = 'Фото'
    if (serviceOrderPhotosUrls.length) {
        return (
            <CustomAccordion summary={'Информация об обслуживании'} defaultOpenState={true} preventHandleOpen={false}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '12px',
                        marginTop: '12px',
                        paddingLeft: '12px',
                    }}
                >
                    <Box>
                        <Typography
                            variant={'body2'}
                            color={'grey.500'}
                            sx={{ fontSize: '15px', marginY: '4px', lineHeight: 1 }}
                        >
                            Фото
                        </Typography>
                        <Box sx={{ display: 'flex', gap: '12px', flexWrap: 'wrap' }}>
                            {serviceOrderPhotosUrls.map((url, i) => {
                                return (
                                    <img
                                        style={{
                                            width: '64px',
                                            height: '64px',
                                            borderRadius: '8px',
                                            border: '1px solid gray',
                                            cursor: 'pointer',
                                        }}
                                        src={url}
                                        onClick={() => {
                                            openModalFn['photo-gallery']({ collection, initialIndex: i, name })
                                        }}
                                    />
                                )
                            })}
                        </Box>
                    </Box>
                </Box>
            </CustomAccordion>
        )
    }
}

export default observer(ApplicationServicingAccordion)
