const settingsDialogStyles = (theme) => ({
    customDialog: {
        width: "650px",
        padding: "24px 55px"
    },

    contentContainer: {
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        width: "100%",
        margin: "24px 0 38px"
    },
    buttonsContainer: {
        gap: "16px",
        width: "100%"
    },
    contentItem: {
        display: "flex",
        alignItems: "center"
    }
})

export default settingsDialogStyles
