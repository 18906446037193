import PagedCollectionStore from '../utils/stores/PagedCollectionStore'
import { getAgreementOrders, getOperatorRequests, getOrders, releaseOrder, takeOrder } from '../services/ApiService'
import { computed, makeObservable, observable } from 'mobx'
import { modalData } from '../utils/controllers/ModalController'
import { notifySuccess } from '../services/ToastifyService/ToastifyService'
import { gState } from '../utils/controllers/GlobalStateController'
import WebSocketService from '../services/WebSocketService'

class OperatorRequestStore extends PagedCollectionStore {
    selectedTab = 0
    constructor() {
        super({ action: getOperatorRequests })
        this.wsConnection = new WebSocketService()
        this.pageSize = 100
        this.refreshedOrder = null
        this.refreshOrderId = 0
        this.intl = gState['intl']
        makeObservable(this, {
            wsConnection: observable,
            refreshedOrder: observable,
            refreshOrderId: observable,
            selectedTab: observable,
            isRefreshOrderOpened: computed,
            disabledBtnSubmitRequest: observable,
            // toggleDisabledBtnSubmitRequest: action,
        })
    }

    disabledBtnSubmitRequest = false

    setSelectedTab = (value) => {
        this.selectedTab = value
    }

    toggleDisabledBtnSubmitRequest = (value) => {
        // не дает вернуть заявку во время редактирования
        this.disabledBtnSubmitRequest = value
    }

    setIsTaken = ({ isTakenByMe, id }) => {
        let promise
        if (isTakenByMe) {
            promise = releaseOrder({ orderId: id })
        } else {
            promise = takeOrder({ orderId: id })
        }
        promise.then(() => this.reLoad())
    }

    get isRefreshOrderOpened() {
        const modalRequestData = modalData['request-details']
        if (!modalRequestData?.id) {
            return false
        }
        return modalRequestData.id === this.refreshOrderId
    }

    findOrder = (id) => {
        return this.collection.findIndex((el) => el.id === id)
    }

    setWsConnection = () => {
        this.wsConnection.setConnection('Notification', (data) => {
            const { type, orderId, maintenanceOrderId } = data

            switch (type) {
                case 4:
                    
                    this.changeCollectionWsAction(orderId, type)
                    break
                case 5:
                   
                    this.changeCollectionWsAction(orderId, type)
                    break
                
                default:
                    break
            }
        })
    }

    changeCollectionWsAction = (id, type) => {
        this.refreshOrderId = id
        const order = this.collection.find((order) => order.id === id)
        if (order) {
            this.collection = this.collection.map((order) => {
                if (order.id === id) {
                    if (type === 5) {
                        order.isTaken = false
                        order.isTakenByMe = false
                        notifySuccess(`Заявка ${id} была возвращена оператором в общий список`)
                    } else if (type === 4) {
                        order.isTaken = true
                        order.isTakenByMe = false
                        notifySuccess(`Заявка №${id} была взята оператором в обработку`)
                    }
                    this.refreshedOrder = order
                }
                return order
            })
        }
    }

    updateOrder = (orderId) => {
        // в параметрах запроса убрал ...loadParams чтобы запрос к заявке отправлялся без фильтров и заявка не исчезала из таблицы
        return getOperatorRequests({ orderId }).then((res) => {
            if (res.content.length) {
                const newOrder = observable(res.content[0])
                let orderIndex = null
                this.collection.forEach((order, index) => {
                    if (order.id === orderId) {
                        orderIndex = index
                    }
                })
                if (typeof orderIndex === 'number') {
                    this.collection.splice(orderIndex, 1, newOrder)
                    return newOrder
                }
            }
        })
    }
}

export default OperatorRequestStore
