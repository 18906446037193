import React, { useEffect, useState } from 'react'
import { closeModalFn, isModalOpen, openModalFn } from '../../../../utils/controllers/ModalController'
import RightDrawer from '../../../../components/customElements/RightDrawer/RightDrawer'
import { Box, Typography } from '@mui/material'
import Button from '@mui/material/Button'
import { FormattedMessage } from 'react-intl'
import StatusInfoAndEdit from './DrawersCommonComponents/StatusInfoAndEdit'
import AssignedDealer from '../../../../components/customElements/RequestDataAccordions/AssignedDealer'
import { isAdmin, isAdministration, isDealer, isOperator } from '../../../../services/AccountService'
import EquipmentDataAccordion from './DrawersCommonComponents/EquipmentDataAccordion'
import InformationAccordion from './DrawersCommonComponents/InformationAccordion'
import SubscriberData from './DrawersCommonComponents/SubscriberData'
import AcceptBtn from './DrawersCommonComponents/Buttons/AcceptBtn'
import DuplicateBtn from './DrawersCommonComponents/Buttons/DuplicateBtn'
import { inject, observer } from 'mobx-react'
import StartServiceBtn from './DrawersCommonComponents/Buttons/StartServiceBtn'
import ProblemSolvedRemotely from './DrawersCommonComponents/Buttons/ProblemSolvedRemotely'
import ReturnForRevisionBtn from './DrawersCommonComponents/Buttons/ReturnForRevisionBtn'
import ServiceIsNotPossible from './DrawersCommonComponents/Buttons/ServiceIsNotPossible'
import OnSiteRepairBtn from './DrawersCommonComponents/Buttons/OnSiteRepairBtn'
import TransferToServiceCenterBtn from './DrawersCommonComponents/Buttons/TransferToServiceCenterBtn'
import CommentsAccordion from './DrawersCommonComponents/CommentsAccordion'
import ServiceInformationAccordion from './DrawersCommonComponents/ServiceInformationAccordion'
import FullEquipmentDataAccordion from './DrawersCommonComponents/FullEquipmentDataAccordion'
import ApplicationServicingAccordion from './DrawersCommonComponents/ApplicationServicingAccordion'
import GuaranteeRepairBtn from './DrawersCommonComponents/Buttons/GuaranteeRepairBtn'
import TakeReleaseBtn from './DrawersCommonComponents/Buttons/TakeReleaseBtn'
import RequestManagementStore from '../../../RequestsManagement/stores/RequestManagementStore'
import ReportOrderDetailsStore from '../../../Reports/stores/ReportOrderDetailsStore'

const CommonServiceDrawer = ({ operatorStore, serviceStore, serviceSelectOrdersStore, ordersStore  }) => {
    const { toggleAssignDealerDrawer, clearSelectOrder } = serviceSelectOrdersStore
    const {
        setShowInfoDrawer,
        clearCheckedSeviceOrder,
        onCloseDrawer,
        currentServiceData,
        acceptServiceOrderFunc, // принять заявку на обслуживание
        beginWorkWithOrderFunc, // начать работу с заявкой
        setFromDrawer,
        toggleStatusOnly,
        setToggleStatusOnly
    } = serviceStore
    

    const handleCloseInfoDrawer = () => {
        clearCheckedSeviceOrder()
        onCloseDrawer()
        setShowInfoDrawer(null)
        clearSelectOrder()
        setToggleStatusOnly(true)
        // setFromDrawer(false)
        closeModalFn['common-service-drawer']()
        // closeModalFn['current-service-data']()
    }

    const showCommentsBlock = () => {
        if (isAdministration() || isOperator()) {
            return currentServiceData?.status === 1 ? true : false
        }
        return true
    }

    const guaranteeRepairStatus = () => {
        return currentServiceData?.status === 6.4 || currentServiceData?.status === 6.5
    }

    if (currentServiceData) {
        return (
            <RightDrawer
                open={isModalOpen['common-service-drawer']}
                onClose={handleCloseInfoDrawer}
                titleComp={
                    <Box className={'fx-col'}>
                        <Typography variant={'subtitle1'} color={'common.white'}>
                            <FormattedMessage
                                id={`Заявка на обслуживание №${String(currentServiceData.id).padStart(6, '0')}`}
                            />
                        </Typography>
                    </Box>
                }
            >
                <StatusInfoAndEdit ordersStore={ordersStore} currentServiceData={currentServiceData} />
                <Box sx={toggleStatusOnly? {display: 'none'}: null}>
                    
                
                {isAdmin() && !guaranteeRepairStatus() && !(currentServiceData?.status === 10) && (
                    <AssignedDealer
                        request={currentServiceData}
                        defaultOpenState={true}
                        onEdit={
                            [1, 2].includes(currentServiceData.status) ? () => toggleAssignDealerDrawer(true) : null
                        }
                    />
                )}
                {currentServiceData.ticketStatus === 2 ? (
                    <FullEquipmentDataAccordion isEditable={currentServiceData.isEquipmentIdEditable} />
                ) : (
                    <EquipmentDataAccordion
                        equipmentId={currentServiceData.equipmentId}
                        serviceOrderId={currentServiceData.id}
                        preventEdit={
                            isOperator() && operatorStore.selectedTab === 3
                                ? !currentServiceData.isTakenByMe
                                : !currentServiceData.isEquipmentIdEditable
                        }
                    />
                )}
                {!isDealer() && !isOperator() && !guaranteeRepairStatus() && (
                    <InformationAccordion currentServiceData={currentServiceData} />
                )}
                <SubscriberData operatorStore={operatorStore} />
                {(currentServiceData.ticketStatus === 2 ||
                    currentServiceData.ticketStatus === 3 ||
                    guaranteeRepairStatus()) && (
                    <ServiceInformationAccordion
                        serviceStore={serviceStore}
                        type={currentServiceData.ticketStatus === 3 || guaranteeRepairStatus() ? 2 : 1}
                    />
                )}
                {(currentServiceData.status === 11 || currentServiceData.status === 7) && (
                    <ApplicationServicingAccordion serviceStore={serviceStore} />
                )}
                {showCommentsBlock() && <CommentsAccordion />}

                {(isAdmin() && currentServiceData.status === 6.3) ||
                (isDealer() && currentServiceData.status === 6.3) ? (
                    <GuaranteeRepairBtn />
                ) : null}

                {isOperator() && currentServiceData?.status === 11 && (
                    <TakeReleaseBtn data={currentServiceData} store={serviceStore} />
                )}

                {!isAdministration() && !isOperator() && (
                    <Box
                        sx={{
                            margin: '16px',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '8px',
                            paddingBottom: '32px',
                        }}
                    >
                        {currentServiceData.status === 2 ? (
                            <AcceptBtn currentServiceData={currentServiceData} action={acceptServiceOrderFunc} />
                        ) : null}
                        {currentServiceData.status === 4 ? (
                            <>
                                <StartServiceBtn
                                    currentServiceData={currentServiceData}
                                    action={beginWorkWithOrderFunc}
                                />
                                <ProblemSolvedRemotely />
                                <ReturnForRevisionBtn />
                                <ServiceIsNotPossible />
                            </>
                        ) : null}
                        {currentServiceData.status === 5 ? (
                            <>
                                <OnSiteRepairBtn currentServiceData={currentServiceData} />
                                <TransferToServiceCenterBtn currentServiceData={currentServiceData} />

                                <ReturnForRevisionBtn />
                                <ServiceIsNotPossible />
                            </>
                        ) : null}
                        {!currentServiceData.ticketStatus && ![3, 7, 11, 10].includes(currentServiceData.status) && (
                            <DuplicateBtn />
                        )}
                    </Box>
                )}
                </Box>
            </RightDrawer>
        )
    }
}

export default inject('serviceStore')(observer(CommonServiceDrawer))
