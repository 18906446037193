import React, { useEffect, useMemo, useState } from 'react'
import { gState } from '../../../utils/controllers/GlobalStateController'
import CustomAccordion from './utils/CustomAccordion'
import Box from '@mui/material/Box'
import { isAdmin, isAdministration, isDealer, isOperator } from '../../../services/AccountService'
import InputField from '../InputField/InputField'
import IconButton from '@mui/material/IconButton'
import checkIcon from '../../../assets/images/common/check.svg'
import cross from '../../../assets/images/common/cross_dark.svg'
import DataUnit from '../DataUnit/DataUnit'
import { Typography } from '@mui/material'
import { useRequestManagementContext } from '../../../views/RequestsManagement/context/withRequestManagmentContext'

const ChangePriority = ({ id, priority, changePriority, isDefaultOpen, firstItem, status, massChange }) => {
    const intl = gState['intl']
    const priorityList = useMemo(() => {
        return isAdmin() || isAdministration() || isOperator()
            ? [
                  { value: undefined, key: 'Без приоритета' },
                  { value: '0', key: '0' },
                  { value: '1', key: '1' },
                  { value: '2', key: '2' },
                  { value: '3', key: '3' },
                  { value: '4', key: '4' },
                  { value: '5', key: '5' },
              ]
            : []
    }, [isAdmin, isAdministration, isOperator])
    const [value, setValue] = useState(priority)
    const [visible, setIsVisible] = useState(false)
    const { operatorStore } = useRequestManagementContext()

    const getValue = useMemo(() => {
        const withNullPriority = priority === null || priority === undefined ? 'Без приоритета' : priority
        return isAdmin() || isAdministration() || isOperator() ? value : withNullPriority
    }, [value, priority])

    const onChange = (event) => {
        if (isAdmin() || isAdministration() || isOperator()) {
            setValue(event.target.value)
            setIsVisible(true)
            if (firstItem && event.target.value === firstItem.priority && id?.length <= 1) {
                setIsVisible(false)
            }
        }
    }
    
    return (
        <CustomAccordion summary={intl.formatMessage({ id: 'Приоритет заявки' })} defaultOpenState={isDefaultOpen}>
            <Box className={'aln-center jst-sb'} mt={1.5}>
                {(isAdmin() || isOperator() || (isAdministration() && status === 1) || (isAdministration() && massChange)) && (
                    <InputField
                        value={getValue}
                        onChange={onChange}
                        label={intl.formatMessage({ id: 'Приоритет заявки' })}
                        readOnly={!isAdmin() && !isAdministration() && !isOperator()}
                        type={isAdmin() || (isAdministration() && status === 1) || (isAdministration() && massChange) || isOperator() ? 'select' : 'text'}
                        items={priorityList}
                        minWidth={250}
                        disabled={operatorStore?.selectedTab === 1 ? !(status === 1 || status === 2) : false}
                    />
                )}
                {isDealer() || (isAdministration() && (status !== 1 && !massChange)) ? (
                    <DataUnit
                        name={intl.formatMessage({ id: 'Приоритет заявки' })}
                        value={priority || 'Нет приоритета'}
                    />
                ) : null}
                <Box className={'aln-center'}>
                    {(isAdmin() || isAdministration() || isOperator()) && visible && (
                        <>
                            <IconButton
                                onClick={() => {
                                    changePriority && changePriority(id, value.value === null ? +value.value : +value)
                                    setIsVisible(false)
                                }}
                            >
                                <img alt={''} src={checkIcon} />
                            </IconButton>
                            <IconButton
                                className={'ml-8'}
                                onClick={() => {
                                    setIsVisible(!value)
                                    setValue(() => priority || priorityList['0'])
                                }}
                            >
                                <img alt={''} src={cross} width={20} height={20} />
                            </IconButton>
                        </>
                    )}
                </Box>
            </Box>
        </CustomAccordion>
    )
}

export default ChangePriority
