import React from 'react'
import { notifyAssignDealerInfo } from '../../../../services/ToastifyService/ToastifyService'
import { Box, Button, Typography } from '@mui/material'
import info_blue from '../../../../assets/images/common/info_blue.svg'
import cross_dark_thin from '../../../../assets/images/common/cross_dark_thin.svg'
import { closeModalFn } from '../../../../utils/controllers/ModalController'

const AssignDealerToast = ({ text, requestsAction, action }) => {
    const toastOptions = {
        position: 'bottom-left',
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: false,
        progress: undefined,
        theme: 'light',
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        onClose: () => {
            if (requestsAction) {
                return action()
            }
        },
        progressStyle: { background: '#1668B9' },
        icon: false,
        closeButton: false,
    }

    // кастомный тост с возможностью отменить операцию
    const customNotify = ({ closeToast }) => (
        <Box
            sx={{ display: 'flex', alignItems: 'flex-start', gap: '8px', width: '384px', padding: '8px 8px 12px 8px' }}
        >
            <img alt='info' src={info_blue} />
            <Typography
                sx={{ fontSize: '14px', fontWeight: 300, lineHeight: 'normal', color: 'black', marginRight: '16px' }}
            >
                {text}
            </Typography>
            <Box sx={{ display: 'flex' }}>
                <Button
                    variant={'outlined'}
                    color={'primary'}
                    onClick={() => {
                        requestsAction = false
                        closeToast()
                        closeModalFn['progress-backdrop']()
                    }}
                    sx={{ width: '112px', height: '48px', marginRight: '8px' }}
                >
                    Отмена
                </Button>
                {/* <Box>
                    <img
                        onClick={() => {
                            closeModalFn["progress-backdrop"]()
                            requestsAction = false
                            closeToast()
                        }}
                        src={cross_dark_thin}
                        alt={"close"}
                        style={{cursor: "pointer"}}
                    />
                </Box> */}
            </Box>
        </Box>
    )
    return notifyAssignDealerInfo(customNotify, toastOptions)
}

export default AssignDealerToast
