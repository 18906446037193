import { Button } from '@mui/material'
import React from 'react'
import { openModalFn } from '../../../../../../utils/controllers/ModalController'

const ServiceIsNotPossible = () => {
    const handleAction = () => {
        openModalFn['intermediate-drawer']({ type: 'service-is-not-possible' })
    }
    return (
        <Button onClick={handleAction} fullWidth variant='outlined'>
            Обслуживание невозможно
        </Button>
    )
}

export default ServiceIsNotPossible
