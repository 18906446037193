import {css} from "@emotion/css"


function reportIconComponentStyles(theme) {
    return {
        container: {
            padding: "8px",
            display: "flex",
            alignItems: "center",
            gap: "6px",
            borderRadius: "6px",
            background: theme.palette.gray.veryLightGray,
            cursor: "pointer",
            "&:hover": {
                background: "rgba(93, 175, 239, 0.20)",
            }
        },
        reportIcon: css({
            height: "16px"
        })
    }
}

export default reportIconComponentStyles