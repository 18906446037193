import { Provider, inject, observer } from 'mobx-react'
import React, { useEffect, useState } from 'react'
import AddServiceRequestForm from '../CreateServiceDrawer/AddServiceRequestForm'
import ServiceInstallationData from './ServiceInstallationData'
import { Box } from '@mui/material'
import { isDealer, isOperator } from '../../../../../services/AccountService'

const SubscriberData = ({ serviceStore, operatorStore }) => {
    const [isEdit, setIsEdit] = useState(false)
    const { currentServiceData } = serviceStore
    useEffect(() => {
        serviceStore?.toggleDisabledBtnSubmitRequest(isEdit)
    }, [isEdit])
    return (
        <Box>
            {isEdit ? (
                <Provider serviceStore={serviceStore}>
                    <AddServiceRequestForm
                        alwaysShow={true}
                        onEdit={true}
                        setIsEdit={setIsEdit}
                        isEdit={isEdit}
                        withoutAdditionalInfo={isDealer()}
                    />
                </Provider>
            ) : (
                <ServiceInstallationData
                    serviceStore={serviceStore}
                    setIsEdit={setIsEdit}
                    isEdit={isEdit}
                    defaultOpenState={true}
                    onEdit={
                        isOperator() && operatorStore.selectedTab === 3
                            ? currentServiceData.isTakenByMe
                            : currentServiceData.isDataEditable
                    }
                    serviceOrder={currentServiceData}
                    withoutInstallationDate={true}
                />
            )}
        </Box>
    )
}

export default inject('serviceStore')(observer(SubscriberData))
