import RequestBaseStore from '../../../stores/RequestBaseStore'
import { makeObservable, observable } from 'mobx'
import {
    addComment,
    assignDealer,
    getComments,
    markOrderAsDuplicate,
    markOrderAsDuplicateAdministration,
    markOrderAsDuplicateDealer,
    markOrderAsImpossible,
    resetToCreated,
    setPriority,
    updateFinancialSource,
    updateRequest,
} from '../../../services/ApiService'
import { getComp } from '../../../utils/utils/DI'
import { notifySuccess } from '../../../services/ToastifyService/ToastifyService'
import { isAdmin, isAdministration, isOperator } from '../../../services/AccountService'
import { closeModalFn, isModalOpen, modalData, openModalFn } from '../../../utils/controllers/ModalController'
import OrdersStore from 'src/stores/OrdersStore'
import OperatorRequestStore from 'src/stores/OperatorRequestStore'
import requestsTableStore from '../components/RequestsTableOptimized/RequestsTableOptimizedStore'

class RequestManagementStore extends RequestBaseStore {
    contentState = 'default'
    newDealer = ''
    newEquipment = ''
    comment = ''

    constructor({ operatorStore, ordersStore }) {
        super()
        this.dealersStore = getComp('DealersStore')
        this.statusesStore = getComp('StatusesStore')
        this.ordersStore = ordersStore
        this.operatorStore = operatorStore

        makeObservable(this, {
            contentState: observable,
            newDealer: observable,
            newEquipment: observable,
            comment: observable,
        })
    }

    setOrderDataRequest = (isSuspicious) => {
        const reloadCallback = () => {
            if (isOperator() && this.operatorStore.selectedTab === 0) {
                this.operatorStore.updateOrder(this.data.id)
            }
            this.ordersStore.updateOrder(this.data.id).then(() => {
                    requestsTableStore.addServiceProps(this.ordersStore.collection)
                
            })
        }
        if (isSuspicious) {
            this.data.isSuspicious = !this.data.isSuspicious
            this.setOrderData(this.data.id, { isSuspicious: this.data.isSuspicious }, reloadCallback)
        } else {
            this.data.hasDuplicates = !this.data.hasDuplicates
            this.setOrderData(this.data.id, { hasDuplicates: this.data.hasDuplicates }, reloadCallback)
        }
    }

    setComment = (e) => {
        this.comment = e.target.value
    }

    toDefaultView = () => {
        this.contentState = 'default'
    }

    toAssignDealerView = () => {
        this.contentState = 'assignDealer'
    }

    toChangeStatusView = () => {
        this.contentState = 'changeStatus'
    }
    toEditDataView = () => {
        this.contentState = 'editData'
    }

    clear = () => {
        this.newDealer = ''
        this.newStatus = 0
        this.photoSettings.setNewStatus = 0
        this.comment = ''
        this.newEquipment = ''
        this.toDefaultView()
        super.clear()
    }

    onNewDealerChange = (aid) => {
        this.newDealer = aid
    }

    setNewEquipment = (value) => {
        this.newEquipment = value
    }

    get availableStatuses() {
        const { status } = this.data
        const availableStatuses = [1, 2, 7, 10, 11]

        const statuses = this.statusesStore.orderStatuses.filter((el) => availableStatuses.includes(el.id))
        const toDuplicate = statuses.filter((el) => el.id === 10)
        const includeToDuplicate = [9, 4, 5, 6, 2].includes(status)

        if (isAdministration()) {
            return statuses.filter((el) => el.id === 10)
        }

        if (isAdmin() && (status === 2 || status === 4)) {
            return statuses.filter((el) => el.id === 10 || el.id === 1)
        }

        return includeToDuplicate
            ? toDuplicate
            : status === 7
            ? statuses.filter((el) => el.id !== 11 && el.id !== 7 && el.id !== 1)
            : status === 11
            ? statuses.filter((el) => (isAdmin() || el.id !== 10) && el.id !== 11 && el.id !== 1)
            : statuses.filter((el) => el.id !== 7 && el.id !== 11 && el.id !== 1)
    }
    getCurrentOrder() {
        return this.ordersStore.collection.find((order) => order.id === this.data.id)
    }

    reloadCallback = () => {
        this.ordersStore
            .updateOrder(this.data.id)
            .then((order) => {
                if (order) {
                    this.setData(order)
                } else {
                    closeModalFn['request-details']()
                }
            })
            .then(() => {
                setTimeout(() => {
                    requestsTableStore.addServiceProps()
                }, 400)
            })
        this.toDefaultView()
    }

    assignDealer = (callback) => {
        const endAction = () => {
            if (typeof callback === 'function') {
                callback()
            } else {
                this.reloadCallback()
            }
            notifySuccess('Дилер назначен')
        }
        assignDealer({
            dealerId: isOperator()
                ? this.data.dealerId
                : this.dealersStore.getDealer(this.newDealer || this.data.dealer)?.subjectId,
            orderIds: [this.data.id],
            // relatedDataReset: true
        }).then(() => {
            if (this.comment) {
                addComment({ orderId: this.data.id, text: this.comment }).then(() => {
                    endAction()
                })
            } else {
                endAction()
            }
        })
    }
    markAsImpossible = (callback) => {
        markOrderAsImpossible({
            orderId: this.data.id,
            comment: this.comment,
        }).then(() => {
            if (callback) {
                callback()
            } else {
                this.reloadCallback()
            }
            this.comment = ''
            notifySuccess('Заявка обновлена')
        })
    }
    markAsDuplicate = (operatorCallback) => {
        openModalFn['progress-backdrop']()
        if (isAdministration()) {
            markOrderAsDuplicateAdministration({ orderId: this.data.id, comment: this.comment })
                .then(() => {
                    if (operatorCallback) {
                        operatorCallback()
                    } else {
                        this.reloadCallback()
                    }
                })
                .then(() => {
                    closeModalFn['progress-backdrop']()
                    notifySuccess('Заявка обновлена')
                })
                .catch(() => {
                    closeModalFn['progress-backdrop']()
                })
        } else {
            markOrderAsDuplicate({
                orderId: this.data.id,
            })
                .then(() => {
                    if (operatorCallback) {
                        operatorCallback()
                    } else {
                        this.reloadCallback()
                    }
                })
                .then(() => {
                    closeModalFn['progress-backdrop']()
                    notifySuccess('Заявка обновлена')
                })
                .catch(() => {
                    closeModalFn['progress-backdrop']()
                })
        }
    }

    resetStatusToCreated = (operatorCallback) => {
        resetToCreated({ orderId: this.data.id }).then(() => {
            if (operatorCallback) {
                operatorCallback()
            } else {
                this.reloadCallback()
            }
            notifySuccess('Заявка обновлена')
        })
    }

    changeEquipment = (callback) => {
        updateRequest(this.data.id, {
            equipmentId: this.newEquipment,
            updateComment: this.comment,
        })
            .then(() => {
                this.ordersStore.updateOrder(this.data.id).then((order) => {
                    if (order) {
                        this.setData(order)
                    } else {
                        closeModalFn['request-details']()
                    }
                    notifySuccess('Id оборудования обновлён')
                    this.comment = ''
                })
            })
            .then(() => {
                // this.reloadCallback()
                if (callback) {
                    callback()
                }
            })
    }

    changeFinancialSource = (id, financialSource) => {
        openModalFn['progress-backdrop']()

        updateFinancialSource({ orderId: id, financialSourceId: financialSource })
            .then(() => {
                this.reloadCallback()
                notifySuccess('Тип оплаты изменен')
                closeModalFn['progress-backdrop']()
            })
            .catch(() => {
                closeModalFn['progress-backdrop']()
            })
    }

    changePriority = (id, priority) => {
        openModalFn['progress-backdrop']()
        setPriority({ OrderId: id, Priority: priority })
            .then(() => {
                this.reloadCallback()
                notifySuccess('Приоритет заявки обновлен')
                closeModalFn['progress-backdrop']()
            })
            .catch(() => {
                closeModalFn['progress-backdrop']()
            })
    }
}

export default RequestManagementStore
