import { Box, Checkbox, IconButton, TableCell, TableRow, Tooltip, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import store from './RequestsTableOptimizedStore'
import { observer } from 'mobx-react'
import fullSubsidyIcon from '../../../../assets/images/financialSource/fullSubsidy.svg'
import partialSubsidyIcon from '../../../../assets/images/financialSource/partialSubsidy.svg'
import fullFreeIcon from '../../../../assets/images/financialSource/fullFree.svg'
import createdIcon from '../../../../assets/images/orderStatuses/new.svg'
import assignedIcon from '../../../../assets/images/orderStatuses/assigned.svg'
import acceptedIcon from '../../../../assets/images/orderStatuses/accepted.svg'
import installationIcon from '../../../../assets/images/orderStatuses/installation.svg'
import installedIcon from '../../../../assets/images/orderStatuses/installed.svg'
import incompleteIcon from '../../../../assets/images/orderStatuses/incomplete.svg'
import addAdminIcon from '../../../../assets/images/orderStatuses/uploadByAdmin.svg'
import duplicateIcon from '../../../../assets/images/orderStatuses/duplicate.svg'
import clarifyRequestIcon from '../../../../assets/images/orderStatuses/clarify_request.svg'
import formedIcon from '../../../../assets/images/reportStatuses/formed.svg'
import dataCheckingIcon from '../../../../assets/images/reportStatuses/dataChecking.svg'
import dataClarifyingIcon from '../../../../assets/images/reportStatuses/dataClarifying.svg'
import dataCheckingFinishedIcon from '../../../../assets/images/reportStatuses/dataCheckingFinished.svg'
import closedIcon from '../../../../assets/images/reportStatuses/closed.svg'
import isExpiredAssign from 'src/assets/images/isExpiredAssign.svg'
import isSuspicious from 'src/assets/images/isSuspicious.svg'
import moment from 'moment'
import { formatAddress, formatName, formatPhoneNumber } from '../../../../helpers/ordersUtiles'
import { getComp } from '../../../../utils/utils/DI'
import BodyCheckbox from './BodyCheckbox'
import { getTitleFinancialSource } from '../../../../helpers/switchFinancialSource'
import { closeModalFn } from '../../../../utils/controllers/ModalController'

import copyIcon from 'src/assets/images/common/copy.svg'

function TableOptimizedRow({ index, columns, row, click, store }) {
    const { outlineTrue } = store
    const dealersStore = getComp('DealersStore')
    const statusStore = getComp('StatusesStore')
    const getIconStatus = (status) => {
        switch (status) {
            case 1:
                return createdIcon
            case 2:
                return assignedIcon
            case 4:
                return acceptedIcon
            case 5:
                return installationIcon
            case 6:
                return installedIcon
            case 7:
                return incompleteIcon
            case 9:
                return addAdminIcon
            case 10:
                return duplicateIcon
            case 11:
                return clarifyRequestIcon
            case 12:
                return formedIcon
            case 13:
                return dataCheckingIcon
            case 14:
                return dataClarifyingIcon
            case 15:
                return dataCheckingFinishedIcon
            case 16:
                return closedIcon
            default:
                return null
        }
    }

    const getIconFinancialSourceId = (financialSourceId) => {
        switch (financialSourceId) {
            case 1:
                return fullSubsidyIcon
            case 2:
                return partialSubsidyIcon
            case 3:
                return fullFreeIcon
            default:
                return null
        }
    }

    const rowColor = (row) => {
        const { dataCheckingStatus } = row
        switch (dataCheckingStatus) {
            case 1:
                return 'rgba(22,176,37,0.3) !important'
            case 2:
                return 'rgba(224,23,23,0.25) !important'
            case 3:
                return 'rgba(224,96,23,0.3) !important'
            default:
                return null
        }
    }
    const stylesRowCell = {
        height: '56px',
        padding: '8px',
        fontWeight: '500',
        cursor: 'pointer',
    }

    const typeTableCell = (column, row) => {
        const { propName } = column
        const { status, financialSourceId, dealer, created, installationDate, phone, id, isExpiredInstall } = row

        switch (propName) {
            case 'status':
                return (
                    <TableCell sx={{ ...stylesRowCell }} key={propName} align={'center'}>
                        <Tooltip title={statusStore.getStatusName(status)} placement={'top'}>
                            {status && <img src={getIconStatus(status)} alt={'Статус'} />}
                        </Tooltip>
                    </TableCell>
                )
            case 'financialSourceId':
                return (
                    <TableCell sx={{ ...stylesRowCell }} key={propName}>
                        {financialSourceId && financialSourceId !== 4 && (
                            <Box sx={{ display: 'flex', alignItems: 'center', maxWidth: '120px' }}>
                                <img src={getIconFinancialSourceId(financialSourceId)} alt={'Оплата'} />

                                {financialSourceId === 4 ? null : (
                                    <Typography ml={2} sx={{ fontSize: '14px', fontWeight: '500', width: '60%' }}>
                                        {statusStore?.financialSourcesName[financialSourceId - 1] ||
                                            getTitleFinancialSource(financialSourceId)}
                                    </Typography>
                                )}
                            </Box>
                        )}
                    </TableCell>
                )
            case 'dealer':
                return (
                    <TableCell sx={{ ...stylesRowCell }} key={propName}>
                        <Typography variant={'body2'}>{dealersStore.getDealer(dealer)?.displayName}</Typography>
                    </TableCell>
                )
            case 'created':
                return (
                    <TableCell sx={{ ...stylesRowCell }} align={'center'} key={propName}>
                        <Typography variant={'body2'}>
                            {created ? moment(created).format('DD.MM.YYYY') : null}
                        </Typography>
                    </TableCell>
                )
            case 'installationDate':
                return (
                    <TableCell sx={{ ...stylesRowCell }} align={'center'} key={propName}>
                        <Typography variant={'body2'}>
                            {installationDate ? moment(installationDate).format('DD.MM.YYYY') : null}
                        </Typography>
                    </TableCell>
                )
            case 'fullName':
                return (
                    <TableCell
                        sx={{ ...stylesRowCell, justifyContent: 'flex-start', textAlign: 'left' }}
                        key={propName}
                    >
                        <Typography variant={'body2'}>{formatName(row)}</Typography>
                    </TableCell>
                )
            case 'address':
                return (
                    <TableCell
                        sx={{ ...stylesRowCell, justifyContent: 'flex-start', maxWidth: '300px', textAlign: 'left' }}
                        key={propName}
                    >
                        <Tooltip title={formatAddress(row)} placement={'top-start'}>
                            <Typography
                                variant={'body2'}
                                sx={{
                                    display: '-webkit-box',
                                    WebkitLineClamp: 2,
                                    WebkitBoxOrient: 'vertical',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    wordBreak: 'break-word',
                                }}
                            >
                                {formatAddress(row)}
                            </Typography>
                        </Tooltip>
                    </TableCell>
                )
            case 'phone':
                return (
                    <TableCell sx={{ ...stylesRowCell, paddingRight: '24px' }} key={propName}>
                        <Typography variant={'body2'} sx={{ whiteSpace: 'nowrap' }}>
                            {formatPhoneNumber(phone)}
                        </Typography>
                    </TableCell>
                )
            case 'id':
                return (
                    <TableCell sx={{ ...stylesRowCell, alignItems: 'center' }} key={propName}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            {navigator.clipboard ? (
                                <IconButton
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        navigator.clipboard.writeText(String(id).padStart(6, '0'))
                                    }}
                                >
                                    <img alt={''} src={copyIcon} />
                                </IconButton>
                            ) : null}
                            <Typography variant={'body2'} sx={{ whiteSpace: 'nowrap' }}>
                                {id}
                            </Typography>
                        </Box>
                    </TableCell>
                )

            default:
                return (
                    <TableCell sx={{ ...stylesRowCell, maxWidth: '200px' }} key={propName}>
                        <Typography variant={'body2'}>{row[propName]}</Typography>
                    </TableCell>
                )
        }
    }
    

    return (
        <TableRow
            key={row.id}
            hover
            onClick={() => {
                outlineTrue(row), click({ data: row, index: index })
            }}
            sx={{
                '&:last-child td, &:last-child th': { border: 0 },
                backgroundColor: rowColor(row)
                    ? rowColor(row)
                    : index % 2 === 0
                    ? 'rgba(217, 217, 217, 0.2)'
                    : 'inherit',
                height: row.outline === true ? '46px' : null,
                outline: row.outline === true ? '5px solid rgba(93, 175, 239, 0.3) !important' : null,
                '&:hover': {
                    height: '46px',
                    outline: '5px solid rgba(93, 175, 239, 0.3) !important',
                },
            }}
        >
            <TableCell sx={{ ...stylesRowCell, paddingLeft: '24px' }} align={'center'}>
                {row.isSuspicious?  (
                    <Tooltip title={'Заявка отмечена как подозрительная'} placement={'top'}>
                        {<img src={isSuspicious} alt={'Статус'} />}
                    </Tooltip>
                ) :  row.isExpiredAssign || row.isExpiredInstall ? (
                    <Tooltip title={'Заявка не выполнена в срок'} placement={'top'}>
                        {<img src={isExpiredAssign} alt={'Статус'} />}
                    </Tooltip>
                ) : null}
            </TableCell>
            <BodyCheckbox row={row} />
            {columns.map((column) => typeTableCell(column, row))}
        </TableRow>
    )
}

export default observer(TableOptimizedRow)
