import React, { useMemo } from 'react'
import { closeModalFn, isModalOpen, openModalFn } from '../../../../utils/controllers/ModalController'
import { useIntl } from 'react-intl'
import { observer } from 'mobx-react'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import cross_dark_thin from '../../../../assets/images/common/cross_dark_thin.svg'
import info_blue from '../../../../assets/images/common/info_blue.svg'
import { Dialog } from '@mui/material'
import { getStyles } from '../../../../utils/utils/commonUtils'
import customDialogStyles from '../../../../components/CustomDialog/customDialogStyles'
import getNoun from '../../../../utils/utils/getNoun'
import RequestsTableStore from '../RequestsTableOptimized/RequestsTableOptimizedStore'
import AssignDealerToast from '../AssignDealerSubmitDialog/AssignDealerToast'
import OrdersStore from '../../../../stores/OrdersStore'

const ModalConfirmation = ({ordersStore}) => {
    const intl = useIntl()
    const classes = getStyles(customDialogStyles)
    const styles = {
        width: '470px',
        padding: '0 8px 16px 8px',
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
    }

    const { selected, dealersStore, newDealer, assignDealer, actionType } = RequestsTableStore

    const requestsRus = useMemo(() => {
        return getNoun(selected.length, 'заявки', 'заявок', 'заявок')
    }, [selected.length])

    // переменная для контроля запуска функции
    let requestsAction = true


    const getText = () => {
        switch (actionType) {
            case 'assignToDealer':
                return {
                    text: intl.formatMessage(
                        {
                            id: `Выполняется назначение {selected} {requests} на дилера {dealer}`,
                            defaultMessage: `Выполняется назначение {selected} {requests} на дилера {dealer}`,
                        },
                        {
                            selected: selected.length,
                            dealer: dealersStore.getDealer(newDealer)?.displayName,
                            requests: requestsRus,
                        }
                    ),
                    requestsAction,
                    action: () => assignDealer(ordersStore),
                }
            case false:
                return 'Отменить назначение'
            default:
                return 'Назначить дилеру'
        }
    }

    return (
        <Dialog
            open={!!isModalOpen['modal-confirmation-submit-dialog']}
            onClose={closeModalFn['modal-confirmation-submit-dialog']}
            classes={{
                root: classes.root(styles),
            }}
        >
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '8px 16px' }}>
                <Typography sx={{ color: 'black', fontSize: '16px', fontWeight: 500 }}>
                    {intl.formatMessage({ id: 'Подтверждение операции' })}
                </Typography>

                <IconButton sx={{ padding: 0 }} onClick={closeModalFn['modal-confirmation-submit-dialog']}>
                    <img alt='' src={cross_dark_thin} />
                </IconButton>
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'flex-start', padding: '0 16px 0 16px' }}>
                <img alt='info' src={info_blue} />
                <Typography sx={{ fontSize: '16px', fontWeight: 300, paddingLeft: '8px', lineHeight: 'normal' }}>
                    {intl.formatMessage(
                        {
                            id: `Подтвердить назначение {selected} {requests} на дилера {dealer}?`,
                            defaultMessage: `Подтвердить назначение {selected} {requests} на дилера {dealer}?`,
                        },
                        {
                            selected: <b>{selected.length}</b>,
                            dealer: <b>{dealersStore.getDealer(newDealer)?.displayName}</b>,
                            requests: requestsRus,
                        }
                    )}
                </Typography>
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'center', gap: '16px', marginTop: '8px' }}>
                <Button
                    variant={'contained'}
                    color={'primary'}
                    sx={{ width: '207px' }}
                    onClick={() => {
                        openModalFn['progress-backdrop']()
                        requestsAction = true
                        AssignDealerToast(getText())

                        closeModalFn['modal-confirmation-submit-dialog']()
                    }}
                >
                    {intl.formatMessage({ id: 'Подтвердить' })}
                </Button>
                <Button
                    variant={'outlined'}
                    color={'primary'}
                    sx={{ width: '193px' }}
                    onClick={closeModalFn['modal-confirmation-submit-dialog']}
                >
                    {intl.formatMessage({ id: 'Отмена' })}
                </Button>
            </Box>
        </Dialog>
    )
}
export default observer(ModalConfirmation)
