import React from 'react'
import { Box, Button } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { openModalFn } from '../../../../../../utils/controllers/ModalController'
import { observer } from 'mobx-react'

const GuaranteeRepairBtn = () => {
    const handleActionGuarantee = () => {
        openModalFn['intermediate-drawer']({ type: 'guarantee-repair' })
    }
    const handleActionNotGuarantee = () => {
        openModalFn['intermediate-drawer']({ type: 'not-guarantee-repair' })
    }
    return (
        <>
            <Box width={1} px={2} py={1}>
                <Button
                    variant={'contained'}
                    color={'primary'}
                    fullWidth
                    onClick={handleActionGuarantee}
                    // disabled={isLooker()}
                >
                    <FormattedMessage id={'Гарантийный ремонт'} />
                </Button>
            </Box>
            <Box width={1} px={2} py={1}>
                <Button
                    variant={'contained'}
                    color={'primary'}
                    fullWidth
                    onClick={handleActionNotGuarantee}
                    // disabled={isLooker()}
                >
                    <FormattedMessage id={'Негарантийный ремонт'} />
                </Button>
            </Box>
        </>
    )
}
export default observer(GuaranteeRepairBtn)
