import React, {useState} from "react"
import Box from "@mui/material/Box"
import {getComp} from "../../../utils/utils/DI"
import {getStyles} from "../../../utils/utils/commonUtils"
import reportIconComponentStyles from "../styles/reportIconComponentStyles"
import Tooltip from "@mui/material/Tooltip"
import {observer} from "mobx-react"
import ReportDetailsTooltip from "./ReportDetailsTooltip"

function ReportIconComponent({report, openReportDetails}) {
    const {id, status} = report
    const classes = getStyles(reportIconComponentStyles)
    const {getReportStatusImage} = getComp("StatusesStore")

    const handleOpenReportDetails = () => {
        openReportDetails(report)
    }

    return (
        <Tooltip title={<ReportDetailsTooltip report={report} />} placement={"top-end"}>
            <Box sx={classes.container} onClick={handleOpenReportDetails}>
                <img alt={""} src={getReportStatusImage(status)} className={classes.reportIcon} />№{id}
            </Box>
        </Tooltip>
    )
}

export default observer(ReportIconComponent)
