import React, { useEffect } from 'react'
import Box from '@mui/material/Box'
import { Button, Checkbox, MenuItem, Select, Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { observer } from 'mobx-react'
import shevronDown from '../../../../assets/images/common/shevronDown.svg'
import FormControl from '@mui/material/FormControl'
import { isDealer } from '../../../../services/AccountService'
import { getStyles } from '../../../../utils/utils/commonUtils'
import MassOperationsStyles from './MassOperationsStyles'
import MassOperationDrawerStore from '../MassOperationDrawer/MassOperationDrawerStore'
import RequestsTableStore from '../RequestsTableOptimized/RequestsTableOptimizedStore'

function MassOperations({ stackActionStore, collection, status }) {
    const {
        availableTabs,
        setSelectedTabName,
        selectedTabName,
        selectedTabAction,
        selectedTabActionNew,
        setSelectedChanged,
        selectChanged,
        store,
    } = stackActionStore
    const { getActionTabs, selected, firstItem } = RequestsTableStore

    const { isTableRowSelected, totalSelectedRows, selectAllCollection } = store || {}
    const classes = getStyles(MassOperationsStyles)

    let selectDisabled = isDealer() && status !== 2 ? true : !isTableRowSelected || selected.length === 0
    const handleClick = () => {
       
            MassOperationDrawerStore.nameOperation = selectedTabName
            getActionTabs()
                .find((tab) => tab.name === selectedTabName)
                .action()

            
        
        // stackActionStore.selectedTabName = 'Выберите действие'
    }

    

    return (
        <>
            {collection && (
                <Box sx={{ ...classes.showOnMobile, ...classes.tableHeadCheckboxWrapper }}>
                    <Checkbox
                        checked={!!totalSelectedRows}
                        onChange={() => selectAllCollection(collection)}
                        onClick={(e) => e.stopPropagation()}
                        sx={{ padding: '0 0 0 5px' }}
                    />
                    {!!store.totalSelectedRows && (
                        <Typography sx={{ fontSize: '11px', fontWeight: 400 }}>
                            Выбрано: {store.totalSelectedRows}
                        </Typography>
                    )}
                </Box>
            )}
            <Box className={'fx-nowrap'} pr={2} sx={isDealer() ? { ...classes.hideOnMobile } : {}}>
                <FormControl
                    sx={{
                        '& .MuiInputBase-root': {
                            margin: 0,
                        },
                    }}
                >
                    <Select
                        disabled={selectDisabled}
                        value={selectedTabName}
                        onOpen={(e) => {
                            if (!selectChanged) {
                                setSelectedTabName(e)
                            }
                        }}
                        onChange={(e) => {
                            setSelectedChanged(true)
                            setSelectedTabName(e)
                        }}
                        renderValue={(selectedTabName) => {
                            if (
                                (!selectChanged && selectedTabName === 'Выберите действие') ||
                                selectDisabled ||
                                !selected.length ||
                                !firstItem
                            ) {
                                return (
                                    <Typography sx={{ fontWeight: 500, color: '#5F5F5F' }}>
                                        Выберите действие
                                    </Typography>
                                )
                            }

                            const item = getActionTabs().find((el) => {
                                if (el.name === selectedTabName) {
                                    return el
                                }
                            })
                            return (
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    {item && item.icon ? <img src={item.icon} alt='item icon' /> : null}
                                    <Typography sx={{ ...classes.selectedItem }}>{item?.name}</Typography>
                                </Box>
                            )
                        }}
                        sx={{
                            ...classes.customSelect,
                            fontWeight: selectDisabled && 700,
                            background: selectDisabled ? null : 'white',
                            borderRadius: '4px 0 0 4px',

                            '& .MuiOutlinedInput-notchedOutline': {
                                border: !selectDisabled && selectedTabName ? '1px solid #1668B9' : null,
                            },
                        }}
                        MenuProps={{
                            sx: classes.menuProps,
                        }}
                        IconComponent={(e) => (
                            <img
                                className={e.className}
                                style={{ opacity: selectDisabled ? '0.3' : null }}
                                src={shevronDown}
                                alt={'arrow'}
                            />
                        )}
                    >
                        {getActionTabs()
                            .filter((el) => el.status.includes(status))
                            .map((el) => {
                                return (
                                    <MenuItem value={el.name} key={el.name} sx={classes.menuItem}>
                                        {el.icon ? (
                                            <img style={{ marginRight: '4px' }} src={el.icon} alt={el.name} />
                                        ) : null}
                                        {el.name}
                                    </MenuItem>
                                )
                            })}
                    </Select>
                </FormControl>
                <Button
                    sx={{
                        ...classes.actionBtn,
                        '&:hover': selectedTabActionNew
                            ? {
                                  background: '#19F',
                                  color: 'white',
                                  border: '1px solid #19F',
                                  borderLeft: 'none',
                              }
                            : {
                                  background: 'none',
                                  border: '1px solid #A5A5A5',
                                  borderLeft: 'none',
                              },
                        cursor: selectedTabActionNew ? 'pointer' : 'default',
                        background: selectedTabActionNew ? null : 'none',
                        color: !selectedTabActionNew ? '#A5A5A5' : null,
                        border: selectedTabActionNew ? '1px solid #1668B9' : '1px solid #A5A5A5',
                        borderLeft: 'none',
                    }}
                    onClick={handleClick}
                    
                    variant={'contained'}
                >
                    <FormattedMessage id={'Выполнить'} />
                </Button>
            </Box>
        </>
    )
}

export default observer(MassOperations)
