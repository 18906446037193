import React from 'react'
import CustomAccordion from '../../../../../components/customElements/RequestDataAccordions/utils/CustomAccordion'
import DataUnit from '../../../../../components/customElements/DataUnit/DataUnit'
import { Box } from '@mui/material'
import moment from 'moment'
import { getComp } from '../../../../../utils/utils/DI'
import { observer } from 'mobx-react'

const InformationAccordion = ({ currentServiceData }) => {
    const { getName } = getComp('AuthorsStore')
    return (
        <CustomAccordion summary={'Информация о заявке'} defaultOpenState={true} preventHandleOpen={false}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '12px', marginTop: '12px', paddingLeft: '12px' }}>
                <DataUnit name='№ заявки на обслуживание' value={String(currentServiceData.id).padStart(6, '0')} />
                <DataUnit name='Дата создания' value={moment(currentServiceData.created).utc().format('DD.MM.YYYY')} />
                <DataUnit name='Способ добавления' value={'Интерфейс системы CRM'} />
                <DataUnit name='Автор' value={getName(currentServiceData.authorId)?.name} />
                <DataUnit name='Источник' value={currentServiceData.senderName} />
            </Box>
        </CustomAccordion>
    )
}

export default observer(InformationAccordion)
