import React, { useEffect, useState } from 'react'
import { Provider, observer } from 'mobx-react'
import Box from '@mui/material/Box'
import RequestsTable from '../../components/RequestsTable/RequestsTable'
import { closeModalFn, disposeModal, isModalOpen, openModalFn, regModal } from '../../utils/controllers/ModalController'
import withWidth, { isWidthUp } from '@mui/material/Hidden/withWidth'
import { isAdmin, isOperator } from '../../services/AccountService'
import AssignDealerDrawer from './components/AssignDealerDrawer'
import InstallationRequestDrawer from './components/InstalationRequestDrawer/InstallationRequestDrawer'
import OperatorTableControls from './components/utils/OperatorTableControls'
import MassChangePriorityDrawer from './components/MassChangePriorityDrawer/MassChangePriorityDrawer'
import AssignDealerSubmitDialog from './components/AssignDealerSubmitDialog/AssignDealerSubmitDialog'
import ReportOrderDetailsDrawer from '../Reports/components/ReportOrderDetailsDrawer/ReportOrderDetailsDrawer'
import { useRequestManagementContext, withRequestsManagementStore } from './context/withRequestManagmentContext'
import NewRequestFilters from './components/NewRequestFilters/NewRequestFilters'
import { gState } from '../../utils/controllers/GlobalStateController'
import ProgressBackdrop from '../../components/ProgressBackdrop/ProgressBackdrop'
import RequestDetailsDrawer from 'src/views/RequestsManagement/components/RequestDetailsDrawer/RequestDetailsDrawer'
import ReportsStore from '../Reports/stores/ReportsStore'
import ReportDetails from '../Reports/components/ReportDetails'
import RequestTableSettingsDialog from './components/RequestTableSettingsDialog/RequestTableSettingsDialog'
import RequestTableSettingsStore from './stores/RequestTableSettingsStore'
import { getStyles } from '../../utils/utils/commonUtils'
import requestsManagementStyles from './styles/requestsManagementStyles'
import ServiceStore from '../Service/stores/ServiceStore'
import CreatingServiceRequestDrawer from '../Service/components/Drawers/CreateServiceDrawer/CreatingServiceRequestDrawer'
import ServiceFilters from '../Service/components/Filter/ServiceFilters'
import ServiceSelectOrdersStore from '../Service/stores/ServiceSelectOrdersStore'
import CommonServiceDrawer from '../Service/components/Drawers/CommonServiceDrawer'
import MassChangeTypeSubsidy from './components/MassChangeTypeSubsidy/MassChangeTypeSubsidy'
import MassChangeStatusDrawer from './components/MassChangeStatusDrawer/MassChangeStatusDrawer'
import Modal from '../../components/TestModalWindow/TestModalWindow'
import { Document, pdf, PDFRenderer } from '@react-pdf/renderer'
import DealerRequestPDF from './components/DealerRecuestPDF/DealerRecuestPDF'
import TransactionConfirmation from '../../components/TransactionConfirmation/TransactionConfirmation'

import RequestsTableOptimized from './components/RequestsTableOptimized/RequestsTableOptimized'
import { Button, TextField } from '@mui/material'
import AssignDealerDrawerNew from './components/AssignDealerDrawerNew'
import RequestsTableStore from './components/RequestsTableOptimized/RequestsTableOptimizedStore'
import MassOperationDrawer from './components/MassOperationDrawer/MassOperationDrawer'
import ModalConfirmation from './components/ModalConfirmation/ModalConfirmation'

function RequestsManagement({ width }) {
    const {
        filterStore,
        ordersStore,
        operatorStore,
        selectOrdersStore,
        reportOrderDetailsStore,
        openAddRequestDrawer,
        reLoadCallback,
        openRequestDetails,
        totalCollectionLength,
    } = useRequestManagementContext()
    const classes = getStyles(requestsManagementStyles)
    const [reportsStore] = useState(() => new ReportsStore())
    const { openReportDetails, isReportDetailsOpen, selectedReportId, closeReportDetails } = reportsStore
    const [serviceStore] = useState(() => new ServiceStore())
    let { closeOrderDetails, setCurrentOpenedRowIndex, currentOpenedRowIndex } = reportOrderDetailsStore
    const [serviceSelectOrdersStore] = useState(() => new ServiceSelectOrdersStore({ serviceStore: serviceStore }))
    // стор для настроек отображения колонок таблицы
    const [tableSettingsStore] = useState(() => new RequestTableSettingsStore())

    const { serviceDrawersController, editSelectTab } = serviceStore
    const { massOperation, closeMassOperation } = RequestsTableStore
    // функция вызывается при расформировании отчета
    const handleUpdateTable = () => {
        // если фильтрация применена то обновляем таблицу чтобы убрать заявки не соответствующие фильтру
        // необходимо при расформировании отчета
        if (filterStore.selectedFilters.length) {
            if (!totalCollectionLength) {
                if (isModalOpen['request-details']) {
                    closeModalFn['request-details']()
                }
            }
            ordersStore.reLoad()
            // closeOrderDetails()
        }
    }

    // useEffect(() => {
    //     if (!isModalOpen['request-details'] && !isModalOpen['installation-request']) {
    //         setCurrentOpenedRowIndex(null)
    //     }
    // }, [isModalOpen['request-details'], isModalOpen['installation-request']])

    useEffect(() => {
        regModal('assign-dealer-submit-dialog')
        regModal('modal-confirmation-submit-dialog')
        regModal('installation-request')
        serviceStore.getServiceReceiversFunc()
        if (isOperator()) {
            regModal('create-service-request')
            regModal('common-service-drawer') // общий дровер для всех основных статусов
            regModal('current-service-data')

            serviceStore.getTechnicalData()
            serviceStore.getUploadServicePhotoSettingsFunc()
            if (operatorStore.selectedTab === 1) {
                setCurrentOpenedRowIndex(null)
                ordersStore.reLoad({
                    page: 0,
                    size: operatorStore.pageSize,
                })
            } else if (operatorStore.selectedTab === 2) {
                setCurrentOpenedRowIndex(null)
                serviceStore.reLoadpageSize()
                serviceStore.reLoad({ page: 0, size: 100 })
            } else if (operatorStore.selectedTab === 3) {
                setCurrentOpenedRowIndex(null)
                serviceStore.gerReworkPagedData()
            }
        } else {
            ordersStore.reLoad()
        }

        return () => {
            disposeModal('installation-request')
            disposeModal('assign-dealer-submit-dialog')
            disposeModal('create-service-request')
            disposeModal('common-service-drawer') // общий дровер для всех основных статусов
            disposeModal('current-service-data')
            disposeModal('modal-confirmation-submit-dialog')
        }
    }, [operatorStore.selectedTab])

    useEffect(() => {
        if (isOperator()) {
            if (operatorStore.selectedTab === 0) {
                operatorStore.reLoad({
                    page: 0,
                    size: operatorStore.pageSize,
                })
                operatorStore.setWsConnection()
                return () => {
                    operatorStore.setData([])
                    operatorStore.wsConnection.disconnect()
                }
            }
            if (operatorStore.selectedTab === 3) {
                // serviceStore.gerReworkPagedData()
                serviceStore.setWsConnection()
                return () => {
                    serviceStore.setData([])
                    serviceStore.wsConnection.disconnect()
                }
            }
        }
    }, [operatorStore.selectedTab])

    useEffect(() => {
        regModal('request-table-settings')

        return () => {
            disposeModal('request-table-settings')
        }
    }, [])

    useEffect(() => {
        editSelectTab(operatorStore?.selectedTab)
    }, [operatorStore?.selectedTab])
    const rowsOptions = isWidthUp('sm', width) ? [50, 100, 200, 300, 500] : [50]

    const onRowClick = (e) => {
        return operatorStore.selectedTab === 2 || operatorStore.selectedTab === 3
            ? serviceDrawersController(e)
            : openRequestDetails(e)
    }
    const selectedOrdersList = () => {
        return ordersStore.collection.filter((order) => [...selectOrdersStore.selectedOrders].includes(order.id))
    }
    const downloadRequestsListPDF = async () => {
        const createPDF = pdf(<DealerRequestPDF data={selectedOrdersList()} />).toBlob()

        createPDF.then((blob) => {
            const url = URL.createObjectURL(blob)
            const a = document.createElement('a')
            a.href = url
            a.download = 'Список_заявок.pdf'
            document.body.appendChild(a)
            a.click()
            document.body.removeChild(a)
            URL.revokeObjectURL(url)
        })
    }

    return (
        <>
            {(ordersStore.isReady || operatorStore.isReady) && gState['roles'] ? (
                <>
                    {isOperator() ? (
                        <OperatorTableControls
                            selectedTab={operatorStore.selectedTab}
                            setSelectedTab={operatorStore.setSelectedTab}
                            clearFilters={filterStore.clear}
                        />
                    ) : null}
                    {operatorStore.selectedTab === 2 || operatorStore.selectedTab === 3 ? (
                        <Provider serviceStore={serviceStore} serviceSelectOrdersStore={serviceSelectOrdersStore}>
                            <ServiceFilters selectedTab={operatorStore.selectedTab} />
                        </Provider>
                    ) : (
                        <NewRequestFilters
                            selectOrderCollection={selectOrdersStore?.selectedOrders}
                            downloadRequestsListPDF={downloadRequestsListPDF}
                            totalCollectionLength={totalCollectionLength}
                            filtersStore={filterStore}
                            ordersStore={ordersStore}
                            selectOrdersStore={selectOrdersStore}
                            openAddRequestDrawer={openAddRequestDrawer}
                            operatorStore={operatorStore}
                        />
                    )}

                    <Box name={'table'} sx={classes.tableWrapper}>
                        {isAdmin() ? (
                            <RequestsTableOptimized
                                selectOrdersStore={selectOrdersStore}
                                ordersStore={ordersStore}
                                onRowClick={onRowClick}
                            />
                        ) : (
                            <RequestsTable
                                doublePagination={true}
                                store={
                                    isOperator() && operatorStore.selectedTab === 0
                                        ? operatorStore
                                        : (isOperator() && operatorStore.selectedTab === 2) ||
                                          operatorStore.selectedTab === 3
                                        ? serviceStore
                                        : ordersStore
                                }
                                filterStore={filterStore}
                                operatorStore={operatorStore}
                                selectOrdersStore={selectOrdersStore}
                                onRowClick={onRowClick}
                                selectedTab={operatorStore ? operatorStore.selectedTab : null}
                                rowsPerPageOptions={rowsOptions}
                                scrollButtons={true}
                                tableSettingsStore={tableSettingsStore}
                                // если роль пользователя Админ то будет отображаться кнопка настроек таблицы
                                handleSettings={isAdmin() ? () => openModalFn['request-table-settings']() : null}
                                currentOpenedRowIndex={currentOpenedRowIndex}
                                setCurrentOpenedRowIndex={isReportDetailsOpen ? setCurrentOpenedRowIndex : null}
                                // setCurrentOpenedRowIndex={setCurrentOpenedRowIndex}
                                isServiceTable={
                                    isOperator() && (operatorStore.selectedTab === 2 || operatorStore.selectedTab === 3)
                                        ? true
                                        : false
                                }
                            />
                        )}
                    </Box>
                    <TransactionConfirmation />
                    <AssignDealerDrawer store={selectOrdersStore} />
                    <AssignDealerDrawerNew store={RequestsTableStore} />
                    <AssignDealerSubmitDialog selectOrderStore={selectOrdersStore} />
                    <MassChangePriorityDrawer store={selectOrdersStore} />
                    <MassChangeTypeSubsidy store={selectOrdersStore} />

                    <MassOperationDrawer ordersStore={ordersStore} open={massOperation} onClose={closeMassOperation} />
                    <ModalConfirmation ordersStore={ordersStore} />
                    <MassChangeStatusDrawer store={selectOrdersStore} operatorStore={operatorStore} />
                    <InstallationRequestDrawer
                        serviceStore={serviceStore}
                        ordersStore={ordersStore}
                        openReportDetails={openReportDetails}
                        isReportDetailsOpen={isReportDetailsOpen}
                    />
                    <ReportOrderDetailsDrawer
                        store={reportOrderDetailsStore}
                        ordersStore={ordersStore}
                        reloadCallback={reLoadCallback}
                        fromMainPage
                        onRowClick={onRowClick}
                        openReportDetails={openReportDetails}
                        isReportDetailsOpen={isReportDetailsOpen}
                    />
                    {isReportDetailsOpen ? (
                        <ReportDetails
                            selectedReportId={selectedReportId}
                            onClose={closeReportDetails}
                            topBackdropStart={'0'} // пропс(topBackdropStart) устанавливает  css свойство "top" для бекдропа в окне просмотра отчетов. если не указано то свойство top равно высоте appbar
                            scrollIsAlreadyLocked
                            isReportDetailsOpen={isReportDetailsOpen}
                            updateRequestManagmentTable={handleUpdateTable}
                        />
                    ) : null}

                    <RequestDetailsDrawer
                        filterStore={filterStore}
                        operatorStore={operatorStore}
                        ordersStore={ordersStore}
                        openReportDetails={openReportDetails}
                        isReportDetailsOpen={isReportDetailsOpen}
                    />
                </>
            ) : (
                <ProgressBackdrop isOpen={true} local={true} />
            )}
            {/* диалоговое окно настроек таблицы  */}
            <RequestTableSettingsDialog store={tableSettingsStore} />
            <Provider serviceStore={serviceStore}>
                <CreatingServiceRequestDrawer serviceStore={serviceStore} />
                <CommonServiceDrawer
                    ordersStore={ordersStore}
                    operatorStore={operatorStore}
                    serviceSelectOrdersStore={serviceSelectOrdersStore}
                />
            </Provider>
        </>
    )
}

export default withWidth()(withRequestsManagementStore(observer(RequestsManagement)))
