import { computed, makeAutoObservable, makeObservable, observable } from 'mobx'
import { gState } from '../../../utils/controllers/GlobalStateController'
import enter from 'src/assets/images/common/enter.svg'
import { isAdmin } from '../../../services/AccountService'
import { getComp } from '../../../utils/utils/DI'
import { assignServiceOrderOnDealer, assignServiceOrderOnDealerGroup } from '../../../services/ApiService'
import { notifyError, notifySuccess } from '../../../services/ToastifyService/ToastifyService'
import { closeModalFn, openModalFn } from '../../../utils/controllers/ModalController'
import getNoun from '../../../utils/utils/getNoun'

class ServiceSelectOrdersStore {
    constructor({ serviceStore }) {
        this.isAssignDealerDrawerOpen = false
        this.newDealer = null
        this.selectedOrders = new Set()
        this.selectedOrder = null
        this.ordersStore = serviceStore
        this.dealersStore = getComp('DealersStore')

        makeAutoObservable(this)
    }

    get isTableRowSelected() {
        return this.isOrdersSelected
    }

    getActionTabs = () => {
        return [
            {
                name: gState['intl'].formatMessage({ id: 'Назначить дилера' }),
                action: this.toggleAssignDealerDrawer,
                icon: enter,
                isActive: isAdmin(),
            },
        ]
    }

    clearSelectOrders = () => {
        this.selectedOrders.clear()
    }
    clearSelectOrder = () => {
        this.selectedOrder = null
    }

    get isOrdersSelected() {
        return !!this.selectedOrders.size
    }

    // принимает айдишник заявки из чекбоксов
    selectOrder = (id) => {
        if (this.selectedOrders.has(id)) {
            this.selectedOrders.delete(id)
        } else {
            this.selectedOrders.add(id)
        }
    }

    // принимает айдишник заявки из таблицы
    selectOrderFromDrawer = (id) => {
        this.selectedOrder = [id]
    }

    toggleAssignDealerDrawer = () => {
      
        this.newDealer = null
        this.isAssignDealerDrawerOpen = !this.isAssignDealerDrawerOpen
    }

    assignDealer = () => {
        const orderIds = this.selectedOrder ? this.selectedOrder : this.selectedOrders

        const size = Array.isArray(orderIds) ? orderIds.length : orderIds.size
        openModalFn['progress-backdrop']()
       
        const params = {
            dealerId: this.dealersStore.getDealer(this.newDealer)?.subjectId,
            orderIds: orderIds,
        }

        assignServiceOrderOnDealerGroup(params)
            .then(() => {
                this.ordersStore.reLoad()
            })
            .finally(() => {
                const dealerName = this.dealersStore.getDealer(this.newDealer).displayName
                closeModalFn['progress-backdrop']()
                const message = gState['intl'].formatMessage(
                    {
                        id: '{size} {sizeNoun} {sizeNounAction} на дилера {dealer}',
                        defaultMessage: `{size} {sizeNoun} {sizeNounAction} на дилера {dealer}`,
                    },
                    {
                        size: size,
                        sizeNoun: getNoun(size, 'заявка', 'заявки', 'заявок'),
                        sizeNounAction: getNoun(size, 'назначена', 'назначены', 'назначены'),
                        dealer: dealerName,
                    }
                )
                notifySuccess(message)
            })
            .then((res) => {
                this.clearSelectOrders()

                this.toggleAssignDealerDrawer()
            })
            .catch((err) => {
                closeModalFn['progress-backdrop']()
            })
            .finally(() => {
                this.clearSelectOrder()
                closeModalFn['assign-dealer-submit-dialog']()
            })
    }
}

export default ServiceSelectOrdersStore
