import React, { useEffect, useState } from 'react'
import { isModalOpen } from 'src/utils/controllers/ModalController'
import { Box } from '@mui/system'
import { Paper, Typography } from '@mui/material'
import warningTriangle from 'src/assets/images/common/warningTriangle.svg'
import crossGrayIcon from 'src/assets/images/common/cross_gray.svg'
const WarningPaper = ({ title, text1, text2, style }) => {
    const [showWarning, setShowWarning] = useState(true)

    useEffect(() => {
        if (isModalOpen['create-service-request']) {
            setShowWarning(true)
        }
    }, [isModalOpen['create-service-request']])

    return (
        <Box sx={{ paddingX: '4px', marginTop: '20px', display: showWarning ? 'block' : 'none', ...style }}>
            <Paper
                sx={{
                    background: 'rgba(235, 232, 232, 1)',
                    boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.25)',
                    padding: '8px 10px 8px 16px',
                    borderRadius: '4px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '8px',
                    cursor: 'default',
                }}
            >
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '7px' }}>
                        <img src={warningTriangle} />
                        <Typography sx={{ fontSize: '14px', fontWeight: '500', lineHeight: '17px' }}>
                            {title}
                        </Typography>
                    </Box>

                    <img style={{ cursor: 'pointer' }} src={crossGrayIcon} onClick={() => setShowWarning(false)} />
                </Box>
                <Typography sx={{ fontSize: '14px' }}>{text1}</Typography>
                <Typography sx={{ fontSize: '14px' }}>{text2}</Typography>
            </Paper>
        </Box>
    )
}

export default WarningPaper
