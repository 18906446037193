import {observer} from "mobx-react"
import React from "react"
import Box from "@mui/material/Box"
import InputField from "../../../../components/customElements/InputField/InputField"
import CustomAccordion from "../../../../components/customElements/RequestDataAccordions/utils/CustomAccordion"
import {useIntl} from "react-intl"
import PhotosUpload from "./PhotosUpload"


function AddInformationContent({store}) {
    const {setComment, comment, photoSettings: {photoPermission}} = store
    const intl = useIntl()
    return (
        <Box>
            <CustomAccordion
                defaultOpenState={true}
                summary={intl.formatMessage({id: "Фотографии подтверждающие отклонение"})}
            >
                <PhotosUpload store={store} title={intl.formatMessage(
                    {
                        id: `Загрузите от {minimumLimit} до {maximumLimit} фотографий`,
                        defaultMessage: `Загрузите от {minimumLimit} до {maximumLimit} фотографий`
                    },
                    {
                        minimumLimit: photoPermission.minLimitPerLoad,
                        maximumLimit: photoPermission.maxLimitPerLoad,
                    })}/>
            </CustomAccordion>
            <CustomAccordion
                summary={intl.formatMessage({id: "Причина отклонения заявки"})}
                defaultOpenState={true}
            >
                <InputField
                    multiline
                    value={comment}
                    onChange={setComment}
                    rows={4}
                    fullWidth
                    placeholder={intl.formatMessage({id: "Заполните причину отклонения заявки"})}
                />
            </CustomAccordion>
        </Box>
    )
}

export default observer(AddInformationContent)