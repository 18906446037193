import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { FormattedMessage } from 'react-intl'
import RightDrawer from '../../../../components/customElements/RightDrawer/RightDrawer'
import FinancialSourceAccordio from '../../../../components/customElements/RequestDataAccordions/FinancialSourceAccordio'

const MassChangeTypeSubsidy = ({ store }) => {
    const {
        isMassChangeTypeSubsidyOpen,
        flagResonInstallation,
        toggleMassChangeTypeSubsidy,
        selectedOrders,
        massChangeTypeSubsidy,
        someFullDotationOld,
    } = store

    return (
        <RightDrawer
            open={isMassChangeTypeSubsidyOpen}
            onClose={toggleMassChangeTypeSubsidy}
            titleComp={
                <Box className={'fx-col'}>
                    <Typography variant={'subtitle1'} color={'common.white'}>
                        <FormattedMessage id={'Заявок выбрано'} />: {selectedOrders.size}
                    </Typography>
                    <Typography variant={'body1'} color={'#8FBFF6'}>
                        <FormattedMessage id={'Изменение типа дотации'} />
                    </Typography>
                </Box>
            }
        >
            <FinancialSourceAccordio
                flagResonInstallation={flagResonInstallation}
                ids={[...selectedOrders]}
                massChangeTypeSubsidy={massChangeTypeSubsidy}
                someFullDotationOld={someFullDotationOld}
                createRequest
                massChange
            />
        </RightDrawer>
    )
}

export default observer(MassChangeTypeSubsidy)
