import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import RightDrawer from '../../../../components/customElements/RightDrawer/RightDrawer'
import RequestsTableStore from '../RequestsTableOptimized/RequestsTableOptimizedStore'
import MassOperationDrawerStore from './MassOperationDrawerStore'
import DealerSelect from '../RequestDetails/AssignToDealerView/utils/DealerSelect'
import { Button, css, FormControl, IconButton, InputLabel, MenuItem, Select } from '@mui/material'
import { openModalFn } from '../../../../utils/controllers/ModalController'
import classNames from 'classnames'
import FinancialSourceAccordio from '../../../../components/customElements/RequestDataAccordions/FinancialSourceAccordio'
import checkIcon from 'src/assets/images/common/check.svg'
import cross from 'src/assets/images/common/cross_dark.svg'
import { getComp } from '../../../../utils/utils/DI'
function MassOperationDrawer({ open, onClose, ordersStore }) {
    const {
        data,
        selected,
        actionType,
        newDealer,
        dealersStore,
        massChangeStatus,
        massChangeTypeSubsidy,
        flagResonInstallation,
    } = RequestsTableStore
    const { nameOperation } = MassOperationDrawerStore
    const [value, setValue] = React.useState([])
    const intl = useIntl()
    const statusesStore = getComp('StatusesStore')
    const statuses = statusesStore.orderStatuses.filter((el) => el.id === 1)

   
    const handleClick = () => {
        setValue([])
        massChangeStatus([...selected], ordersStore)
    }
    const renderActionType = () => {
        switch (actionType) {
            case 'assignToDealer':
                return (
                    <>
                        <Box name={'content'} mx={2} className={classNames('fx fx-col', css({ flex: '1 1 auto' }))}>
                            <DealerSelect massAssign={true} store={RequestsTableStore} />
                        </Box>
                        <Box name={'action'} width={1} mt={2} p={2} pb={3}>
                            <Button
                                variant={'contained'}
                                color={'primary'}
                                fullWidth
                                onClick={() => {
                                    openModalFn['modal-confirmation-submit-dialog']()
                                }}
                                disabled={!newDealer}
                            >
                                <FormattedMessage id={'Назначить'} /> {dealersStore.getDealer(newDealer)?.displayName}
                            </Button>
                        </Box>
                    </>
                )
            case 'changeStatus':
                return (
                    <>
                        <Box mb={3} mt={2} mx={2}>
                            <Typography variant={'h6'} sx={{ fontSize: '14px' }}>
                                <FormattedMessage id={'Изменить статус'} />
                            </Typography>
                        </Box>
                        <Box mt={2} mx={2} sx={{ display: 'flex' }}>
                            <FormControl fullWidth>
                                <InputLabel id='demo-simple-select-label'>
                                    <FormattedMessage id={'Статус'} />
                                </InputLabel>
                                <Select
                                    value={value}
                                    label={intl.formatMessage({ id: 'Статус' })}
                                    onChange={(event) => setValue(event.target.value)}
                                >
                                    {statuses.map((el) => (
                                        <MenuItem value={el.id} key={el.id}>
                                            <Box className={'aln-center'}>
                                                <img alt={''} src={el.imageSrc} />
                                                <Box ml={1}>
                                                    <Typography variant={'subtitle2'}>{el.name}</Typography>
                                                </Box>
                                            </Box>
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>

                            {value.length !== 0 && (
                                <Box className={'aln-center'} ml={1.5}>
                                    <IconButton onClick={handleClick}>
                                        <img alt={'Подтвердить'} src={checkIcon} />
                                    </IconButton>
                                    <IconButton
                                        onClick={() => {
                                            setValue([])
                                        }}
                                    >
                                        <img alt={'Отменить'} src={cross} width={20} height={20} />
                                    </IconButton>
                                </Box>
                            )}
                        </Box>
                    </>
                )
            case 'changeTypeSubsidy':
                return (
                    <FinancialSourceAccordio
                        flagResonInstallation={() => flagResonInstallation(ordersStore)}
                        ids={[...selected]}
                        massChangeTypeSubsidy={massChangeTypeSubsidy}
                        ordersStore={ordersStore}
                        createRequest
                        massChange
                    />
                )
            default:
                return 'Назначение на дилера'
        }
    }

    return (
        <RightDrawer
            open={open}
            onClose={onClose}
            titleComp={
                <Box className={'fx-col'}>
                    <Typography variant={'subtitle1'} color={'common.white'}>
                        <FormattedMessage id={'Заявок выбрано'} />: {selected.length}
                    </Typography>
                    <Typography variant={'body1'} color={'#8FBFF6'}>
                        <FormattedMessage id={nameOperation} />
                    </Typography>
                </Box>
            }
        >
            {renderActionType()}
        </RightDrawer>
    )
}

export default observer(MassOperationDrawer)
