import { Button } from '@mui/material'
import React from 'react'

const AcceptBtn = ({ currentServiceData, action }) => {
    const handleAccept = () => {
        action(currentServiceData.id)
    }
    return (
        <Button
            onClick={handleAccept}
            disabled={currentServiceData.status === 2 ? false : true}
            fullWidth
            variant='contained'
        >
            Принять на обслуживание
        </Button>
    )
}

export default AcceptBtn
