import RequestBaseStore from '../../../stores/RequestBaseStore'
import { computed, makeObservable, observable } from 'mobx'
import { getComp } from '../../../utils/utils/DI'
import {
    acceptOrder,
    declineOrder,
    finishInstallation,
    markOrderAsUncompleted,
    startInstallation,
    markOrderAsDuplicateDealer,
    updateRequest,
    updateReceiver,
    getReceivers,
} from '../../../services/ApiService'
import { notifyError, notifySuccess } from '../../../services/ToastifyService/ToastifyService'
import { closeModalFn, modalData, openModalFn } from '../../../utils/controllers/ModalController'
import moment from 'moment'
import { isDealerOperator } from '../../../services/AccountService'
import ServiceInformationAccordionFormStore from '../../Service/stores/ServiceInformationAccordionFormStore'

class InstallationRequestStore extends RequestBaseStore {
    newEquipment = ''
    startUpdate = ''
    comment = ''
    addInformation = false
    isChangeStatusState = false
    isEditInfo = false
    installationDateInput = moment().startOf('day').format('DD-MM-YYYY')
    isImpossible = true
    isForRevision = true
    receiverData = {}

    constructor({ ordersStore }) {
        super()
        this.ordersStore = ordersStore
        const { allDataCheckingStatuses, allOrderStatuses } = getComp('StatusesStore')
        this.orderStatuses = allOrderStatuses
        this.serviceInformationAccordionFormStore = new ServiceInformationAccordionFormStore()
        this.dataCheckingStatuses = allDataCheckingStatuses
        makeObservable(this, {
            newEquipment: observable,
            receiverData: observable,
            startUpdate: observable,
            comment: observable,
            addInformation: observable,
            equipmentIdError: computed,
            isChangeStatusState: observable,
            isImpossible: observable,
            isForRevision: observable,
            commentValidate: computed,
            isEdit: computed,
            installationDateInput: observable,
            isEditInfo: observable,
        })
    }

    getReceiversFunc = (id) => {
        getReceivers(id).then((res) => {
            this.receiverData = res
        })
    }

    setOrderDataRequest = (isSuspicious) => {
        const reloadCallback = () => {
            // изменяю метод чтобы таблица не перезагружалась при изменении поля повторное обращение
            // this.ordersStore.reLoad().then(() => {
            //     this.setSelectedOrder()
            // })
            this.ordersStore.updateOrder(this.data.id).then((order) => {
                if(isSuspicious){
                    this.data.isSuspicious = !this.data.isSuspicious
                }else{

                    this.data.hasDuplicates = !this.data.hasDuplicates
                }
            })
        }
        if (isSuspicious) {
            this.data.isSuspicious = !this.data.isSuspicious
            this.setOrderData(this.data.id, { isSuspicious: this.data.isSuspicious }, reloadCallback)
        } else {
            this.data.hasDuplicates = !this.data.hasDuplicates
            this.setOrderData(this.data.id, { hasDuplicates: this.data.hasDuplicates }, reloadCallback)
        }
    }

    updateDrawerData = () => {
        this.ordersStore.updateOrder(this.data.id).then((order) => {
            if (order) {
                this.setData(order)
            }
        })
    }

    setInstallationDateInput = (e) => {
        this.installationDateInput = e.target.value
    }

    isAvailableForStatusChange = (status) => {
        const availableStatuses = [this.orderStatuses.assigned, this.orderStatuses.accepted, this.orderStatuses.working]
        return availableStatuses.includes(status)
    }

    onEditCallback = (status) => {
        return this.isAvailableForStatusChange(status) ? this.setIsChangeStatusState() : null
    }

    setIsChangeStatusState = () => {
        this.isChangeStatusState = !this.isChangeStatusState
    }

    get commentValidate() {
        return this.comment === ''
    }

    changeStatusToDuplicate = () => {
        openModalFn['progress-backdrop']()
        markOrderAsDuplicateDealer({ orderId: this.data.id, comment: this.comment })
            .then(() => {
                closeModalFn['installation-request']()
                this.toDefaultStep()
                this.ordersStore
                    .reLoad()
                    .then(() => {
                        closeModalFn['progress-backdrop']()
                    })
                    .catch(() => {
                        closeModalFn['progress-backdrop']()
                    })
            })
            .then(() => {
                notifySuccess('Заявка обновлена')
            })
            .catch(() => {
                closeModalFn['progress-backdrop']()
            })
    }

    clear = () => {
        this.isImpossible = true
        this.isForRevision = true
        this.setNewEquipment('')
        this.setComment('')
        this.addInformation = false
        this.newStatus = this.orderStatuses.none
        this.photoSettings.setNewStatus = this.orderStatuses.none
        super.clear()
    }

    toDefaultStep = () => {
        this.isImpossible = true
        this.isForRevision = true
        this.addInformation = false
        this.isChangeStatusState = false
        this.isEditInfo = false
        this.newStatus = this.orderStatuses.none
        this.photoSettings.setNewStatus = this.orderStatuses.none
        this.setComment('')
        this.data.images = {
            install: [],
            impossible: [],
            uncompleted: [],
        }
    }
    toggleEditInfo = () => {
        if (this.isEditInfo) {
            this.isEditInfo = false
            this.setSelectedOrder()
        } else {
            this.isEditInfo = true
        }
    }
    
    get isEdit() {
        return (
            isDealerOperator() &&
            getComp('UserInfoStore').userId === this.data.authorId &&
            [this.orderStatuses.assigned, this.orderStatuses.accepted, this.orderStatuses.installed].includes(
                this.data.status
            ) &&
            this.data.dataCheckingStatus !== this.dataCheckingStatuses.needClarification
        )
    }

    setNewEquipment = (e) => {
        if (!this.data.equipmentId || this.isEdit) {
            if (e.target) {
                this.newEquipment = e.target.value
            } else {
                this.newEquipment = e
            }
        }
    }
    changeEquipment = () => {
        updateRequest(this.data.id, {
            equipmentId: this.newEquipment,
        }).then(() => {
            this.ordersStore.reLoad().then(() => this.setSelectedOrder())
            notifySuccess('Id оборудования обновлён')
        })
    }
    setComment = (e) => {
        if (e.target) {
            this.comment = e.target.value
        } else {
            this.comment = e
        }
    }

    checkUploadedImages = (res) => {
        const { uploaded, message, images } = res
        const errorImages = images.filter((el) => !el.isValid)
        closeModalFn['progress-backdrop']()
        if (uploaded) {
            notifySuccess(message)
            this.apiCallback()
        }
        if (errorImages.length) {
            errorImages.forEach((el) => {
                notifyError(el.error + ': ' + el.filename)
            })
        }
        if (!uploaded) {
            if (!errorImages.length) {
                notifyError(message)
            }
        }
    }
    setSelectedOrder = () => {
        const { id } = modalData['installation-request']
        const order = this.ordersStore.collection.find((el) => el.id === id)
        if (order) {
            this.clear()
            this.toDefaultStep()
            this.installationDateInput = moment().startOf('day').format('DD-MM-YYYY')
            this.setData(order)
            this.getImages()
            this.setNewEquipment(order.equipmentId || '')
        } else {
            closeModalFn['installation-request']()
        }
    }

    apiCallback = () => {
        switch (this.data.status) {
            case this.orderStatuses.assigned:
                notifySuccess('Заявка принята')
                break
            case this.orderStatuses.accepted:
                if (!this.isImpossible && !this.isForRevision) {
                    notifySuccess('Статус обновлен')
                }
                break
            case this.orderStatuses.installed:
            case this.orderStatuses.uncompleted:
            case this.orderStatuses.clarifying:
                break
            default:
                closeModalFn['installation-request']()
        }

        this.ordersStore.updateOrder(this.data.id).then((order) => {
            if (order) {
                this.setSelectedOrder(order)
            } else {
                closeModalFn['installation-request']()
            }

            closeModalFn['progress-backdrop']()
        })
    }
    acceptOrder = () => {
        openModalFn['progress-backdrop']()
        acceptOrder({ orderId: this.data.id })
            .then(() => this.apiCallback())
            .catch(() => closeModalFn['progress-backdrop']())
    }
    startInstallation = () => {
        openModalFn['progress-backdrop']()
        startInstallation({
            orderId: this.data.id,
            equipmentId: this.newEquipment,
        })
            .then(() => this.apiCallback())
            .catch(() => closeModalFn['progress-backdrop']())
    }
    finishInstallation = () => {
        const params = {}
        params.orderId = this.data.id
        params.modelId = +this.serviceInformationAccordionFormStore.data['receiverModelId']
        params.serialNumber = this.serviceInformationAccordionFormStore.data['receiverSerialNumber']
        // updateReceiver(params).then(() => {
            const utcCorrectDate = moment(this.installationDateInput, 'DD-MM-YYYY').local(true).format()
            openModalFn['progress-backdrop']()
            let files = this.data.images.install.map((el) => el.file)
            finishInstallation({
                orderId: this.data.id,
                date: utcCorrectDate,
                ...files,
            })
                .then((res) => this.checkUploadedImages(res))
                .then(() => {
                    this.newEquipment = ''
                })
                .catch(() => closeModalFn['progress-backdrop']())
        // })
    }

    markAsImpossible = () => {
        this.isForRevision = false
        this.newStatus = this.orderStatuses.impossible
        this.photoSettings.setNewStatus = this.orderStatuses.impossible
        if (!this.addInformation) {
            this.data.images = {
                install: [],
                impossible: [],
                uncompleted: [],
            }
            this.addInformation = true
        } else {
            const files = this.data.images.impossible.map((el) => el.file)
            declineOrder({ orderId: this.data.id, comment: this.comment, ...files }).then(this.checkUploadedImages)
        }
    }

    markAsUncompleted = () => {
        this.isImpossible = false
        this.newStatus = this.orderStatuses.uncompleted
        this.photoSettings.setNewStatus = this.orderStatuses.uncompleted
        if (!this.addInformation) {
            this.data.images = {
                install: [],
                impossible: [],
                uncompleted: [],
            }
            this.addInformation = true
        } else {
            let files = []
            if (this.data.images.uncompleted.length) {
                files = this.data.images.uncompleted.map((el) => el.file)
            }
            markOrderAsUncompleted({ orderId: this.data.id, comment: this.comment, ...files }).then(
                this.checkUploadedImages
            )
        }
    }

    get equipmentIdError() {
        let summ
        let number
        if (this.newEquipment) {
            number = +this.newEquipment.slice(0, 2)
            if (this.newEquipment.length > 2) {
                summ = this.newEquipment
                    .slice(2, 14)
                    .split('')
                    .reduce((acc, el) => {
                        return +acc + +el
                    })
            }
        }
        return !(this.newEquipment.length === 14 && summ === number)
    }
}

export default InstallationRequestStore
