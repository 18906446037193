import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import WarningPaper from './WarningPaper'
import lupe from '../../../../../assets/images/common/lupe.svg'
import warningTriangle from '../../../../../assets/images/common/warningTriangle.svg'
import ServiceDrawerElement from './ServiceDrawerElement'
import { inject, observer } from 'mobx-react'
const SearchResult = ({ serviceStore }) => {
    const {
        installedOrders,
        searchByIdMode,
        searchByPhoneMode,
        showSearchResult,
        addThisToCheckedServiceOrder,
        checkedServiceOrderId,
        openedAccordionIndex,
        setOpenedAccordionIndex,
    } = serviceStore

    if (showSearchResult) {
        return (
            <>
                <Box
                    sx={{
                        padding: '0',
                        marginTop: '12px',
                        background: 'rgba(237, 241, 245, 1)',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: '8px 48px 8px 16px',
                        border: 'solid 1px rgba(165, 165, 165, 1)',
                        fontWeight: '500',
                        fontSize: '15px',
                    }}
                >
                    <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                        <img src={lupe} />
                        <Typography sx={{ fontWeight: '500' }}>Результаты поиска</Typography>
                    </Box>
                    <img src={warningTriangle} />
                </Box>
                {installedOrders.length ? (
                    <>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                padding: '0px 16px 8px 16px',
                            }}
                        >
                            {installedOrders.length === 1 && (
                                <WarningPaper
                                    title={'Поиск дал следующий результат'}
                                    text1={
                                        'Пожалуйста, уточните у Абонента адрес и выберите заявку с подходящими данными'
                                    }
                                />
                            )}
                            {installedOrders.length > 1 && (
                                <WarningPaper
                                    title={'Поиск дал несколько результатов'}
                                    text1={
                                        'Пожалуйста, уточните у Абонента адрес и выберите заявку с подходящими данными'
                                    }
                                />
                            )}
                        </Box>
                        {installedOrders.map((el, index) => (
                            <ServiceDrawerElement
                                key={el.id}
                                el={el}
                                checkboxAction={addThisToCheckedServiceOrder}
                                checkedServiceOrderId={checkedServiceOrderId}
                                openedAccordionIndex={openedAccordionIndex}
                                setOpenedAccordionIndex={setOpenedAccordionIndex}
                                index={index}
                                serviceStore={serviceStore}
                            />
                        ))}
                    </>
                ) : (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            padding: '16px 25px',
                        }}
                    >
                        <Typography sx={{ fontWeight: '500' }}>Ничего не найдено</Typography>
                        {searchByIdMode && (
                            <WarningPaper
                                title={'Поиск не дал результатов'}
                                text1={'Пожалуйста, проверьте правильность ввода Id оборудования.'}
                                text2={
                                    'Если данные верные и поиск по ID не дал результатов, попробуйте выполнить поиск по номеру телефона.'
                                }
                            />
                        )}
                        {searchByPhoneMode && (
                            <WarningPaper
                                title={'Поиск не дал результатов'}
                                text1={'Пожалуйста, проверьте правильность ввода телефона и повторите поиск.'}
                                text2={
                                    'Если данные верные и поиск не дал результатов, пожалуйста, заведите заявку с новыми данными и сообщите Абоненту, что обработка и обслуживание такой заявки может потребовать дополнительного времени'
                                }
                            />
                        )}
                    </Box>
                )}
            </>
        )
    }
}

export default inject('serviceStore')(observer(SearchResult))
