import React, { useEffect } from 'react'
import FileInput from '../../../../../components/customElements/FileInput/FileInput'
import { css } from '@emotion/react'
import { useIntl } from 'react-intl'
import { IconButton, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { observer } from 'mobx-react'
import crossGrayIcon from '../../../../../assets/images/common/cross_gray.svg'
import { notifyError } from '../../../../../services/ToastifyService/ToastifyService'

const AddPhotosBlock = ({ settingsField, formStore, serviceStore }) => {
    const intl = useIntl()
    const { uploadServicePhotoSettings } = serviceStore
    const deletePhotoFromUploadList = (url) => {
        const index = formStore.data.files.findIndex((item) => item.url === url)

        formStore.data.files.splice(index, 1)
    }

    const currentSettings = uploadServicePhotoSettings.find((item) => item.key === settingsField)

    const { maxLimitPerLoad, minLimitPerLoad } = currentSettings

    const setFile = (files, settingsField) => {
        const settings = uploadServicePhotoSettings.find((item) => item.key === settingsField)
        if (formStore.data.files === null) {
            formStore.data.files = []
        }
        for (let i = 0; i < files.length; i++) {
            if (files[i].size > settings.maxImageSizeInBytes) {
                notifyError(
                    `${intl.formatMessage(
                        {
                            id: 'Размер файла превышает {size} мегабайт',
                            defaultMessage: 'Размер файла превышает {size} мегабайт',
                        },
                        {
                            size: settings.maxImageSizeInBytes / 1000000,
                        }
                    )} : ${files[i].name}`
                )
            } else {
                formStore.data.files.push({
                    file: files[i],
                    name: files[i].name,
                    url: window.URL.createObjectURL(files[i]),
                })

                if (formStore.data.files.length > maxLimitPerLoad) {
                    notifyError(`Загрузка более ${maxLimitPerLoad} фотографий невозможна`)
                    formStore.data.files = formStore.data.files.slice(0, maxLimitPerLoad)
                    return
                }
            }
        }
    }

    return (
        <Box>
            <Typography sx={{ fontSize: '15px', fontWeight: '300', marginBottom: '4px' }}>
                {`Загрузите от ${minLimitPerLoad} до ${maxLimitPerLoad} фотографий`}
            </Typography>
            <Box sx={{ display: 'flex', gap: '12px', flexDirection: 'column', marginY: '8px' }}>
                {formStore.data.files?.length
                    ? formStore.data.files.map((imgObj, i) => {
                          return (
                              <Box sx={{ display: 'flex', gap: '12px', alignItems: 'center' }}>
                                  <img
                                      style={{
                                          width: '64px',
                                          height: '64px',
                                          borderRadius: '8px',
                                          border: '1px solid gray',
                                      }}
                                      src={imgObj.url}
                                  />
                                  <Typography
                                      variant={'body2'}
                                      color={'grey.500'}
                                      sx={{ fontSize: '15px', marginY: '4px', lineHeight: 1 }}
                                  >
                                      {imgObj.name}
                                  </Typography>
                                  <IconButton
                                      onClick={() => {
                                          deletePhotoFromUploadList(imgObj.url)
                                      }}
                                  >
                                      <img src={crossGrayIcon} />
                                  </IconButton>
                              </Box>
                          )
                      })
                    : null}
            </Box>
            {formStore?.data?.files?.length >= maxLimitPerLoad ? null :
                <FileInput
                    multiple={true}
                    file={null}
                    setFile={(e) => setFile(e, settingsField)}
                    fileTypes={['JPEG', 'JPG', 'PNG']}
                    classes={css({ height: '76px' })}
                    fileName
                    label={intl.formatMessage({ id: 'Фотографии не загружены' })}
                />

            }
        </Box>
    )
}

export default observer(AddPhotosBlock)
