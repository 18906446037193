import { Box, Button } from '@mui/material'
import React, { useState } from 'react'
import OrderStatus from '../../../../../components/customElements/RequestDataAccordions/OrderStatus'
import { inject, observer } from 'mobx-react'
import { isAdmin } from '../../../../../services/AccountService'
import { getComp } from '../../../../../utils/utils/DI'
import ReportChangeStatus from '../../../../Reports/components/ReportInfoDrawer/utils/ReportChangeStatus'
import DataUnit from '../../../../../components/customElements/DataUnit/DataUnit'
import { FormattedMessage } from 'react-intl'
import LinksToInstallationRequest from './LinksToInstallationRequest'
const StatusInfoAndEdit = ({ currentServiceData, serviceStore, hardCodeStatus, ordersStore, openRequestDetails }) => {
    const [isEdit, setIsEdit] = useState(false)
    // const [inputValue, setInputValue] = useState(currentServiceData.status)
    // const { getServiceOrderStatusName, getStatusName } = getComp('StatusesStore')

    const handleChangeStatus = () => {
        setIsEdit(!isEdit)
        serviceStore?.toggleDisabledBtnSubmitRequest(!isEdit)
        serviceStore.setToggleStatusOnly()
    }
    const onEditStatusAdmin = () => {
       return isAdmin() &&  (currentServiceData.status === 7 || currentServiceData.status === 11)
    }
    return isEdit ? (
        <Box mb={1.5} mx={2} sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
            <OrderStatus
                status={hardCodeStatus || currentServiceData.status}
                isService={true}
                onEdit={handleChangeStatus}
            />
            <ReportChangeStatus closeChange={handleChangeStatus} currentServiceData={currentServiceData} store={serviceStore} />
        </Box>
    ) : (
        <Box sx={{ paddingLeft: '10px' }}>
            <Box sx={{ display: 'flex' }}>
                <OrderStatus
                    status={hardCodeStatus || currentServiceData.status}
                    isService={true}
                    onEdit={(onEditStatusAdmin() || currentServiceData.isTakenByMe) && handleChangeStatus}
                    // onEdit={
                    //     isAdmin() &&
                    //     (currentServiceData.status === 7 || currentServiceData.status === 11) &&
                    //     (() => setIsEdit(!isEdit))
                    // }
                    styleEdit={isAdmin() && (currentServiceData.status === 7 || currentServiceData.status === 11) && currentServiceData?.installedOrderId}
                />
                {isAdmin() && (
                    <LinksToInstallationRequest
                        ordersStore={ordersStore}
                        openRequestDetails={openRequestDetails}
                        currentServiceData={currentServiceData}
                    />
                )}
            </Box>
            {currentServiceData.status === 6.4 || currentServiceData.status === 6.5 ? (
                <Box mb={1.5} mx={2} sx={{ flexGrow: 1 }}>
                    <DataUnit
                        name='№ заявки в сервис-центре'
                        value={currentServiceData.externalTrackingNumber || 'Не заполнен'}
                    />
                </Box>
            ) : null}
        </Box>
    )
}
export default inject('serviceStore')(observer(StatusInfoAndEdit))
