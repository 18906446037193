import { Button } from '@mui/material'
import React from 'react'

const StartServiceBtn = ({ currentServiceData, action }) => {
    const handleAction = () => {
        action(currentServiceData.id)
    }
    return (
        <Button onClick={handleAction} fullWidth variant='contained'>
            Начать обслуживание
        </Button>
    )
}

export default StartServiceBtn
