import React, {useMemo} from "react"
import {closeModalFn, isModalOpen, openModalFn} from "../../../../utils/controllers/ModalController"
import {useIntl} from "react-intl"
import {observer} from "mobx-react"
import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import IconButton from "@mui/material/IconButton"
import cross_dark_thin from "../../../../assets/images/common/cross_dark_thin.svg"
import info_blue from "../../../../assets/images/common/info_blue.svg"
import {Dialog} from "@mui/material"
import {getStyles} from "../../../../utils/utils/commonUtils"
import customDialogStyles from "../../../../components/CustomDialog/customDialogStyles"
import getNoun from "../../../../utils/utils/getNoun"
import AssignDealerToast from "./AssignDealerToast"

const AssignDealerSubmitDialog = ({selectOrderStore}) => {
    const intl = useIntl()
    const classes = getStyles(customDialogStyles)
    const styles = {
        width: "470px",
        padding: "0 8px 16px 8px",
        display: "flex",
        flexDirection: "column",
        gap: "16px"
    }

    const requestsRus = useMemo(() => {
        return getNoun(selectOrderStore.selectedOrders?.size, "заявки", "заявок", "заявок")
    }, [selectOrderStore.selectedOrders?.size])

    // переменная для контроля запуска функции
    let requestsAction = true

    return (
        <Dialog
            open={!!isModalOpen["assign-dealer-submit-dialog"]}
            onClose={closeModalFn["assign-dealer-submit-dialog"]}
            classes={{
                root: classes.root(styles)
            }}
        >
            <Box sx={{display: "flex", justifyContent: "space-between", alignItems: "center", padding: "8px 16px"}}>
                <Typography sx={{color: "black", fontSize: "16px", fontWeight: 500}}>
                    {intl.formatMessage({id: "Подтверждение операции"})}
                </Typography>

                <IconButton sx={{padding: 0}} onClick={closeModalFn["assign-dealer-submit-dialog"]}>
                    <img alt="" src={cross_dark_thin} />
                </IconButton>
            </Box>

            <Box sx={{display: "flex", alignItems: "flex-start", padding: "0 16px 0 16px"}}>
                <img alt="info" src={info_blue} />
                <Typography sx={{fontSize: "16px", fontWeight: 300, paddingLeft: "8px", lineHeight: "normal"}}>
                    {intl.formatMessage(
                        {
                            id: `Подтвердить назначение {selected} {requests} на дилера {dealer}?`,
                            defaultMessage: `Подтвердить назначение {selected} {requests} на дилера {dealer}?`
                        },
                        {
                            selected: <b>{selectOrderStore.selectedOrders?.size}</b>,
                            dealer: (
                                <b>
                                    {selectOrderStore.dealersStore.getDealer(selectOrderStore.newDealer)?.displayName}
                                </b>
                            ),
                            requests: requestsRus
                        }
                    )}
                </Typography>
            </Box>

            <Box sx={{display: "flex", justifyContent: "center", gap: "16px", marginTop: "8px"}}>
                <Button
                    variant={"contained"}
                    color={"primary"}
                    sx={{width: "207px"}}
                    onClick={() => {
                        openModalFn["progress-backdrop"]()
                        requestsAction = true
                        AssignDealerToast({
                            text: intl.formatMessage(
                                {
                                    id: `Выполняется назначение {selected} {requests} на дилера {dealer}`,
                                    defaultMessage: `Выполняется назначение {selected} {requests} на дилера {dealer}`
                                },
                                {
                                    selected: selectOrderStore.selectedOrders?.size,
                                    dealer: selectOrderStore.dealersStore.getDealer(selectOrderStore.newDealer)
                                        ?.displayName,
                                    requests: requestsRus
                                }
                            ),
                            requestsAction,
                            action: selectOrderStore.assignDealer
                        })

                        closeModalFn["assign-dealer-submit-dialog"]()
                    }}
                >
                    {intl.formatMessage({id: "Подтвердить"})}
                </Button>
                <Button
                    variant={"outlined"}
                    color={"primary"}
                    sx={{width: "193px"}}
                    onClick={closeModalFn["assign-dealer-submit-dialog"]}
                >
                    {intl.formatMessage({id: "Отмена"})}
                </Button>
            </Box>
        </Dialog>
    )
}
export default observer(AssignDealerSubmitDialog)
