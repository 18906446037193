import { observer } from 'mobx-react'
import React from 'react'
import RightDrawer from '../../../components/customElements/RightDrawer/RightDrawer'
import { FormattedMessage } from 'react-intl'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import AssignToDealerView from './RequestDetails/AssignToDealerView/AssignToDealerView'

function AssignDealerDrawerNew({ store, title, fromDrawer, serviceStore }) {
    const { isAssignDealerDrawerOpen, toggleAssignDealerDrawer, selected } = store
    const massAssignFlag = () => {
        return store.selected.length !== 0 && (store.selected === null || store.selected === undefined)
    }

    return (
        <RightDrawer
            open={isAssignDealerDrawerOpen}
            onClose={toggleAssignDealerDrawer}
            titleComp={
                <Box className={'fx-col'}>
                    {(massAssignFlag() ? false : title) ? (
                        <Typography variant={'subtitle1'} color={'common.white'}>
                            {title}
                        </Typography>

                    ) : (
                        <>
                            <Typography variant={'subtitle1'} color={'common.white'}>
                                <FormattedMessage id={'Заявок выбрано'} />: {selected.length}
                            </Typography>
                            <Typography variant={'body1'} color={'#8FBFF6'}>
                                <FormattedMessage id={'Назначение на дилера'} />
                            </Typography>
                        </>
                    )}
                </Box>
            }
        >
            <AssignToDealerView serviceStore={serviceStore}  store={store} massAssign={true} />
        </RightDrawer>
    )
}

export default observer(AssignDealerDrawerNew)