import { Button } from '@mui/material'
import React from 'react'
import { openModalFn } from '../../../../../../utils/controllers/ModalController'

const OnSiteRepairBtn = ({ currentServiceData }) => {
    const handleAction = () => {
        openModalFn['on-site-service-drawer']()
    }
    return (
        <Button
            onClick={handleAction}
            fullWidth
            variant='contained'
            disabled={currentServiceData.equipmentId ? false : true}
        >
            Ремонт на месте
        </Button>
    )
}

export default OnSiteRepairBtn
