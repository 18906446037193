import React, { useEffect, useState } from 'react'
import CustomAccordion from '../../../../../components/customElements/RequestDataAccordions/utils/CustomAccordion'
import { Box, Button, TextField, Typography } from '@mui/material'
import { inject, observer } from 'mobx-react'
import { modalData } from '../../../../../utils/controllers/ModalController'
import DataUnit from '../../../../../components/customElements/DataUnit/DataUnit'
import moment from 'moment'

const CommentsAccordion = ({ serviceStore, canAddComment = true }) => {
    const { getServiceComments, postServiceOrderComment, currentServiceData } = serviceStore
    const [commentValue, setCommentValue] = useState('')

    useEffect(() => {
        getServiceComments(currentServiceData.id)
    }, [currentServiceData.status])

    const commentsArray = modalData['current-service-data'].serviceOrder.comments

    const handleAddComment = () => {
        postServiceOrderComment({
            comment: commentValue,
            orderId: currentServiceData.id,
        }).then(() => {
            setCommentValue('')
            getServiceComments(currentServiceData.id)
        })
    }

    return (
        <CustomAccordion summary={'Комментарии'} defaultOpenState={false} preventHandleOpen={false}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '2px', marginTop: '12px' }}>
                {commentsArray &&
                    commentsArray.slice().reverse().map((comment, i) => (
                        <Box
                            sx={{
                                padding: '8px 8px 4px 12px',
                                borderRadius: '4px',
                                marginTop: i ? '4px' : 0,
                                display: 'flex',
                                flexDirection: 'column',
                                width: '100%',
                                position: 'relative',
                                border: '1px solid #EBE8E8',
                                boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.25)',
                            }}
                        >
                            <Typography
                                variant={'body1'}
                                sx={{ marginBottom: '4px', fontWeight: '600', fontSize: '12px' }}
                            >
                                {comment.author}
                            </Typography>
                            <Typography variant={'body1'} sx={{ wordBreak: 'break-word', fontSize: '14px' }}>
                                {comment.text}
                            </Typography>
                            <Typography sx={{ fontSize: '12px', textAlign: 'right' }}>{comment.date}</Typography>
                        </Box>
                    ))}
                {canAddComment && (
                    <Box sx={{ marginTop: '4px' }}>
                        <TextField
                            id='outlined-multiline-flexible'
                            label='Комментарий'
                            multiline
                            maxRows={4}
                            sx={{ width: '100%' }}
                            value={commentValue}
                            onChange={(e) => setCommentValue(e.target.value)}
                            // onKeyUp={(e) => e.key === 'Enter' && handleAddComment()}
                            // onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
                        />
                        <Button
                            onClick={handleAddComment}
                            fullWidth
                            sx={{ marginTop: '10px' }}
                            variant='contained'
                            disabled={commentValue.trim().length === 0}
                        >
                            Отправить комментарий
                        </Button>
                    </Box>
                )}
            </Box>
        </CustomAccordion>
    )
}

export default inject('serviceStore')(observer(CommentsAccordion))
