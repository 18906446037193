import React from 'react'
import RightDrawer from '../../../../../components/customElements/RightDrawer/RightDrawer'
import { closeModalFn, isModalOpen } from '../../../../../utils/controllers/ModalController'
import { FormattedMessage } from 'react-intl'
import { Box } from '@mui/system'
import { observer } from 'mobx-react-lite'
import { inject } from 'mobx-react'
import SearchResult from './SearchResult'
import DrawerButtons from './DrawerButtons'
import FormsOnTop from './FormsOnTop'
import WarningsContainer from './WarningsContainer'
import AddServiceRequestForm from './AddServiceRequestForm'
const CreatingServiceRequestDrawer = ({ serviceStore }) => {
    const { onCloseDrawer, equipmentIdError } = serviceStore
    return (
        <RightDrawer
            open={isModalOpen['create-service-request']}
            onClose={onCloseDrawer}
            title={<FormattedMessage id={'Заявка на сервисное обслуживание'} />}
            backButtonClick={() => closeModalFn['create-service-request']()}
        >
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
                <Box>
                    <FormsOnTop />
                    <AddServiceRequestForm />
                    <SearchResult />
                    <WarningsContainer />
                </Box>
                <DrawerButtons />
            </Box>
        </RightDrawer>
    )
}

export default inject('serviceStore')(observer(CreatingServiceRequestDrawer))
