import React from 'react'
import { observer } from 'mobx-react'
import Box from '@mui/material/Box'
import classNames from 'classnames'
import { css } from '@emotion/css'
import Button from '@mui/material/Button'
import AssignedDealer from '../../../../../components/customElements/RequestDataAccordions/AssignedDealer'
import DealerSelect from './utils/DealerSelect'
import { FormattedMessage } from 'react-intl'
import { isModalOpen, openModalFn } from '../../../../../utils/controllers/ModalController'

function AssignToDealerView({ store, massAssign , serviceStore}) {
    
    let { status, dealer } = store.data || serviceStore?.currentServiceData || {}
    dealer =  serviceStore? serviceStore.currentServiceData?.assignDealer : store.data?.dealer
    
    return (
        <>
            <Box name={'content'} mx={2} className={classNames('fx fx-col', css({ flex: '1 1 auto' }))}>
                <Box mx={-2}>
                    {!!status && !massAssign &&  <AssignedDealer request={serviceStore? serviceStore.currentServiceData : store.data} defaultOpenState={true} />}
                </Box>
                <DealerSelect massAssign={massAssign} store={store} />
            </Box>

            <Box name={'action'} width={1} mt={2} p={2} pb={3}>
                <Button
                    variant={'contained'}
                    color={'primary'}
                    fullWidth
                    onClick={() => {
                        if (massAssign) {
                            openModalFn['assign-dealer-submit-dialog']()
                            return
                        }

                        store.assignDealer()
                    }}
                    disabled={!store.newDealer || (store.newDealer === dealer && ![1,7,11].includes(status))}
                >
                    <FormattedMessage id={'Назначить'} /> {store.dealersStore.getDealer(store.newDealer)?.displayName}
                </Button>
            </Box>
        </>
    )
}

export default observer(AssignToDealerView)
