import { Button } from '@mui/material'
import React from 'react'
import { openModalFn } from '../../../../../../utils/controllers/ModalController'

const TransferToServiceCenterBtn = ({ currentServiceData }) => {
    const handleAction = () => {
        openModalFn['transfer-to-service-center-drawer']()
    }
    return (
        <Button
            onClick={handleAction}
            fullWidth
            variant='contained'
            disabled={currentServiceData.equipmentId ? false : true}
        >
            Передача в сервисный центр
        </Button>
    )
}

export default TransferToServiceCenterBtn
