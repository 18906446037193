import { Button } from '@mui/material'
import React from 'react'
import { openModalFn } from '../../../../../../utils/controllers/ModalController'

const ProblemSolvedRemotely = () => {
    const handleAction = () => {
        openModalFn['intermediate-drawer']({ type: 'problem-solved-remotely' })
    }
    return (
        <Button onClick={handleAction} fullWidth variant='outlined'>
            Проблема решена дистанционно
        </Button>
    )
}

export default ProblemSolvedRemotely
