import { Box } from '@mui/system'
import React, { useEffect } from 'react'
import MultipleSelect from '../../../../../components/customElements/MultipleSelect/MultipleSelect'
import FormInput from '../../../../../components/customElements/FormInput'
import CustomAccordion from '../../../../../components/customElements/RequestDataAccordions/utils/CustomAccordion'
import { useIntl } from 'react-intl'
import { getComp } from '../../../../../utils/utils/DI'
import { placeNames } from '../../../../../helpers/globalVariables'
import { inject, observer } from 'mobx-react'
import moment from 'moment'
import { Button } from '@mui/material'
import { useRequestManagementContext } from '../../../../RequestsManagement/context/withRequestManagmentContext'
import { isAdmin, isOperator } from '../../../../../services/AccountService'
const AddServiceRequestForm = ({ serviceStore, alwaysShow, onEdit, isEdit, setIsEdit, withoutAdditionalInfo }) => {
    const territoryStore = getComp('TerritoryStore')
    const { shownSenders, defaultSenders } = getComp('SendersStore')
    const {
        showAddServiceRequestForm,
        serviceAddRequestFormStore,
        currentServiceData,
        gerReworkPagedData,
        updateServiceOrder,
    } = serviceStore
    const categoriesStore = getComp('CategoriesStore')
    const intl = useIntl()
    const { operatorStore } = useRequestManagementContext()

    const disabledCreatedStatus = () => {
        return (
            currentServiceData?.status === 1 ||
            currentServiceData?.status === 2 ||
            currentServiceData?.status === 4 ||
            currentServiceData?.status === 5 ||
            currentServiceData?.status === 7 ||
            currentServiceData?.status === 10
        )
    }

    let editData = [
        {
            propName: 'surname',
            validName: 'surname',
            label: intl.formatMessage({ id: 'Фамилия' }),
            type: 'text',
            capitalizeFirstLetter: true,
        },
        { propName: 'name', label: intl.formatMessage({ id: 'Имя' }), type: 'text', capitalizeFirstLetter: true },
        {
            propName: 'patronymic',
            validName: 'patronymic',
            label: intl.formatMessage({ id: 'Отчество' }),
            type: 'text',
            capitalizeFirstLetter: true,
        },
        {
            propName: 'phoneNumber',
            validName: 'phoneNumber',
            mask:"+7 (999)-999-99-99",
            label: intl.formatMessage({ id: 'Телефон' }),
            type: 'text',
        },

        {
            propName: 'territory',
            validName: 'territory',
            label: intl.formatMessage({ id: 'Регион' }),
            items: territoryStore.collection,
            type: 'select',
        },
        {
            propName: 'district',
            validName: 'district',
            label: intl.formatMessage({ id: 'Округ' }),
            autocompleteOptions: { territory: serviceAddRequestFormStore.data['territory'], type: 'district' },
            readOnly: serviceAddRequestFormStore.data.territory === '',
            disabled: serviceAddRequestFormStore.data.territory === '',
            territorySelect: true,
            type: 'autocomplete',
        },
        {
            propName: 'locality',
            validName: 'locality',
            label: intl.formatMessage({ id: 'Населенный пункт' }),
            territorySelect: true,
            autocompleteOptions: {
                territory: serviceAddRequestFormStore.data['territory'],
                district: serviceAddRequestFormStore.data['district'],
                type: 'locality',
            },
            readOnly: serviceAddRequestFormStore.data.district === '',
            disabled: serviceAddRequestFormStore.data.district === '',
            type: 'autocomplete',
        },
        {
            propName: 'addressType',
            validName: 'addressType',
            label: intl.formatMessage({ id: 'Топоним (улица / переулок и т.д.)' }),
            type: 'autocomplete',
            items: placeNames,
        },
        {
            propName: 'address',
            validName: 'address',
            label: intl.formatMessage({ id: 'Наименование топонима (улицы / переулка и т.д.)' }),
            type: 'text',
        },
        { propName: 'house',validName: 'house', label: intl.formatMessage({ id: 'Дом' }), type: 'text', paste:true, },
        { propName: 'building' ,validName: 'building', label: intl.formatMessage({ id: 'Корпус' }), type: 'text', paste:true, },
        { propName: 'apartment',validName: 'apartment', label: intl.formatMessage({ id: 'Квартира' }), type: 'text', paste:true, },
        {
            propName: 'created',
            validName: 'created',
            label: intl.formatMessage({ id: 'Дата обращения' }),
            mask: '99-99-9999, 99:99',
            placeholder: 'DD-MM-YYYY, HH:mm',
            type: 'text',
            defaultValue: moment().format('DD-MM-YYYY, HH:mm'),
        },

        // {propName: 'preferentialCategory', label: intl.formatMessage({id: 'Льготная категория'})},
        {
            propName: 'preferentialCategories',
            validName: 'preferentialCategories',
            label: intl.formatMessage({ id: 'Льготные категории' }),
            items: categoriesStore.collection.map((el) => {
                return { key: el.name, value: el.id }
            }),
            multiple: true,
            type: 'multiple',
            onClear: serviceAddRequestFormStore.onClearMultiple,
        },
        {
            propName: 'senderId',
            validName: 'senderId',
            label: intl.formatMessage({ id: 'Источник данных о заявке' }),
            items: shownSenders.map((el) => ({ key: el.name, value: el.id })),
            defaultValue:
                defaultSenders && shownSenders.find((sender) => sender.id === defaultSenders) ? defaultSenders : '',
            type: 'select',
        },
        {
            propName: 'additionalInfo',
            validName: 'additionalInfo',
            label: 'Дополнительная информация',
            type: 'text',
            multiline: true,
            rows: 5,
            paste:true,
        },

        // {
        //     propName: 'hasDuplicates',
        //     label: intl.formatMessage({ id: 'Повторное обращение' }),
        //     type: 'checkbox',
        //     visible: true,
        // },
        // {
        //     propName: 'categories',
        //     label: intl.formatMessage({id: 'Список льготных категорий'}),
        //     items: categoriesStore.collection.map(el => ({key: el.id, value: el.name})),
        //     defaultValue: categoriesStore.collection[0]? categoriesStore.collection[0].id : '',
        // }
    ]

    if (isOperator() && isEdit) {
        const arr = ['additionalInfo', 'preferentialCategories', 'created']
        editData = editData.filter((el) => !arr.includes(el.propName))
    }

    if (isAdmin() && isEdit) {
        const arr = ['created']
        editData = editData.filter((el) => !arr.includes(el.propName))
    }

    if(disabledCreatedStatus() && isEdit) {
        editData = editData.filter((el) => el.propName !== 'created')
    }

    const handleUpdateOrder = () => {
        updateServiceOrder(operatorStore?.selectedTab && operatorStore?.selectedTab === 3).then(() => {
            // это нужно для вызова rework на стр уточнения по ремонтам
            setIsEdit(false)
        })
    }
    // валидация фильтров ввода при создании заявки
    // const validateFilterInputs = (event, propName) => {
    //     if (propName === 'phoneNumber') {
    //         const phonePattern = /^[0-9()-]+$/
    //         return phonePattern.test(event.target.value) || event.target.value === '' ? true : false
    //     }
    //     return true
    // }
    const handleEdit = () => {
        setIsEdit(!isEdit)
    }
    if (showAddServiceRequestForm || alwaysShow) {
        return (
            <Box
                mt={alwaysShow ? 0 : 2}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    height: '100%',
                }}
            >
                <Box>
                    <CustomAccordion
                        summary={alwaysShow ? 'Редактирование данных' : 'Данные Абонента'}
                        defaultOpenState={true}
                        preventHandleOpen={true}
                        onEdit={onEdit ? handleEdit : null}
                    >
                        {editData.map((el, i) => {
                            const {
                                propName,
                                validName,
                                label,
                                items,
                                mask,
                                defaultValue,
                                autocompleteOptions,
                                multiple,
                                readOnly,
                                disabled,
                                type,
                                visible,
                                territorySelect,
                                onClear,
                                capitalizeFirstLetter,
                                styles,
                                multiline,
                                rows,
                                paste
                            } = el

                            if (withoutAdditionalInfo && propName === 'additionalInfo') {
                                return
                            }

                            return (
                                <Box mt={i ? 1.5 : 0} key={i + 'createOrderInput'} sx={{ marginTop: '12px' }}>
                                    {multiple ? (
                                        <MultipleSelect
                                            items={items}
                                            value={serviceAddRequestFormStore.data.preferentialCategories}
                                            // value={categoriesStore.getInputCategories(
                                            //     serviceAddRequestFormStore.data.preferentialCategories
                                            // )}
                                            onChange={serviceAddRequestFormStore.onChangeMultiple}
                                            label={label}
                                            propName={propName}
                                            validName={validName}
                                            readOnly={readOnly}
                                            disabled={disabled}
                                            onClear={onClear}
                                        />
                                    ) : (
                                        <Box mt={i ? 1.5 : 0} key={i + ''}>
                                            <FormInput
                                                api={serviceAddRequestFormStore}
                                                type={type}
                                                autocompleteOptions={autocompleteOptions}
                                                items={!!items ? items : null}
                                                propName={propName}
                                                validName={validName}
                                                fullWidth
                                                defaultValue={defaultValue}
                                                mask={mask}
                                                label={label}
                                                withoutErrorText
                                                readOnly={readOnly}
                                                disabled={disabled}
                                                visible={visible}
                                                territorySelect={territorySelect}
                                                capitalizeFirstLetter={capitalizeFirstLetter}
                                                styles={styles}
                                                multiline={multiline}
                                                paste={paste}
                                                // validateFilterInputs={validateFilterInputs}
                                            />
                                        </Box>
                                    )}
                                </Box>
                            )
                        })}
                        {alwaysShow && (
                            <Box className={'jst-sa'} sx={{ gap: '8px', marginTop: '14px' }}>
                                <Button onClick={handleUpdateOrder} color={'primary'} variant={'contained'} fullWidth>
                                    {intl.formatMessage({ id: 'Применить изменения' })}
                                </Button>
                                <Button
                                    onClick={() => setIsEdit(false)}
                                    color={'primary'}
                                    variant={'contained'}
                                    fullWidth
                                >
                                    {intl.formatMessage({ id: 'Отмена' })}
                                </Button>
                            </Box>
                        )}
                    </CustomAccordion>
                </Box>
            </Box>
        )
    }
}

export default inject('serviceStore')(observer(AddServiceRequestForm))
