import { observer } from 'mobx-react'
import { useIntl } from 'react-intl'
import React from 'react'
import TableControls from '../../../../components/customElements/TableControls'

function OperatorTableControls({ selectedTab, setSelectedTab, clearFilters }) {
    const intl = useIntl()
    const data = [
        {
            text: intl.formatMessage({ id: intl.formatMessage({ id: 'уточнения по установкам' }) }),
            color: selectedTab === 0 ? '#16B025' : '#5F5F5F',
        },
        {
            text: intl.formatMessage({ id: intl.formatMessage({ id: 'заявки на установку' }) }),
            color: selectedTab === 1 ? '#16B025' : '#5F5F5F',
        },
        {
            text: intl.formatMessage({ id: intl.formatMessage({ id: 'заявки на ремонт' }) }),
            color: selectedTab === 2 ? '#16B025' : '#5F5F5F',
        },
        {
            text: intl.formatMessage({ id: intl.formatMessage({ id: 'уточнения по ремонтам' }) }),
            color: selectedTab === 3 ? '#16B025' : '#5F5F5F',
        },
    ]
    return (
        <TableControls
            controls={data}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
            clearFilters={clearFilters}
        />
    )
}

export default observer(OperatorTableControls)
