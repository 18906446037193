import React from 'react'
import { observer } from 'mobx-react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { useIntl } from 'react-intl'
import moment from 'moment'

function ActionButtons({ store }) {
    const intl = useIntl()
    const {
        acceptOrder,
        startInstallation,
        equipmentIdError,
        finishInstallation,
        data,
        changeStatusToDuplicate,
        addInformation,
        markAsImpossible,
        markAsUncompleted,
        isChangeStatusState,
        commentValidate,
        isAvailableForStatusChange,
        onEditCallback,
        installationDateInput,
        isImpossible,
        isForRevision,
        photoSettings: { photoPermission },
        orderStatuses,
        serviceInformationAccordionFormStore,
    } = store
    const { status } = data
    
    const buttonsData = [
        {
            name: intl.formatMessage({ id: 'Принять' }),
            action: acceptOrder,
            isVisible: status === orderStatuses.assigned && !isChangeStatusState,
        },
        {
            name: intl.formatMessage({ id: 'Начать установку' }),
            action: startInstallation,
            isVisible: status === orderStatuses.accepted && !isChangeStatusState && isImpossible && isForRevision,
            disabled: equipmentIdError,
        },
        {
            name: intl.formatMessage({ id: 'Завершить установку' }),
            action: finishInstallation,
            isVisible: status === orderStatuses.working && !isChangeStatusState && isForRevision && isImpossible,
            disabled:
                // (!serviceInformationAccordionFormStore.data.receiverSerialNumber&& status !==4) ||
                // (!serviceInformationAccordionFormStore.data.receiverModelId && status !==4) ||
                photoPermission.isInstallButtonDisabled ||
                installationDateInput.length !== 10 ||
                moment().diff(moment(installationDateInput, 'DD-MM-YYYY')) < 0,
        },
        {
            name: intl.formatMessage({ id: 'Установка невозможна' }),
            action: markAsImpossible,
            isVisible:
                (status === orderStatuses.working || status === orderStatuses.accepted) &&
                isImpossible &&
                !isChangeStatusState,
            disabled: addInformation && (commentValidate || photoPermission.isImposibleButtonDisabled),
            outlined: addInformation ? false : true,
        },
        {
            name: intl.formatMessage({ id: 'Вернуть на доработку' }),
            action: markAsUncompleted,
            isVisible:
                (status === orderStatuses.working || status === orderStatuses.accepted) &&
                isForRevision &&
                !isChangeStatusState,
            disabled: addInformation && (commentValidate || photoPermission.isUncomplitedButtonDisabled),
            outlined: addInformation ? false : true,
        },
        {
            name: intl.formatMessage({ id: 'Перевести в дубликат' }),
            action: isChangeStatusState ? changeStatusToDuplicate : () => onEditCallback(status),
            isVisible: isAvailableForStatusChange(status) && isForRevision && isImpossible,
            disabled: isChangeStatusState ? commentValidate : !isAvailableForStatusChange(status),
            outlined: isChangeStatusState ? false : true,
        },
    ]

    return (
        <Box px={2} mt={2}>
            {buttonsData.map((button) => {
                const { name, action, disabled, isVisible, outlined } = button
                const validName = name ? name : ''
                return isVisible ? (
                    <Box width={1} mb={1}>
                        <Button
                            key={name + '-action-button'}
                            variant={outlined ? 'outlined' : 'contained'}
                            color={'primary'}
                            fullWidth
                            onClick={action}
                            disabled={disabled}
                            sx={
                                outlined
                                    ? null
                                    : {
                                          // исправление бага для мобильной версии, скрыл появляющиеся стили ховера при монтировании компонента
                                          '&:hover': {
                                              background: disabled ? 'rgba(0, 0, 0, 0.12)' : 'rgb(22,104,185)',
                                          },
                                      }
                            }
                        >
                            {validName}
                        </Button>
                    </Box>
                ) : null
            })}
        </Box>
    )
}

export default observer(ActionButtons)
