import React, { useEffect, useRef, useState } from 'react'
import CustomAccordion from '../../../../../components/customElements/RequestDataAccordions/utils/CustomAccordion'
import { inject, observer } from 'mobx-react'
import FormInput from '../../../../../components/customElements/FormInput'
import { Box, TextField, Typography } from '@mui/material'
import checkIcon from '../../../../../assets/images/common/check.svg'
import crossIcon from '../../../../../assets/images/common/cross_dark.svg'
import { closeModalFn, openModalFn } from '../../../../../utils/controllers/ModalController'
import { changeServiceEquipmentId } from '../../../../../services/ApiService'
import DataUnit from '../../../../../components/customElements/DataUnit/DataUnit'
import WarningPaper from '../CreateServiceDrawer/WarningPaper'
import { useRequestManagementContext } from '../../../../RequestsManagement/context/withRequestManagmentContext'
const EquipmentDataAccordion = ({ equipmentId, serviceOrderId, serviceStore, preventEdit }) => {
    const equipmentIdRef = useRef(equipmentId)

    const { changeServiceOrderEquipmentId, currentServiceData } = serviceStore
    const [isEdit, setIsEdit] = useState(currentServiceData.equipmentId ? false : true)
    const [inputValue, setInputValue] = useState(equipmentId)
    const { operatorStore } = useRequestManagementContext()
    const handleEdit = (e) => {
        setIsEdit(!isEdit)
        serviceStore?.toggleDisabledBtnSubmitRequest(!isEdit)
        equipmentIdRef.current = inputValue
    }

    const handleOnChange = (e) => {
        setInputValue(e.target.value)
    }

    const handleChangeEquipmentId = (e) => {
        openModalFn['progress-backdrop']()
        const params = {
            maintenanceOrderId: serviceOrderId,
            installedOrderEquipmentId: inputValue,
        }
        changeServiceOrderEquipmentId({ operatorStore, params })
            .catch(() => setInputValue(equipmentIdRef.current))
            .finally(() => {
                setIsEdit(!isEdit)
            })
    }

    const handleCancel = () => {
        setInputValue(equipmentIdRef.current)
        setIsEdit(!isEdit)
    }

    const errorStylesActive = () => {
        if (currentServiceData.status === 5 && !inputValue) {
            return true
        } else {
            return false
        }
    }

    useEffect(() => {
        if (preventEdit) {
            setIsEdit(false)
        }
    }, [preventEdit])

    return (
        <CustomAccordion
            summary={'Данные оборудования'}
            defaultOpenState={true}
            preventHandleOpen={false}
            onEdit={preventEdit ? null : handleEdit}
        >
            {!inputValue ? (
                <WarningPaper
                    title={'ID оборудования не указан'}
                    text1={'Заявка на сервисное обслуживание создана без указания ID оборудования!'}
                    text2={'Необходимо ввести ID оборудования перед закрытием заявки!'}
                    style={{ marginTop: '8px' }}
                />
            ) : null}
            <Box sx={{ marginTop: '14px', paddingLeft: '12px' }}>
                {isEdit ? (
                    <Box sx={{ display: 'flex', gap: '12px', alignItems: 'center' }}>
                        <TextField
                            label={'Id оборудования'}
                            value={inputValue}
                            onChange={handleOnChange}
                            // error={inputValue ? false : true}
                            error={errorStylesActive()}
                        />
                        <Box sx={{ display: 'flex', gap: '12px', alignItems: 'center' }}>
                            <img style={{ cursor: 'pointer' }} onClick={handleChangeEquipmentId} src={checkIcon} />
                            <img style={{ cursor: 'pointer' }} onClick={handleCancel} src={crossIcon} />
                        </Box>
                    </Box>
                ) : (
                    <DataUnit name='Id оборудования' value={inputValue || 'Не заполнен'} />
                )}
            </Box>
        </CustomAccordion>
    )
}

export default inject('serviceStore')(observer(EquipmentDataAccordion))
