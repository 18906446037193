import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import classNames from 'classnames'
import { css } from '@emotion/css'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import InputLabel from '@mui/material/InputLabel'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Typography from '@mui/material/Typography'
import FormControl from '@mui/material/FormControl'
import InputField from '../../../../../components/customElements/InputField/InputField'
import DealerSelect from '../AssignToDealerView/utils/DealerSelect'
import { FormattedMessage, useIntl } from 'react-intl'
import { isAdmin, isAdministration, isOperator } from '../../../../../services/AccountService'
import { closeModalFn } from '../../../../../utils/controllers/ModalController'
import { notifySuccess } from '../../../../../services/ToastifyService/ToastifyService'

function ChangeStatusView({ store, operatorStore }) {
    const intl = useIntl()
    const {
        newStatus,
        data,
        availableStatuses,
        comment,
        setComment,
        newDealer,
        assignDealer,
        markAsImpossible,
        markAsDuplicate,
        resetStatusToCreated
    } = store
    const { status } = data
    const handleClick = () => {
        const operatorCallback = isOperator()
            ? () => {
                  operatorStore.reLoad()
                  closeModalFn['request-details']()
                //   notifySuccess('Заявка обновлена')
              }
            : null

            switch (newStatus) {
                case 7:
                    markAsImpossible(operatorCallback);
                    store.newStatus = null
                    store.comment = ''
                    break;
                case 10:
                    markAsDuplicate(operatorCallback);
                    store.comment = ''
                    break;
                case 1:
                    resetStatusToCreated(operatorCallback);
                    store.comment = ''
                    break;    
                default:
                    assignDealer(operatorCallback);
                    store.comment = ''
                    break;
            }

        // newStatus === 7
        //     ? markAsImpossible(operatorCallback)
        //     : newStatus === 10
        //     ? markAsDuplicate(operatorCallback)
        //     : assignDealer(operatorCallback)
    }
    return (
        <React.Fragment>
            <Box name={'content'} mx={2} className={classNames('fx fx-col', css({ flex: '1 1 auto' }))}>
                <Box mb={3}>
                    <Typography variant={'h6'} sx={{ fontSize: '14px' }}>
                        <FormattedMessage id={'Изменить статус'} />
                    </Typography>
                </Box>
                <FormControl fullWidth>
                    <InputLabel id='demo-simple-select-label'>
                        <FormattedMessage id={'Статус'} />
                    </InputLabel>
                    <Select
                        value={newStatus || status}
                        label={intl.formatMessage({ id: 'Статус' })}
                        onChange={(event) => (store.newStatus = event.target.value)}
                    >
                        {availableStatuses.map((el) => (
                            <MenuItem value={el.id} key={el.id}>
                                <Box className={'aln-center'}>
                                    <img alt={''} src={el.imageSrc} />
                                    <Box ml={1}>
                                        <Typography variant={'subtitle2'}>{el.name}</Typography>
                                    </Box>
                                </Box>
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                {newStatus === 7 || (newStatus === 10 && isAdministration()) ? (
                    <Box mt={2}>
                        <InputField
                            multiline
                            autoFocus
                            value={comment}
                            onChange={setComment}
                            rows={4}
                            fullWidth
                            placeholder={intl.formatMessage({ id: 'Введите комментарий' })}
                        />
                    </Box>
                ) : null}

                {newStatus === 2 && (isAdmin() || isAdministration()) && (
                    <Box mt={1}>
                        <DealerSelect store={store} />
                    </Box>
                )}
            </Box>
            <Box name={'action'} width={1} mt={2} p={2} pb={3}>
                <Button
                    variant={'contained'}
                    color={'primary'}
                    fullWidth
                    onClick={handleClick}
                    disabled={
                        !newStatus ||
                        newStatus === status ||
                        (newStatus === 2 && !newDealer && status !== 7 && status !== 11) ||
                        (newStatus === 7 && !comment) || (newStatus === 10 && isAdministration() && !comment)
                    }
                >
                    <FormattedMessage id={'Подтвердить'} />
                </Button>
            </Box>
        </React.Fragment>
    )
}

export default observer(ChangeStatusView)
