import { makeAutoObservable, makeObservable } from 'mobx'
import FormStore from '../../../utils/stores/FormStore'
import moment from 'moment'
import { isAdmin, isDealer } from '../../../services/AccountService'

const initState = {
    orderId: '',
    receiverSerialNumber: '',
    receiverModelId: '',
    dateOfMaintenance: '',
    files: null,
    diagnosticPassBasisComment: '',
}

const validateDataTemplate = {
    orderId: { required: true },
    
    dateOfMaintenance: {
        required: true,
        validators: [
            (value) => value.length < 8,
            (value) => {
                return moment().utc(true).diff(moment(value, 'DD-MM-YYYY').utc(true)) < 0
            },
        ],
    },
    diagnosticPassBasisComment: { required: true },
}

class ServiceInformationAccordionFormStore extends FormStore {
    constructor() {
        const validateData = { ...validateDataTemplate };

        if (!(isDealer()||isAdmin()) ) {
            validateData.files = { required: true };
            validateData.receiverSerialNumber= { required: true };
            validateData.receiverModelId= { required: true };
        }
        super({ initState, validateData })
        this.showErrors = true
        this.isValid = false
        makeObservable(this, {})
    }

    isFiveStatus = () => true

    clear = () => {
        this.setData(initState)
        this.clearSelectedFilters()
    }
}

export default ServiceInformationAccordionFormStore
