import { makeAutoObservable, makeObservable } from 'mobx'
import FormStore from '../../../utils/stores/FormStore'
import moment from 'moment'
import { formatPhoneNumber } from '../../../helpers/ordersUtiles'

const initState = {
    phoneNumber: '',
    address: '',
    equipmentId: '',
    preferentialCategories: [],
    surname: '',
    name: '',
    patronymic: '',
    territory: '',
    district: '',
    locality: '',
    addressType: '',
    house: '',
    building: '',
    apartment: '',
    preferentialCategory: '',
    created: '',
    additionalInfo: '',
    senderId: '',
    maintenanceReasonId: 1,
    // hasDuplicates: false,
    installedOrderId: '',
    orderId: null,
}

const validateData = {
    surname: { required: true },
    name: { required: true },
    territory: { required: true },
    district: { required: true },
    address: { required: true },
    locality: { required: true },
    phoneNumber: {
        required: true,
        validators: [
            (value) => {
                value = formatPhoneNumber(value)
                return !/^\+7 \(\d{3}\)-\d{3}-\d{2}-\d{2}$/.test(value)
            },
        ],
    },
    created: {
        required: true,
        validators: [
            (value) => value.length < 16,
            (value) => {
                return moment().utc(true).diff(moment(value, 'DD-MM-YYYY,HH:mm').utc(true)) < 0
            },
        ],
    },
    senderId: { required: true },
    maintenanceReasonId: { required: true },
}

class ServiceAddRequestFormStore extends FormStore {
    constructor() {
        super({ initState, validateData })
        this.showErrors = true
        this.isValid = false
        makeObservable(this, {})
    }

    clear = () => {
        this.setData(initState)
        this.clearSelectedFilters()
    }
}

export default ServiceAddRequestFormStore
