import { Button } from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect } from 'react'
import lupeWhiteIcon from '../../../../../assets/images/common/lupeWhite.svg'
import { inject, observer } from 'mobx-react'

const DrawerButtons = ({ serviceStore }) => {
    const {
        showContinueInfo,
        showAddServiceRequestForm,
        haveNotFound,
        searchByPhoneMode,
        searchByIdMode,
        getInstalledOrders,
        toggleToShowContinueInfo,
        toggleOnPhoneSearchMode,
        showAddServiceRequestFormFunc,
        onCloseDrawer,
        createServiceOrder,
        checkedSeviceOrder,
        installedOrders,
        showToggleToPhoneBtnFunc,
        showToggleToPhoneBtn,
        equipmentIdError,
        serviceAddRequestFormStore,
        goOnStartDrawer,
    } = serviceStore

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '8px',
                padding: '0 16px',
                marginTop: '14px',
                marginBottom: '16px',
            }}
        >
            {searchByIdMode && !showContinueInfo && !showAddServiceRequestForm && !checkedSeviceOrder && (
                <Button
                    fullWidth
                    variant={'contained'}
                    onClick={getInstalledOrders}
                    startIcon={<img style={{ marginRight: '8px' }} src={lupeWhiteIcon} alt='search icon' />}
                    disabled={searchByIdMode && equipmentIdError}
                >
                    найти данные по Id оборудования
                </Button>
            )}
            {!haveNotFound &&
                !showAddServiceRequestForm &&
                !showContinueInfo &&
                !installedOrders.length &&
                !showToggleToPhoneBtn &&
                !searchByPhoneMode && (
                    <Button fullWidth variant={'outlined'} onClick={toggleOnPhoneSearchMode}>
                        id не предоставлен
                    </Button>
                )}
            {searchByPhoneMode && !checkedSeviceOrder && (
                <Button fullWidth variant={'contained'} onClick={getInstalledOrders}>
                    найти данные по номеру телефона
                </Button>
            )}
            {searchByIdMode && !installedOrders.length && haveNotFound && (
                <Button fullWidth variant={'outlined'} onClick={toggleOnPhoneSearchMode}>
                    поиск по номеру телефона
                </Button>
            )}
            {searchByPhoneMode && !installedOrders.length && (
                <Button fullWidth variant={'outlined'} onClick={toggleToShowContinueInfo} disabled={!haveNotFound}>
                    поиск не дал результатов
                </Button>
            )}
            {showContinueInfo && (
                <Button fullWidth variant={'contained'} onClick={showAddServiceRequestFormFunc}>
                    Продолжить
                </Button>
            )}
            {checkedSeviceOrder && installedOrders.length && !showAddServiceRequestForm && (
                <Button fullWidth variant={'contained'} onClick={() => showAddServiceRequestFormFunc()}>
                    Создать на основе заявки № {checkedSeviceOrder.orderId}
                </Button>
            )}
            {showAddServiceRequestForm && (
                <Button
                    fullWidth
                    variant={'contained'}
                    onClick={createServiceOrder}
                    disabled={!serviceAddRequestFormStore.isValid}
                >
                    Создать заявку на обслуживание
                </Button>
            )}
            {(showAddServiceRequestForm || showContinueInfo || showAddServiceRequestForm) && (
                <Button fullWidth variant={'outlined'} onClick={onCloseDrawer}>
                    Отмена
                </Button>
            )}
            {installedOrders.length !== 0 && !showAddServiceRequestForm && (
                <Button fullWidth variant={'outlined'} onClick={goOnStartDrawer}>
                    сбросить данные
                </Button>
            )}
        </Box>
    )
}

export default inject('serviceStore')(observer(DrawerButtons))
