import React, { useEffect, useState } from 'react'
import CustomAccordion from '../../../../../components/customElements/RequestDataAccordions/utils/CustomAccordion'
import { Box, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import DataUnit from '../../../../../components/customElements/DataUnit/DataUnit'
import { inject, observer } from 'mobx-react'
import { SortGridMenuItems } from '@mui/x-data-grid'
import FormInput from '../../../../../components/customElements/FormInput'
import { useIntl } from 'react-intl'
import { isAdmin, isDealer } from '../../../../../services/AccountService'

const FullEquipmentDataAccordion = ({ serviceStore, isEditable }) => {
    const intl = useIntl()
    const { currentServiceData, serviceReceivers, serviceInformationAccordionFormStore } = serviceStore

    const [isEdit, setIsEdit] = useState(false)

    useEffect(() => {
        serviceInformationAccordionFormStore.data.orderId = currentServiceData.id
    }, [])

    const handleEdit = () => {
        setIsEdit(!isEdit)
    }

    return (
        <CustomAccordion
            summary={'Данные оборудования'}
            defaultOpenState={true}
            preventHandleOpen={false}
            // onEdit={isEditable ? handleEdit : null}
        >
            <Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '14px',
                        marginTop: '12px',
                        paddingLeft: '10px',
                    }}
                >
                    {isEditable ? (
                        <>
                            <DataUnit
                                sx={{ marginBottom: '2px' }}
                                name='Id оборудования'
                                value={currentServiceData.equipmentId || 'Не заполнен'}
                            />
                            {isDealer() || isAdmin()? null: <Box>

                            <FormInput
                                type='select'
                                api={serviceInformationAccordionFormStore}
                                propName={'receiverModelId'}
                                label={intl.formatMessage({ id: 'Модель оборудования' })}
                                fullWidth
                                placeholder={'Модель оборудования'}
                                items={serviceReceivers.map((el) => ({
                                    key: `${el.name} - ${el.manufacturerName}`,
                                    value: el.id,
                                }))}
                                withoutErrorText={true}
                                />
                            <FormInput
                                type='text'
                                api={serviceInformationAccordionFormStore}
                                propName={'receiverSerialNumber'}
                                label={intl.formatMessage({ id: 'Серийный номер' })}
                                fullWidth
                                placeholder={'Серийный номер'}
                                withoutErrorText={true}
                                />
                                </Box>}
                        </>
                    ) : (
                        <>
                            <DataUnit name='Id оборудования' value={currentServiceData.equipmentId || 'Не заполнен'} />
                            <DataUnit
                                name='Модель оборудования'
                                value={currentServiceData.receiverModel || 'Не заполнен'}
                            />
                            <DataUnit
                                name='Серийный номер'
                                value={currentServiceData.receiverSerialNumber || 'Не заполнен'}
                            />
                        </>
                    )}
                </Box>
            </Box>
        </CustomAccordion>
    )
}

export default inject('serviceStore')(observer(FullEquipmentDataAccordion))
