import React, { useEffect } from 'react'
import CustomAccordion from '../../../../../components/customElements/RequestDataAccordions/utils/CustomAccordion'
import { useIntl } from 'react-intl'
import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import moment from 'moment'
import { observer } from 'mobx-react'
import FormInput from '../../../../../components/customElements/FormInput'
import DataUnit from '../../../../../components/customElements/DataUnit/DataUnit'
import { openModalFn } from '../../../../../utils/controllers/ModalController'
import AddPhotosBlock from './AddPhotosBlock'
import { isOperator, isAdministration } from '../../../../../services/AccountService'
const ServiceInformationAccordion = ({ serviceStore, type }) => {
    const intl = useIntl()
    const {
        serviceInformationAccordionFormStore,
        getServicePhotosFunc,
        currentServiceData,
        serviceOrderPhotosUrls,
        photosInfoList,
    } = serviceStore
    

    useEffect(() => {
        if (isOperator() || isAdministration()) {
            return
        } else {
            getServicePhotosFunc(currentServiceData.id, 'MaintenanceCompleted')
        }
        

        if (type === 1) {
            serviceInformationAccordionFormStore.data.diagnosticPassBasisComment = ' '
        }
    }, [])

    const collection = photosInfoList
    const name = 'Фото'
    
    return (
        <CustomAccordion
            summary={type === 2 ? 'Передача в сервисный центр' : 'Информация об обслуживании'}
            defaultOpenState={true}
            preventHandleOpen={false}
        >
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '14px', marginTop: '12px', paddingLeft: '15px' }}>
                {currentServiceData.dateOfMaintenance || currentServiceData.dateOfSeizure ? (
                    <DataUnit
                        name={
                            type === 2
                                ? intl.formatMessage({ id: 'Дата изъятия оборудования' })
                                : intl.formatMessage({ id: 'Дата выполнения работ' })
                        }
                        value={
                            type === 2
                                ? moment(currentServiceData.dateOfSeizure).format('DD.MM.YYYY')
                                : moment(currentServiceData.dateOfMaintenance).format('DD.MM.YYYY')
                        }
                    />
                ) : (
                    <FormInput
                        api={serviceInformationAccordionFormStore}
                        type={'text'}
                        propName={'dateOfMaintenance'}
                        fullWidth
                        mask={'99-99-9999'}
                        label={
                            type === 2
                                ? intl.formatMessage({ id: 'Дата изъятия оборудования' })
                                : intl.formatMessage({ id: 'Дата выполнения работ' })
                        }
                        withoutErrorText={true}
                        defaultValue={moment().format('DD.MM.YYYY')}
                    />
                )}
                {!isOperator() ? (
                    !isAdministration() ? (
                        <>
                            {serviceOrderPhotosUrls.length ? (
                                <Box>
                                    <Typography
                                        variant={'body2'}
                                        color={'grey.500'}
                                        sx={{ fontSize: '15px', marginY: '4px', lineHeight: 1 }}
                                    >
                                        Фото
                                    </Typography>
                                    <Box sx={{ display: 'flex', gap: '12px', flexWrap: 'wrap' }}>
                                        {serviceOrderPhotosUrls.map((url, i) => {
                                            return (
                                                <img
                                                    style={{
                                                        width: '64px',
                                                        height: '64px',
                                                        borderRadius: '8px',
                                                        border: '1px solid gray',
                                                        cursor: 'pointer',
                                                    }}
                                                    src={url}
                                                    onClick={() => {
                                                        openModalFn['photo-gallery']({
                                                            collection,
                                                            initialIndex: i,
                                                            name,
                                                        })
                                                    }}
                                                />
                                            )
                                        })}
                                    </Box>
                                </Box>
                            ) : (
                                ![6.3, 6.4, 6.5, 6.2].includes(currentServiceData.status) && (
                                    <AddPhotosBlock
                                        settingsField={'MaintenanceCompleted'}
                                        formStore={serviceInformationAccordionFormStore}
                                        serviceStore={serviceStore}
                                    />
                                )
                            )}
                        </>
                    ) : null
                ) : null}

                {type === 2 &&
                    (currentServiceData.diagnosticPassBasisComment ? (
                        <DataUnit
                            name={intl.formatMessage({ id: 'Основание для передачи в сервисный центр' })}
                            value={currentServiceData.diagnosticPassBasisComment}
                        />
                    ) : (
                        <FormInput
                            api={serviceInformationAccordionFormStore}
                            type={'text'}
                            propName={'diagnosticPassBasisComment'}
                            fullWidth
                            label={intl.formatMessage({ id: 'Основание для передачи в сервисный центр' })}
                            withoutErrorText={true}
                            minRows={3}
                            multiline
                        />
                    ))}
            </Box>
        </CustomAccordion>
    )
}

export default observer(ServiceInformationAccordion)
