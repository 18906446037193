import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import {
    isAdmin,
    isAdministration,
    isAdminLabelAA,
    isDealer,
    isLooker,
    isOperator,
} from '../../../../../services/AccountService'
import classNames from 'classnames'
import { css } from '@emotion/css'
import Box from '@mui/material/Box'
import AssignedDealer from '../../../../../components/customElements/RequestDataAccordions/AssignedDealer'
import SetEquipmentId from '../../../../../components/customElements/RequestDataAccordions/SetEquipmentId'
import RequestInfo from '../../../../../components/customElements/RequestDataAccordions/RequestInfo'
import InstallationData from '../../../../../components/customElements/RequestDataAccordions/InstallationData'
import { isModalOpen } from '../../../../../utils/controllers/ModalController'
import UploadedPhotos from '../../../../../components/customElements/RequestDataAccordions/UploadedPhotos'
import ChangePriority from '../../../../../components/customElements/RequestDataAccordions/ChangePriority'
import commonStyles from '../../../../../styles/commonStyles'
import ChangeInstallationDate from '../../../../../components/customElements/RequestDataAccordions/ChangeInstallationDate'
import HasDuplicates from '../../../../../components/customElements/RequestDataAccordions/HasDuplicates'
import CommentsBlock from '../../../../../components/RequestCommentsBlock/CommentsBlock'
import StorageAccordion from 'src/components/customElements/RequestDataAccordions/StorageAccordion/StorageAccordion'
import FinancialSourceAccordion from '../../../../../components/customElements/RequestDataAccordions/FinancialSourceAccordio'
import { getComp } from '../../../../../utils/utils/DI'
import CustomAccordion from '../../../../../components/customElements/RequestDataAccordions/utils/CustomAccordion'
import { gState } from '../../../../../utils/controllers/GlobalStateController'
import TakeReleaseBtn from '../../../../Service/components/Drawers/DrawersCommonComponents/Buttons/TakeReleaseBtn'
import { getStyles } from '../../../../../utils/utils/commonUtils'
import IsSuspicious from '../../../../../components/customElements/RequestDataAccordions/IsSuspicious'

function DefaultContent({
    store,
    ordersStore,
    operatorStore,
    filterStore,
    setUpdateHistory,
    updateHistory,
    openReportDetails,
}) {
    const classes = getStyles(commonStyles)
    const intl = gState['intl']
    const isOperatorTable = isOperator() && [0].includes(operatorStore.selectedTab)
    const {
        data,
        toAssignDealerView,
        toEditDataView,
        changePriority,
        changeFinancialSource,
        changeInstallationDate,
        setOrderDataRequest,
    } = store

    const { status, id, isTakenByMe, priority, installationDate, dataCheckingStatus, reportStatus } = data

    const changeCreationDateCallback = () => {
        store.ordersStore.updateOrder(id).then((res) => store.setData(res))
    }

    const installationDateInclude = () => {
        return [6, 9, 12, 13, 14, 15, 16].includes(status)
    }

    const notRenderDataInstall = () => {
        switch (true) {
            case isAdmin():
                return [1, 2, 4, 5].includes(status)
            case isOperator():
                return false
            default:
                return true
        }
    }

    const AdministrationView = isAdministration() && [7, 11].includes(status)

    return (
        <Box name={'content'} className={classNames('fx-col jst-sb', css(classes.drawerContent))}>
            {isAdmin() && status !== 10 ? (
                <AssignedDealer
                    request={data}
                    defaultOpenState={true}
                    onEdit={
                        [1, 2, 4, 7, 11].includes(status)
                            ? isLooker() && [1, 2, 4, 11].includes[status]
                                ? null
                                : toAssignDealerView
                            : null
                    }
                />
            ) : null}
            {isAdmin() && status !== 1 && status !== 2 && status !== 4 && status !== 7 && status !== 10 ? (
                <SetEquipmentId
                    store={store}
                    inputsValues={filterStore.data}
                    updateHistory={updateHistory}
                    setUpdateHistory={setUpdateHistory}
                />
            ) : null}
            {!AdministrationView && <RequestInfo request={store.data} openReportDetails={openReportDetails} />}
            <InstallationData
                onEdit={
                    (isAdmin() && ![12, 15, 16].includes(status) && [4, 102, 200].includes(reportStatus)) ||
                    (isAdmin() && [1, 2, 4, 5, 6, 7, 9, 10, 11, 13].includes(status)) ||
                    (isOperator() && ((!isOperatorTable && [1, 2].includes(status)) || isTakenByMe)) ||
                    (isAdministration() && status === 1) ||
                    (isAdminLabelAA() &&
                        [1, 2, 4, 5, 6, 7, 9, 10, 11, 12, 13, 15, 16].includes(status) &&
                        ![5, 100, 101].includes(reportStatus))
                        ? isLooker()
                            ? null
                            : toEditDataView
                        : null
                }
                order={store.data}
                defaultOpenState
                drawerOpen={isModalOpen['request-details']}
                filterStore={filterStore}
                operatorStore={operatorStore}
                ordersStore={ordersStore}
            />
            {isOperator() || notRenderDataInstall() || isOperatorTable ? null : (
                <CustomAccordion
                    defaultOpenState={isDealer() && status === 5}
                    summary={intl.formatMessage({ id: 'Данные установки' })}
                >
                    {(isDealer() || isAdmin()) && installationDateInclude() && (
                        <ChangeInstallationDate
                            requestId={id}
                            requestStatus={status}
                            reportStatus={reportStatus}
                            inputData={installationDate}
                            changeInstallationDate={changeInstallationDate}
                            reloadAction={changeCreationDateCallback}
                            reportOrder={!!dataCheckingStatus && status !== 6}
                            setUpdateHistory={setUpdateHistory}
                            updateHistory={updateHistory}
                            store={store}
                        />
                    )}

                    <UploadedPhotos store={store} defaultOpenState={true} order={data} />
                </CustomAccordion>
            )}
            <CommentsBlock store={store} />
            <StorageAccordion order={data} />
            <FinancialSourceAccordion
                changeFinancialSource={changeFinancialSource}
                onEdit={
                    isLooker()
                        ? false
                        : (isAdmin() && [1, 2].includes(status)) ||
                          (isAdministration() && [1, 2].includes(status)) ||
                          (isOperator() && status === 1)
                }
                data={data}
            />
            {status === 9 ||
            (isOperator() && !isTakenByMe) ||
            isAdministration() ||
            (isDealer() && [7].includes(status)) ||
            isLooker() ? null : (
                <ChangePriority id={id} priority={priority || null} changePriority={changePriority} status={status} />
            )}

            {((isAdmin() && status !== 9) ||
                !AdministrationView ||
                (isOperator() && ((!isOperatorTable && [1, 2].includes(status)) || isTakenByMe))) && (
                <HasDuplicates data={data} onChange={setOrderDataRequest} />
            )}

            {isAdmin() && (
                <IsSuspicious data={data} onChange={setOrderDataRequest} />
            )}

            {isOperatorTable ? <TakeReleaseBtn data={data} store={operatorStore} /> : null}
        </Box>
    )
}

export default observer(DefaultContent)
