import React, {useContext, useState} from "react"
import {observer} from "mobx-react"
import RequestsManagementStore from "src/stores/RequestsManagementStore"

const RequestsManagementContext = React.createContext({})

export const useRequestManagementContext = () => useContext(RequestsManagementContext)

const WithRequestsManagementStore = observer(({children}) => {
    const [requestsManagement] = useState(new RequestsManagementStore())

    return (
        <RequestsManagementContext.Provider value={requestsManagement}>
            {children}
        </RequestsManagementContext.Provider>
    )
})

export function withRequestsManagementStore(Component) {
    return function withRequestsManagementComponent(props) {
        return (
            <WithRequestsManagementStore>
                <Component {...props}/>
            </WithRequestsManagementStore>
        )
    }
}
