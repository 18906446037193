import { Box, Tooltip, Typography } from '@mui/material'
import { observer } from 'mobx-react'
import React, { useEffect } from 'react'
import { getStyles } from '../../../../../utils/utils/commonUtils'
import { orderInfoChipsStyles } from '../../../../../components/OrderInfoChips/orderInfoChipsStyles'
import { getOrders } from '../../../../../services/ApiService'
import { useNavigate } from 'react-router'
import { openModalFn } from '../../../../../utils/controllers/ModalController'
import { getComp } from '../../../../../utils/utils/DI'

function LinkToInstallationRequest({ currentServiceData, openRequestDetails, ordersStore }) {
    const classes = getStyles(orderInfoChipsStyles) // стили взаимствованы из компонента OrderInfoChips
    const statusesStore = getComp('StatusesStore')

    const [orderStatus, setOrderStatus] = React.useState(null)
    useEffect(() => {
        getOrders({ orderId: currentServiceData?.installedOrderId }).then((res) => {
            ordersStore.setData(res.content)
            setOrderStatus(res.content[0].status)
           
        })
    }, [currentServiceData?.installedOrderId])
    const icon = statusesStore.getStatusImage(orderStatus)
    const name = statusesStore.getStatusName(orderStatus)
    const openOrderInstallation = () => {
        getOrders({ orderId: currentServiceData?.installedOrderId }).then((res) => {
            openModalFn['request-details']({ id: currentServiceData?.installedOrderId })
            
        }) 
    }
    return (
        currentServiceData?.installedOrderId && (
            <Box mr={2} sx={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}>
                <Tooltip title={name} placement={'top'}>
                    <Box
                        mx={2}
                        sx={{ ...classes.container, marginRight: '0px', background: true ? 'rgb(237 241 245)' : null }}
                    >
                        <img
                            style={{ width: '20px', height: '20px', marginBottom: '1px' }}
                            alt={`report status №${orderStatus}`}
                            src={icon}
                        />
                        <Typography
                            sx={{
                                ...classes.text,
                                color: '#0871C3',
                                cursor: 'pointer',
                                textDecoration: 'underline',
                                '&:hover': {
                                    color: '#05509C',
                                },
                            }}
                            onClick={openOrderInstallation}
                        >
                            {currentServiceData?.installedOrderId}
                        </Typography>
                    </Box>
                </Tooltip>
            </Box>
        )
    )
}

export default observer(LinkToInstallationRequest)


