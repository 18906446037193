import React from "react"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import {FormattedMessage} from "react-intl/lib"
import DatesStore from "../../../stores/DatesStore"
import {observer} from "mobx-react"
import {getComp} from "../../../utils/utils/DI"

function TotalOrders({order}) {
    const {ordersCount, orderStatus} = order
    const {getStatusImage} = getComp("StatusesStore")
    return (
        <Box className={"fx-nowrap"}>
            <img alt={""} style={{width: "16px", height: "16px"}} src={getStatusImage(orderStatus)}/>
            <Typography variant={"body1"}>{ordersCount}</Typography>
        </Box>
    )
}

function ReportDetailsTooltip({report}) {
    const {id, month, year, orders, ordersCount} = report
    const {getMonthName} = new DatesStore()
    return (
        <Box>
            <Typography variant={"body1"}>
                №{id} <FormattedMessage id={"за"}/> {getMonthName(month)} {year}
            </Typography>
            <Box sx={{display: "flex", gap: "8px", marginTop: "4px"}}>
                <Typography variant={"h5"}>
                    {ordersCount}
                </Typography>
                {orders.map(order => <TotalOrders order={order}/>)}
            </Box>
        </Box>
    )
}

export default observer(ReportDetailsTooltip)