import React, { useEffect, useState } from 'react'
import { Box, Button } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { openModalFn } from '../../../../../../utils/controllers/ModalController'
import { observer } from 'mobx-react'

const TakeReleaseBtn = ({ data, store }) => {
    const { setIsTaken, disabledBtnSubmitRequest } = store
    const { isTakenByMe, isTaken, id } = data

    const handleAction = () => {
        setIsTaken({ isTakenByMe, id })
    }

    return isTakenByMe || !isTaken ? (
        <>
            <Box width={1} px={2} py={1}>
                <Button
                    variant={'contained'}
                    color={'primary'}
                    fullWidth
                    onClick={handleAction}
                    disabled={disabledBtnSubmitRequest}
                >
                    {isTakenByMe ? <FormattedMessage id={'Вернуть заявку'} /> : <FormattedMessage id={'Взять заявку в обработку'} />}
                </Button>
            </Box>
        </>
    ) : (
        <></>
    )
}
export default observer(TakeReleaseBtn)
