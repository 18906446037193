import React from 'react'
import { observer } from 'mobx-react'
import CustomAccordion from './utils/CustomAccordion'
import DealerCard from '../../../views/RequestsManagement/components/RequestDetails/AssignToDealerView/utils/DealerCard'
import { getComp } from '../../../utils/utils/DI'
import Box from '@mui/material/Box'
import { gState } from '../../../utils/controllers/GlobalStateController'

function AssignedDealer({ request, defaultOpenState, onEdit }) {
    const intl = gState['intl']
    
    return (
        <CustomAccordion
            defaultOpenState={defaultOpenState}
            summary={intl.formatMessage({ id: 'Назначенный дилер' })}
            onEdit={onEdit}
        >
            <DealerCard
                dealer={
                    getComp('DealersStore').getDealer(request.dealer) ||
                    getComp('DealersStore').getDealer(request.assignedDealerId)
                }
                selected
                onlyShow={true}
            />
        </CustomAccordion>
    )
}

export default observer(AssignedDealer)
