import { observer } from 'mobx-react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import React from 'react'

function TableControls({ controls, selectedTab, setSelectedTab, clearFilters }) {
    const setControls = (el, i) => {
        const { text, color, total } = el
        return (
            <Box
                pb={1}
                ml={i ? 3.5 : 0}
                sx={{
                    borderBottom: i === selectedTab ? `2px solid ${color}` : null,
                    textShadow: i === selectedTab ? '0px 0px 1px' : null,
                    cursor: 'pointer',
                }}
                onClick={() => {
                    setSelectedTab(i)
                    clearFilters()
                }}
            >
                <Typography
                    variant={'body1'}
                    sx={{
                        color: color,
                        textTransform: 'uppercase',
                        fontWeight: 500,
                        '&:hover': {
                            textShadow: '0px 0px 1px',
                        },
                    }}
                >
                    {text}
                    {total ? `(${total})` : null}
                </Typography>
            </Box>
        )
    }
    return (
        <Box className={'fx-nowrap'} sx={{ marginBottom: '16px' }}>
            {controls.map((el, i) => setControls(el, i))}
        </Box>
    )
}

export default observer(TableControls)
