import React, { useState } from 'react'
import CustomAccordion from '../../../../../components/customElements/RequestDataAccordions/utils/CustomAccordion'
import { Box } from '@mui/system'
import { Typography } from '@mui/material'
import DataUnit from '../../../../../components/customElements/DataUnit/DataUnit'
import dealerAvatarSmall from '../../../../../assets/images/common/dealerAvatarSmall.svg'
import moment from 'moment'
import { observer } from 'mobx-react-lite'
import shevronRight from '../../../../../assets/images/common/shevronRight.svg'
import { formatAddress } from '../../../../../helpers/ordersUtiles'
const ServiceDrawerElement = ({
    el,
    checkboxAction,
    checkedServiceOrderId,
    openedAccordionIndex,
    setOpenedAccordionIndex,
    index,
    serviceStore,
}) => {
    const { serviceAddRequestFormStore } = serviceStore
    const [checked, setChecked] = useState(false)
    const { territory, district, locality, address, addressType, house, building, apartment } = el
    const getNormalizedString = (obj) => {
        return formatAddress(obj)
    }

    const markSearchText = (str, field) => {
        if (str === serviceAddRequestFormStore.data[field]) {
            return <span style={{ background: 'yellow' }}>{str}</span>
        }

        return str
    }
    function formatPhoneNumber(phoneNumber) {
        const cleaned = ('' + phoneNumber).replace(/\D/g, '');
        const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})$/);
    
        if (match) {
            return `+${match[1]} (${match[2]})-${match[3]}-${match[4]}-${match[5]}`;
        }
    
        return null; // Вернуть null, если входные данные некорректны
    }
   
    return (
        <CustomAccordion
            defaultOpenState={index === 0 ? true : false}
            summary={`Заявка на установку № ${el.orderId}`}
            key={el.orderId}
            headColor={checked ? 'rgba(203, 227, 255, 1)' : 'rgba(246, 246, 246, 1)'}
            contentPadding={'9px 0'}
            withCheckbox={true}
            checkboxAction={checkboxAction}
            orderId={el.orderId}
            checkedServiceOrderId={checkedServiceOrderId}
            checked={checked}
            setChecked={setChecked}
            arrowIcon={<img src={shevronRight} />}
            isOpened
            openedAccordionIndex={openedAccordionIndex}
            setOpenedAccordionIndex={setOpenedAccordionIndex}
            index={index}
        >
            <Box
                sx={{
                    height: '45px',
                    background: 'rgba(246, 246, 246, 1)',
                    paddingLeft: '16px',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '8px',
                }}
            >
                <img src={dealerAvatarSmall} />
                <Typography>{el.dealerName}</Typography>
            </Box>
            <Box sx={{ padding: '10px 0 0 40px', display: 'flex', flexDirection: 'column', gap: '8px' }}>
                <DataUnit name='Id оборудования' value={markSearchText(el.equipmentId, 'equipmentId')} />
                <DataUnit name='Дата установки' value={el.installed ? moment(el.installed).format('DD.MM.YYYY') : '-'} />
                <DataUnit
                    name='Адрес'
                    value={getNormalizedString({
                        territory,
                        district,
                        locality,
                        address,
                        addressType,
                        house,
                        building,
                        apartment,
                    })}
                />
                <DataUnit name='Заказчик' value={`${el.surname || ''} ${el.name || ''} ${el.patronymic || ''}`} />
                <DataUnit name='Причина' value={el.installationReasonId} />
                <DataUnit name='Телефон' value={formatPhoneNumber(markSearchText(el.phone, 'phoneNumber'))} />
                <DataUnit name='Льготная категория' value={el.preferentialCategory} />
                <DataUnit name='Льготные категории' value={el.preferentialCategories} />
                <DataUnit name='Дополнительная информация' value={el.additionalInfo} />
            </Box>
        </CustomAccordion>
    )
}

export default observer(ServiceDrawerElement)
