const MassOperationsStyles = (theme) => {
    return {
        customSelect: {
            position: "relative",
            marginRight: "8px",
            backgroundColor: "rgba(217, 217, 217, 0.50)",
            color: "black",
            fontSize: "15px",
            fontWeight: "500",
            textTransform: "uppercase",
            display: "flex",
            alignItems: "center",
            width: "340px",
            "& .MuiTypography-root": {
                paddingTop: "2px"
            }
        },

        selectedItem: {
            color: "black",
            fontSize: "15px",
            fontWeight: "500",
            padding: "0 4px"
        },

        menuProps: {
            "& .MuiPaper-root": {
                border: "1px solid #A5A5A5",
                marginTop: "4px",
                boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.25)"
            },
            "& .MuiList-root": {
                padding: "0"
            }
        },

        menuItem: {
            textTransform: "uppercase",
            margin: "0",
            color: "black",
            fontSize: "15px",
            fontWeight: "500",
            "&.Mui-selected": {
                background: "rgba(93, 175, 239, 0.20)",
                borderRadius: "0",

                "&:hover": {
                    background: "rgba(93, 175, 239, 0.20)",
                    borderRadius: "0"
                }
            },
            "&:hover": {
                background: "rgba(93, 175, 239, 0.20)",
                borderRadius: "0"
            }
        },
        actionBtn: {
            fontWeight: 700,
            boxShadow: "none",
            paddingLeft: "50px",
            paddingRight: "50px",
            borderRadius: "0 4px 4px 0"
        },
        hideOnMobile: {
            display: "flex",
            [theme.breakpoints.down("sm")]: {
                display: "none"
            }
        },
        showOnMobile: {
            display: "none",
            [theme.breakpoints.down("sm")]: {
                display: "flex"
            }
        },
        tableHeadCheckboxWrapper: {
            alignItems: "center",
            gap: "8px"
        }
    }
}

export default MassOperationsStyles